import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';

function DetailsTopTabs(props) {
    const { item } = props;
    return (
        <section className="tab-product m-0">
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <Tabs className="tab-content nav-material">
                        <TabList className="nav nav-tabs nav-material">
                            <Tab className="nav-item">
                                <span className="nav-link active">
                                    <i className="icofont icofont-ui-home"></i>Details
                                </span>
                                <div className="material-border"></div>
                            </Tab>
                        </TabList>
                        <TabPanel className="tab-pane fade mt-4 show active">
                            <table className="table table-striped mb-0">
                                <tbody>
                                    <tr>
                                        <th>Category</th>
                                        <td>{item.category.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Spaces</th>
                                        <td>
                                            {item.spaces.map(
                                                (space, index) =>
                                                    `${space.name} ${
                                                        item.spaces.length === index + 1 ? '' : ', '
                                                    }`,
                                            )}
                                        </td>
                                    </tr>
                                    {item.collections.length > 0 ? (
                                        <tr>
                                            <th>Collections</th>
                                            <td>
                                                {item.collections.map(
                                                    (collection, index) =>
                                                        `${collection.name} ${
                                                            item.collections.length === index + 1
                                                                ? ''
                                                                : ', '
                                                        }`,
                                                )}
                                            </td>
                                        </tr>
                                    ) : null}
                                    <tr>
                                        <th>Vendor</th>
                                        <td>{item.vendor}</td>
                                    </tr>
                                    <tr>
                                        <th>Width</th>
                                        <td>{item.width} inches</td>
                                    </tr>
                                    <tr>
                                        <th>Height</th>
                                        <td>{item.height} inches</td>
                                    </tr>
                                    <tr>
                                        <th>Length</th>
                                        <td>{item.length} inches</td>
                                    </tr>
                                    <tr>
                                        <th>Weight</th>
                                        <td>{item.weight} pounds</td>
                                    </tr>
                                    {item.box ? (
                                        <tr>
                                            <th>Packing box</th>
                                            <td>
                                                {item.box.width} (width) x {item.box.height}{' '}
                                                (height) x {item.box.length} (length) inches
                                            </td>
                                        </tr>
                                    ) : null}
                                </tbody>
                            </table>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </section>
    );
}

export default DetailsTopTabs;
