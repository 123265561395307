import axios from 'axios';
import store from '../store';
import { deleteUser } from '../actions/auth';

const instance = axios.create({
    baseURL: process.env.REACT_APP_LAR_API_URL || 'http://api-test.louisandrocco.com/api/v1/',
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor
instance.interceptors.request.use(
    function(config) {
        const { auth } = store.getState();
        if (auth.user) {
            const token = auth.user.token;
            config.headers.Authorization = `jwt ${token}`;
        }
        // Do something before request is sent
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(
    function(response) {
        // Do something before request is sent
        return response;
    },
    function(error) {
        if (
            error &&
            error.response &&
            error.response.status === 403 &&
            error.response.data.errors[0].msg.name === 'TokenExpiredError'
        ) {
            store.dispatch(deleteUser());
            window.location.href = '/';
        }
        if (error && !error.response) {
            window.location.href = '/500';
        }
        // Do something with request error
        return Promise.reject(error);
    },
);

export default instance;
