import React, { useEffect } from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import { IntlProvider } from 'react-redux-multilingual';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

// Import custom components
import store from './store';
import translations from './constants/translations';
import { getAllProducts } from './actions';

// Layouts
import Furniture from './components/layouts/furniture/main';

//Collection Pages
import SearchResults from './components/search';
import CollectionNoSidebar from './components/collection/collection-no-sidebar';

// Product Pages
import LeftImage from './components/products/left-image';

// Features
import Layout from './components/app';
import Cart from './components/cart';
import Compare from './components/compare/index';
import wishList from './components/wishlist';
import ProjectDetail from './components/wishlist/projectDetail';
import checkOut from './components/checkout';
import orderSuccess from './components/checkout/success-page';
import orderError from './components/checkout/error-page';

// Extra Pages
import aboutUs from './components/pages/about-us';
import PageNotFound from './components/pages/404';
import PageError from './components/pages/500';
import lookbook from './components/pages/lookbook';
import Login from './components/pages/login';
import Register from './components/pages/register';
import Search from './components/pages/search';
import Collection from './components/pages/collection';
import ForgetPassword from './components/pages/forget-password';
import ChangePassword from './components/pages/change-password';
import Contact from './components/pages/contact';
import Faq from './components/pages/faq';
import SignUpSuccessful from './components/pages/signup-successful';
import VerifiedEmail from './components/pages/verified-email';
import Returns from './components/pages/returns';
import Privacy from './components/pages/privacypolicy';
import HowItWorks from './components/pages/howitworks';
import ProductList from './components/pages/product-list';
import Profile from './components/profile';

// Rents
import DetailRent from './components/rents/detail';

// Google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);

function usePageViews() {
    const location = useLocation();
    useEffect(
        () => {
            ReactGA.pageview(location.pathname + location.search);
        },
        [location],
    );
}

const MainLayout = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
};

function Routes() {
    usePageViews();

    return (
        <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Furniture} />
            <Route exact path={`${process.env.PUBLIC_URL}/500`} component={PageError} />
            {/*Routes For Features (Product Collection) */}
            <MainLayout
                path={`${process.env.PUBLIC_URL}/no-sidebar/collection`}
                component={CollectionNoSidebar}
            />

            <MainLayout path={`${process.env.PUBLIC_URL}/search`} component={SearchResults} />

            {/*Routes For Single Product*/}
            <MainLayout path={`${process.env.PUBLIC_URL}/product/:id`} component={LeftImage} />

            {/*Routes For custom Features*/}
            <MainLayout path={`${process.env.PUBLIC_URL}/cart`} component={Cart} />
            <MainLayout exact path={`${process.env.PUBLIC_URL}/projects`} component={wishList} />
            <MainLayout
                path={`${process.env.PUBLIC_URL}/projects/:projectId`}
                component={ProjectDetail}
            />
            <MainLayout path={`${process.env.PUBLIC_URL}/compare`} component={Compare} />
            <MainLayout path={`${process.env.PUBLIC_URL}/checkout`} component={checkOut} />
            <MainLayout path={`${process.env.PUBLIC_URL}/order-success`} component={orderSuccess} />
            <MainLayout path={`${process.env.PUBLIC_URL}/order-error`} component={orderError} />

            <MainLayout path={`${process.env.PUBLIC_URL}/sales/orders`} component={aboutUs} />

            {/*Routes For Extra Pages*/}
            <MainLayout path={`${process.env.PUBLIC_URL}/pages/about-us`} component={aboutUs} />
            <MainLayout path={`${process.env.PUBLIC_URL}/pages/404`} component={PageNotFound} />
            <MainLayout path={`${process.env.PUBLIC_URL}/pages/lookbook`} component={lookbook} />
            <MainLayout path={`${process.env.PUBLIC_URL}/pages/login`} component={Login} />
            <MainLayout path={`${process.env.PUBLIC_URL}/pages/register`} component={Register} />
            <MainLayout path={`${process.env.PUBLIC_URL}/pages/search`} component={Search} />
            <MainLayout
                path={`${process.env.PUBLIC_URL}/pages/collection`}
                component={Collection}
            />
            <MainLayout
                path={`${process.env.PUBLIC_URL}/pages/forgot-password`}
                component={ForgetPassword}
            />
            <MainLayout
                path={`${process.env.PUBLIC_URL}/pages/change-password`}
                component={ChangePassword}
            />
            <MainLayout path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
            <MainLayout path={`${process.env.PUBLIC_URL}/user/profile`} component={Profile} />
            <MainLayout path={`${process.env.PUBLIC_URL}/pages/faq`} component={Faq} />
            <MainLayout
                path={`${process.env.PUBLIC_URL}/signup-successful`}
                component={SignUpSuccessful}
            />
            <MainLayout path={`${process.env.PUBLIC_URL}/verify`} component={VerifiedEmail} />
            <MainLayout path={`${process.env.PUBLIC_URL}/returns`} component={Returns} />
            <MainLayout path={`${process.env.PUBLIC_URL}/privacy`} component={Privacy} />
            <MainLayout path={`${process.env.PUBLIC_URL}/howitworks`} component={HowItWorks} />
            <MainLayout path={`${process.env.PUBLIC_URL}/main`} component={ProductList} />

            {/* Rent pages */}
            <MainLayout path={`${process.env.PUBLIC_URL}/rents/:id`} component={DetailRent} />

            <MainLayout path="*" component={PageNotFound} />
        </Switch>
    );
}

function Root() {
    // Get user session
    useEffect(() => {
        store.dispatch(getAllProducts());
    }, []);

    return (
        <Provider store={store}>
            <IntlProvider translations={translations} locale="en">
                <ToastContainer />
                <BrowserRouter basename={'/'}>
                    <ScrollContext>
                        <Routes />
                    </ScrollContext>
                </BrowserRouter>
            </IntlProvider>
        </Provider>
    );
}

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    hydrate(<Root />, rootElement);
} else {
    render(<Root />, rootElement);
}
