import React from 'react';
import { Helmet } from 'react-helmet';

function HowItWorks() {
    return (
        <div>
            <Helmet>
                <title>How it works - Louis & Rocco</title>
                <meta property="og:title" content="How it works - Louis & Rocco" />
                <meta
                    property="og:image"
                    content={'https://louisandrocco.com/assets/images/icon/logo/logo_short.png'}
                />
                <meta property="og:url" content={`${process.env.PUBLIC_URL}/howitworks`} />
            </Helmet>
            <section className="p-0">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-12 product-related">
                            <h2>how it works</h2>
                        </div>
                    </div>
                    <div className="row row-steps-how-works">
                        <div className="col-sm-12 col-lg-6 text-inverse">
                            <img
                                src={`${
                                    process.env.PUBLIC_URL
                                }/assets/images/howitworks/how_to_step_1.png`}
                                alt="Step 1"
                            />
                        </div>
                        <div className="col-sm-12 col-lg-6 col-text-how-works">
                            <h2>1</h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h3>Create a new project</h3>
                        </div>
                    </div>
                    <div className="divider-how-works">
                        <hr />
                    </div>
                    <div className="row row-steps-how-works">
                        <div className="col-sm-12 col-lg-6 col-text-how-works">
                            <h2>2</h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <h3>Select the dates you need your décor accessories on</h3>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <img
                                src={`${
                                    process.env.PUBLIC_URL
                                }/assets/images/howitworks/how_to_step_2.png`}
                                alt="Step 2"
                            />
                        </div>
                    </div>
                    <div className="divider-how-works">
                        <hr />
                    </div>
                    <div className="row row-steps-how-works">
                        <div className="col-sm-12 col-lg-6 text-inverse">
                            <img
                                src={`${
                                    process.env.PUBLIC_URL
                                }/assets/images/howitworks/how_to_step_3.png`}
                                alt="Step 3"
                            />
                        </div>
                        <div className="col-sm-12 col-lg-6 col-text-how-works">
                            <h2>3</h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <h3>
                                Select the available décor accessories for the dates you selected.
                            </h3>
                        </div>
                    </div>
                    <div className="divider-how-works">
                        <hr />
                    </div>
                    <div className="row row-steps-how-works">
                        <div className="col-sm-12 col-lg-6 col-text-how-works">
                            <h2>4</h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <h3>Receive them and style your space away</h3>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <img
                                src={`${
                                    process.env.PUBLIC_URL
                                }/assets/images/howitworks/how_to_step_4.png`}
                                alt="Step 4"
                            />
                        </div>
                    </div>
                    <div className="divider-how-works">
                        <hr />
                    </div>
                    <div className="row row-steps-how-works">
                        <div className="col-sm-12 col-lg-6 text-inverse">
                            <img
                                src={`${
                                    process.env.PUBLIC_URL
                                }/assets/images/howitworks/how_to_step_5.png`}
                                alt="Step 5"
                            />
                        </div>
                        <div className="col-sm-12 col-lg-6 col-text-how-works">
                            <h2>5</h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <h3>
                                When done with your photoshoot or particular event, place the
                                accessories back in the box and we will take it from there!
                            </h3>
                        </div>
                    </div>
                    <div className="divider-how-works">
                        <hr />
                    </div>
                    <div className="row d-flex justify-content-center align-items-center mt-5 mb-5">
                        <h2>...It is that simple!</h2>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default HowItWorks;
