import React, { Component } from 'react';
import 'react-input-range/lib/css/index.css';
import { SlideToggle } from 'react-slide-toggle';
import { DateRangePicker } from 'react-dates';

import api from '../../../config/axios';

class Filter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            spaces: [],
            collections: [],
            filteredCategories: [],
            filteredCollections: [],
            filteredSpaces: [],
            openFilter: false,
            loaded: false,
            startDate: null,
            endDate: null,
            focusInputDates: null,
        };

        this.buildQuery = this.buildQuery.bind(this);
    }

    async componentDidMount() {
        const responseCategory = await api.get(`/category/list`);
        const responseSpace = await api.get(`/space/list`);
        const responseCollection = await api.get(`/collection/list`);

        const categories = responseCategory.data.data;
        const spaces = responseSpace.data.data;
        const collections = responseCollection.data.data;

        this.setState({
            ...this.state,
            loaded: true,
            categories,
            spaces,
            collections,
            filteredCategories: this.props.category ? [this.props.category] : [],
            filteredCollections: this.props.collection ? [this.props.collection] : [],
            filteredSpaces: this.props.space ? [this.props.space] : [],
        });
    }

    componentDidUpdate(prevProps) {
        // Uso tipico (no olvides de comparar los props):
        if (this.props.title !== prevProps.title) {
            this.setState({
                ...this.state,
                filteredCollections: [],
                filteredSpaces: [],
                filteredCategories: [],
            });
        }

        if (
            this.props.category !== prevProps.category ||
            this.props.space !== prevProps.space ||
            this.props.collection !== prevProps.collection
        ) {
            this.setState({
                ...this.state,
                filteredCollections: this.props.collection ? [this.props.collection] : [],
                filteredSpaces: this.props.space ? [this.props.space] : [],
                filteredCategories: this.props.category ? [this.props.category] : [],
            });
        }
    }

    closeFilter = () => {
        document.querySelector('.collection-filter').style = 'left: -365px';
    };

    handleSelectedCategories(event, categories) {
        var index = categories.indexOf(event.target.value);
        if (event.target.checked) categories.push(event.target.value);
        // push in array checked value
        else categories.splice(index, 1); // removed in array unchecked value
        this.setState(
            {
                ...this.state,
                filteredCategories: categories,
            },
            () => {
                this.props.filterProducts(this.buildQuery());
            },
        );
    }

    handleSelectedSpaces(event, spaces) {
        var index = spaces.indexOf(event.target.value);
        if (event.target.checked) spaces.push(event.target.value);
        // push in array checked value
        else spaces.splice(index, 1); // removed in array unchecked value

        this.setState(
            {
                ...this.state,
                filteredSpaces: spaces,
            },
            () => {
                this.props.filterProducts(this.buildQuery());
            },
        );
    }

    handleSelectedCollections(event, collections) {
        var index = collections.indexOf(event.target.value);
        if (event.target.checked) collections.push(event.target.value);
        // push in array checked value
        else collections.splice(index, 1); // removed in array unchecked value

        this.setState(
            {
                ...this.state,
                filteredCollections: collections,
            },
            () => {
                this.props.filterProducts(this.buildQuery());
            },
        );
    }

    handleDates(startDate, endDate) {
        this.setState(
            {
                ...this.state,
                startDate,
                endDate,
            },
            () => {
                this.setState(
                    {
                        ...this.state,
                        startDate,
                        endDate,
                    },
                    () => {
                        if (this.state.startDate && this.state.endDate) {
                            this.props.filterProducts(this.buildQuery());
                        }
                    },
                );
            },
        );
    }

    buildQuery() {
        return {
            title: this.props.title,
            categoryId:
                this.state.filteredCategories.length === 0
                    ? undefined
                    : this.state.filteredCategories,
            collectionId:
                this.state.filteredCollections.length === 0
                    ? undefined
                    : this.state.filteredCollections,
            spaceId: this.state.filteredSpaces.length === 0 ? undefined : this.state.filteredSpaces,
            dateFrom: this.state.startDate,
            dateTo: this.state.endDate,
        };
    }

    cleanFilter() {
        this.setState(
            {
                ...this.state,
                filteredCategories: [],
                filteredCollections: [],
                filteredSpaces: [],
                startDate: null,
                endDate: null,
            },
            () => {
                this.props.filterProducts(this.buildQuery());
            },
        );
    }

    render() {
        const {
            loaded,
            categories,
            spaces,
            collections,
            filteredCategories,
            filteredCollections,
            filteredSpaces,
        } = this.state;

        if (!loaded) return null;
        return (
            <div className="collection-filter-block">
                {/*brand filter start*/}
                <div className="collection-mobile-back">
                    <span className="filter-back" onClick={e => this.closeFilter(e)}>
                        <i className="fa fa-angle-left" aria-hidden="true"></i> back
                    </span>
                </div>
                <SlideToggle>
                    {({ onToggle, setCollapsibleElement, toggleState }) => (
                        <div className="collection-collapse-block">
                            <h3
                                className={`collapse-block-title ${
                                    toggleState === 'COLLAPSED' ? 'closed' : ''
                                }`}
                                onClick={onToggle}
                            >
                                rent dates
                            </h3>
                            <div
                                className="collection-collapse-block-content"
                                ref={setCollapsibleElement}
                                style={{ overflow: 'visible' }}
                            >
                                <div className="collection-brand-filter mt-2">
                                    <DateRangePicker
                                        startDate={this.state.startDate}
                                        startDateId="your_unique_start_date_id"
                                        endDate={this.state.endDate}
                                        endDateId="your_unique_end_date_id"
                                        onDatesChange={dates =>
                                            this.handleDates(dates.startDate, dates.endDate)
                                        }
                                        focusedInput={this.state.focusInputDates}
                                        onFocusChange={focusedInput =>
                                            this.setState({
                                                ...this.state,
                                                focusInputDates: focusedInput,
                                            })
                                        }
                                        numberOfMonths={1}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </SlideToggle>
                <SlideToggle>
                    {({ onToggle, setCollapsibleElement, toggleState }) => (
                        <div className="collection-collapse-block">
                            <h3
                                className={`collapse-block-title ${
                                    toggleState === 'COLLAPSED' ? 'closed' : ''
                                }`}
                                onClick={onToggle}
                            >
                                categories
                            </h3>
                            <div
                                className="collection-collapse-block-content"
                                ref={setCollapsibleElement}
                            >
                                <div className="collection-brand-filter">
                                    {categories.map((category, index) => {
                                        return (
                                            <div
                                                className="custom-control custom-checkbox collection-filter-checkbox"
                                                key={`item-cat-${index}`}
                                            >
                                                <input
                                                    type="checkbox"
                                                    onChange={e =>
                                                        this.handleSelectedCategories(
                                                            e,
                                                            filteredCategories,
                                                        )
                                                    }
                                                    value={category.id}
                                                    checked={
                                                        filteredCategories.includes(category.id)
                                                            ? true
                                                            : false
                                                    }
                                                    className="custom-control-input"
                                                    id={category.id}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor={category.id}
                                                >
                                                    {category.name}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </SlideToggle>
                <SlideToggle>
                    {({ onToggle, setCollapsibleElement, toggleState }) => (
                        <div className="collection-collapse-block">
                            <h3
                                className={`collapse-block-title ${
                                    toggleState === 'COLLAPSED' ? 'closed' : ''
                                }`}
                                onClick={onToggle}
                            >
                                spaces
                            </h3>
                            <div
                                className="collection-collapse-block-content"
                                ref={setCollapsibleElement}
                            >
                                <div className="collection-brand-filter">
                                    {spaces.map((space, index) => {
                                        return (
                                            <div
                                                className="custom-control custom-checkbox collection-filter-checkbox"
                                                key={`item-space-${index}`}
                                            >
                                                <input
                                                    type="checkbox"
                                                    onChange={e =>
                                                        this.handleSelectedSpaces(e, filteredSpaces)
                                                    }
                                                    value={space.id}
                                                    checked={
                                                        filteredSpaces.includes(space.id)
                                                            ? true
                                                            : false
                                                    }
                                                    className="custom-control-input"
                                                    id={space.id}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor={space.id}
                                                >
                                                    {space.name}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </SlideToggle>
                {collections.length > 0 ? (
                    <SlideToggle>
                        {({ onToggle, setCollapsibleElement, toggleState }) => (
                            <div className="collection-collapse-block">
                                <h3
                                    className={`collapse-block-title ${
                                        toggleState === 'COLLAPSED' ? 'closed' : ''
                                    }`}
                                    onClick={onToggle}
                                >
                                    collections
                                </h3>
                                <div
                                    className="collection-collapse-block-content"
                                    ref={setCollapsibleElement}
                                >
                                    <div className="collection-brand-filter">
                                        {collections.map((collection, index) => {
                                            return (
                                                <div
                                                    className="custom-control custom-checkbox collection-filter-checkbox"
                                                    key={`item-coll-${index}`}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        onChange={e =>
                                                            this.handleSelectedCollections(
                                                                e,
                                                                filteredCollections,
                                                            )
                                                        }
                                                        value={collection.id}
                                                        checked={
                                                            filteredCollections.includes(
                                                                collection.id,
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                        className="custom-control-input"
                                                        id={collection.id}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={collection.id}
                                                    >
                                                        {collection.name}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </SlideToggle>
                ) : null}
                <div className="mt-3 mb-3">
                    <span onClick={this.cleanFilter.bind(this)} style={{ cursor: 'pointer' }}>
                        Clean filters
                    </span>
                </div>
            </div>
        );
    }
}

export default Filter;
