import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';

import api from '../../config/axios';

function AccountInfo(props) {
    const { user } = props;
    const { handleSubmit, register, errors } = useForm();

    const [visibleModal, setVisibleModal] = useState(false);
    const [infoUser, setInfoUser] = useState();
    const [loadedGet, setLoadedGet] = useState(false);
    const [loadedPut, setLoadedPut] = useState(false);

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const updateInfo = (data, e) => {
        e.preventDefault();
        const dataRequest = {
            ...data,
            email: user.email,
        };
        setLoadedPut(true);
        api.put(`/user/${user.id}`, dataRequest)
            .then(response => {
                setLoadedPut(false);
                fetchUserInfo();
                toast('Successfully Updated !');
                setVisibleModal(false);
            })
            .catch(err => {
                setLoadedPut(false);
            });
    };

    const fetchUserInfo = () => {
        api.get(`/user/${user.id}`)
            .then(({ data }) => {
                setInfoUser(data.data);
                setLoadedGet(true);
            })
            .catch(error => {
                setLoadedGet(true);
            });
    };

    const handleEmailValidation = () => {
        setLoadedGet(false);
        api.put(`/auth/verify?email=${user.email}`)
            .then(({ data }) => {
                console.log(data);
                setLoadedGet(true);
                toast('Email sent successfully. Check your inbox!');
            })
            .catch(error => {
                console.log(error);
                setLoadedGet(true);
            });
    };

    return (
        <div>
            <div className="page-title mb-4">
                <h2>My Dashboard</h2>
            </div>
            <div className="welcome-msg">
                <p>
                    Hello, {user.firstName} {user.lastName} !
                </p>
                <p>
                    From your Dashboard you have the ability to view a snapshot of your recent
                    account activity and update your account information. Select a link below to
                    view or edit information.
                </p>
            </div>
            <div className="box-account box-info">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="box">
                            <div className="box-title">
                                <h6>Profile Information</h6>
                                <span onClick={() => setVisibleModal(true)}>Edit</span>
                            </div>
                            <div className="box-content">
                                <p>
                                    {user.firstName} {user.lastName}
                                </p>
                                <p>
                                    {user.email} &nbsp;
                                    {infoUser ? (
                                        infoUser.verified ? (
                                            <strong>
                                                <i className="fa fa-check"></i>Email verified
                                            </strong>
                                        ) : (
                                            <strong>
                                                <i className="fa fa-times"></i>Email not verified
                                            </strong>
                                        )
                                    ) : null}
                                </p>
                                {infoUser ? (
                                    <p>
                                        {!infoUser.verified && (
                                            <span onClick={handleEmailValidation}>
                                                <i className="fa fa-envelope">&nbsp;</i>
                                                Send email validation
                                            </span>
                                        )}
                                    </p>
                                ) : null}
                                {/*infoUser && infoUser.phoneNumber ? (
                                    <h6>{infoUser.phoneNumber}</h6>
                                ) : (
                                    <span onClick={() => setVisibleModal(true)}>
                                        Add phone number
                                    </span>
                                )*/}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="box">
                            <div className="box-title">
                                <h6>Company Information</h6>
                                <span onClick={() => setVisibleModal(true)}>Edit</span>
                            </div>
                            {!loadedGet ? <div className="loading-cls"></div> : null}
                            <div className="box-content">
                                {infoUser && infoUser.companyName ? (
                                    <div>
                                        <p>{infoUser.companyName}</p>
                                        <p>{infoUser.legalBusinessName}</p>
                                        <p>{infoUser.ein}</p>
                                        <p>{infoUser.typeEntity}</p>
                                    </div>
                                ) : (
                                    <p>You are currently not registered any company information.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loadedGet ? (
                <Modal open={visibleModal} onClose={() => setVisibleModal(false)} center>
                    <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="modal-header">
                                <h6>profile information</h6>
                            </div>
                            <div className="modal-body">
                                {loadedPut ? (
                                    <div className="wrapper-modal-loader">
                                        <div className="loading-cls"></div>
                                    </div>
                                ) : null}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label htmlFor="firstName">First Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="fname"
                                            name="firstName"
                                            ref={register({
                                                required: true,
                                            })}
                                            defaultValue={user && user.firstName}
                                        />
                                        <p className="message-error-input">
                                            {errors.firstName &&
                                                errors.firstName.type === 'required' &&
                                                'First Name is required'}
                                        </p>
                                    </div>
                                    <div className="col-lg-12">
                                        <label htmlFor="lastName">Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="fname"
                                            name="lastName"
                                            ref={register({
                                                required: true,
                                            })}
                                            defaultValue={user && user.lastName}
                                        />
                                        <p className="message-error-input">
                                            {errors.lastName &&
                                                errors.lastName.type === 'required' &&
                                                'First Name is required'}
                                        </p>
                                    </div>
                                    <div className="col-lg-12">
                                        <label htmlFor="phoneNumber">Phone number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            defaultValue={infoUser && infoUser.phoneNumber}
                                            ref={register({
                                                required: false,
                                                pattern: /[0-9]{10}/,
                                            })}
                                        />
                                        <p className="message-error-input">
                                            {errors.phoneNumber &&
                                                errors.phoneNumber.type === 'pattern' &&
                                                'Phone number must have 10 characters'}
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <h6>Company information</h6>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label htmlFor="companyName">Company name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="companyName"
                                            ref={register({
                                                required: true,
                                            })}
                                            defaultValue={infoUser && infoUser.companyName}
                                        />
                                        <p className="message-error-input">
                                            {errors.companyName &&
                                                errors.companyName.type === 'required' &&
                                                'Company name is required'}
                                        </p>
                                    </div>
                                    <div className="col-lg-12">
                                        <label htmlFor="legalBusinessName">
                                            Legal business name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="legalBusinessName"
                                            ref={register({
                                                required: true,
                                            })}
                                            defaultValue={infoUser && infoUser.legalBusinessName}
                                        />
                                        <p className="message-error-input">
                                            {errors.legalBusinessName &&
                                                errors.legalBusinessName.type === 'required' &&
                                                'Legal business name is required'}
                                        </p>
                                    </div>
                                    <div className="col-lg-12">
                                        <label htmlFor="typeEntity">Type entity</label>
                                        <select
                                            className="form-control"
                                            name="typeEntity"
                                            ref={register({
                                                required: true,
                                            })}
                                            defaultValue={
                                                infoUser && infoUser.typeEntity
                                                    ? infoUser.typeEntity
                                                    : ''
                                            }
                                        >
                                            <option value="" disabled>
                                                Select one
                                            </option>
                                            <option value="Sole Proprietorship">
                                                Sole Proprietorship
                                            </option>
                                            <option value="Partnership">Partnership</option>
                                            <option value="Limited Liability">
                                                Limited Liability
                                            </option>
                                            <option value="Company">Company</option>
                                            <option value="Business Corporation">
                                                Business Corporation
                                            </option>
                                        </select>
                                        <p className="message-error-input">
                                            {errors.typeEntity &&
                                                errors.typeEntity.type === 'required' &&
                                                'Type entity is required'}
                                        </p>
                                    </div>
                                    <div className="col-lg-12">
                                        <label htmlFor="ein">EIN</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="ein"
                                            ref={register({
                                                required: true,
                                                minLength: 8,
                                            })}
                                            defaultValue={infoUser && infoUser.ein}
                                        />
                                        <p className="message-error-input">
                                            {errors.ein &&
                                                errors.ein.type === 'required' &&
                                                'EIN is required'}
                                        </p>
                                        <p className="message-error-input">
                                            {errors.ein &&
                                                errors.ein.type === 'minLength' &&
                                                'EIN must have more than 8 characters'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="product-right">
                                    <button
                                        className="btn btn-solid"
                                        onClick={handleSubmit(updateInfo)}
                                    >
                                        update info
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            ) : null}
        </div>
    );
}

export default AccountInfo;
