import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useForm from 'react-hook-form';
import Pace from 'react-pace-progress';

import axios from '../../config/axios';
import Breadcrumb from '../common/breadcrumb';
import { rehydrateUser } from '../../actions/auth';
import store from '../../store';

const Login = withRouter(({ history }) => {
    const { handleSubmit, register, errors } = useForm();

    const [loading, setLoading] = useState(false);
    const [errorRequest, setErrorsRequest] = useState(null);

    const handleLogin = (data, e) => {
        e.preventDefault();
        setLoading(true);

        axios
            .post('/auth/client/login', data)
            .then(({ data }) => {
                setLoading(false);
                store.dispatch(rehydrateUser(data.data));
                window.location.href = '/'
            })
            .catch(({ response }) => {
                setLoading(false);
                setErrorsRequest(response.data.errors);
            });
    };

    return (
        <div>
            <section className="login-page section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h3>Login</h3>
                            <div className="theme-card">
                                <form onSubmit={handleSubmit(handleLogin)} className="theme-form">
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="username"
                                            placeholder="Enter your email"
                                            name="username"
                                            ref={register({
                                                required: true,
                                                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i,
                                            })}
                                        />
                                        <p className="message-error-input">
                                            {errors.username &&
                                                errors.username.type === 'required' &&
                                                'Email is required'}
                                            {errors.username &&
                                                errors.username.type === 'pattern' &&
                                                'Must be a valid email'}
                                        </p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="review">Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            placeholder="Enter your password"
                                            name="password"
                                            ref={register({
                                                required: true,
                                            })}
                                        />
                                        <p className="message-error-input">
                                            {errors.password &&
                                                errors.password.type === 'required' &&
                                                'Password is required'}
                                        </p>
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-solid"
                                        onClick={handleSubmit(handleLogin)}
                                    >
                                        Login
                                    </button>
                                </form>
                                <div className="wrapper-errors">
                                    {errorRequest
                                        ? errorRequest.map(error => (
                                              <p className="message-error-input">
                                                  <i className="fa fa-exclamation-triangle"></i>
                                                  &nbsp;{error.msg}
                                              </p>
                                          ))
                                        : null}
                                </div>
                                <div className="mt-5">
                                    <Link to={`${process.env.PUBLIC_URL}/pages/forgot-password`}>
                                        Forgot password?
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 right-login">
                            <h3>New User</h3>
                            <div className="theme-card authentication-right">
                                <h6 className="title-font">Create A Account</h6>
                                <p>
                                    Sign up for a free account at our store. Registration is quick
                                    and easy. It allows you to be able to order from our shop. To
                                    start renting click register.
                                </p>
                                <Link
                                    to={`${process.env.PUBLIC_URL}/pages/register`}
                                    className="btn btn-solid"
                                >
                                    Create an Account
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
});

export default Login;
