const initialState = {
    user: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REHYDRATE_USER':
            return { ...state, user: action.payload };
        case 'DELETE_USER':
            return { ...state, user: null };
        default:
            return state;
    }
};
export default authReducer;
