import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addToCart, addToWishlist, addToCompare } from '../../../actions/index';
import ProductItem from '../../item-product';

class SpecialProducts extends Component {
    render() {
        const { symbol, addToCart, addToWishlist, addToCompare, products } = this.props;

        return (
            <div>
                <div className="title1 section-t-space">
                    <h2 className="title-inner1">Our featured products</h2>
                </div>
                <section className="section-b-space p-t-0">
                    <div className="container">
                        {/*<Tabs className="theme-tab">
                            <TabList className="tabs tab-title">
                                <Tab>NEW PRODUCTS</Tab>
                                <Tab>FEATURED PRODUCTS</Tab>
                                <Tab>BEST SELLERS</Tab>
        </TabList>

                            <TabPanel>*/}
                        <div className="no-slider row">
                            {products.map((product, index) => (
                                <ProductItem
                                    product={product}
                                    symbol={symbol}
                                    onAddToCompareClicked={() => addToCompare(product)}
                                    onAddToWishlistClicked={() => addToWishlist(product)}
                                    onAddToCartClicked={() => addToCart(product, 1)}
                                    key={index}
                                />
                            ))}
                        </div>
                        {/*</TabPanel>
                            <TabPanel>
                                <div className="no-slider row">
                                    {featuredProducts.map((product, index) => (
                                        <ProductItem
                                            product={product}
                                            symbol={symbol}
                                            onAddToCompareClicked={() => addToCompare(product)}
                                            onAddToWishlistClicked={() => addToWishlist(product)}
                                            onAddToCartClicked={() => addToCart(product, 1)}
                                            key={index}
                                        />
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className=" no-slider row">
                                    {bestSeller.map((product, index) => (
                                        <ProductItem
                                            product={product}
                                            symbol={symbol}
                                            onAddToCompareClicked={() => addToCompare(product)}
                                            onAddToWishlistClicked={() => addToWishlist(product)}
                                            onAddToCartClicked={() => addToCart(product, 1)}
                                            key={index}
                                        />
                                    ))}
                                </div>
                            </TabPanel>
                                    </Tabs>*/}
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    symbol: state.data.symbol,
});

export default connect(mapStateToProps, { addToCart, addToWishlist, addToCompare })(
    SpecialProducts,
);
