import React, { useState, useEffect, useRef } from 'react';
import StickyBox from 'react-sticky-box';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import '../common/index.scss';

// import custom Components
import ProductListing from '../collection/common/product-listing';
import FilterBar from '../collection/common/filter-bar';
import Filter from './common/filter';

import { getValueFromQuery } from '../../utils/miscUtils';
import api from '../../config/axios';

function SearchResults() {
    const history = useHistory();
    const refFilter = useRef(null);

    const [layoutColumns, setLayoutColumns] = useState(3);
    const [products, setProducts] = useState({
        list: [],
        pagination: {
            page: 1,
        },
    });
    const [loaded, setLoaded] = useState(false);
    const [fetchingNextPage, setFetchingNextPage] = useState(false);

    const query = history.location.search;
    const queryTitle = getValueFromQuery(query, 'q');
    const queryCategory = getValueFromQuery(query, 'cat');
    const querySpace = getValueFromQuery(query, 'space');
    const queryCollection = getValueFromQuery(query, 'coll');

    useEffect(() => {
        getProducts(1, {
            title: queryTitle,
            categoryId: queryCategory || undefined,
            spaceId: querySpace || undefined,
            collectionId: queryCollection || undefined,
            dateFrom: null,
            dateTo: null,
        });
    }, [query]);

    const handleColumns = columns => {
        setLayoutColumns(columns);
    };

    const fetchProducts = async (page, query) => {
        const { data } = await api.post(`/product/search?page=${page}`, query);
        return data;
    };

    const getProducts = async (page, query) => {
        const data = await fetchProducts(page, query);
        const dataPagination = {
            ...data.meta.pagination,
            showed: data.data.rows.length,
            total: data.data.count,
        };

        setProducts({ list: data.data.rows, pagination: dataPagination });
        setLoaded(true);
    };

    const filterProducts = query => {
        getProducts(1, query);
    };

    const fetchNextPage = async () => {
        if (!fetchingNextPage) {
            setFetchingNextPage(true);
            const query = refFilter.current.buildQuery();
            const data = await fetchProducts(products.pagination.page + 1, query);
            setFetchingNextPage(false);
            const dataPagination = {
                ...data.meta.pagination,
                showed: products.pagination.showed + data.data.rows.length,
                total: data.data.count,
            };

            setProducts({
                list: [...products.list, ...data.data.rows],
                pagination: dataPagination,
            });
            return setLoaded(true);
        }
    };

    const openFilter = () => {
        document.querySelector('.collection-filter').style = 'left: -15px';
    };

    if (!loaded) return <div style={{ height: '40vh' }} className="loading-cls mt-5"></div>;

    const categoriesSelected = refFilter.current ? refFilter.current.state.filteredCategories : [queryCategory];
    return (
        <div>
            <section className="section-b-space">
                <div className="collection-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-3 collection-filter">
                                <StickyBox offsetTop={20} offsetBottom={20}>
                                    <div>
                                        <Filter
                                            filterProducts={filterProducts}
                                            title={queryTitle}
                                            category={queryCategory}
                                            collection={queryCollection}
                                            space={querySpace}
                                            ref={refFilter}
                                        />
                                    </div>
                                </StickyBox>
                                {/*side-bar banner end here*/}
                            </div>
                            <div className="collection-content col">
                                <div className="page-main-content ">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="collection-product-wrapper">
                                                    <div className="product-top-filter">
                                                        <div className="container-fluid p-0">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="filter-main-btn text-center">
                                                                        <span
                                                                            onClick={openFilter}
                                                                            className="filter-btn btn btn-theme"
                                                                        >
                                                                            <i
                                                                                className="fa fa-filter"
                                                                                aria-hidden="true"
                                                                            ></i>{' '}
                                                                            Filter
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <FilterBar
                                                                        query={queryTitle}
                                                                        showed={
                                                                            products.pagination
                                                                                .showed
                                                                        }
                                                                        length={
                                                                            products.pagination
                                                                                .total
                                                                        }
                                                                        onLayoutViewClicked={columns =>
                                                                            handleColumns(columns)
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*Products Listing Component*/}
                                                    <ProductListing
                                                        pagination={products.pagination}
                                                        products={products.list}
                                                        colSize={layoutColumns}
                                                        fetchNextPage={fetchNextPage}
                                                        categoriesSelected={categoriesSelected}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SearchResults;
