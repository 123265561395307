import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { addToWishlist, addToCompare } from '../../actions';
import ProductItem from '../item-product';

import api from '../../config/axios';

function RelatedProduct(props) {
    const { productId } = props;
    const [relatedProducts, setRelatedProducts] = useState([]);

    useEffect(() => {
        api.get(`/product/related-products/${productId}`).then(response => {
            if (response.data.data.length > 0) setRelatedProducts(response.data.data);
            else {
                api.post(`/product/search?page=1`).then(({ data }) => {
                    setRelatedProducts(data.data.rows.splice(0, 6));
                });
            }
        });
    }, []);

    return (
        <section className="section-b-space">
            <div className="container">
                <div className="row">
                    <div className="col-12 product-related">
                        <h2>related products</h2>
                    </div>
                </div>
                <div className="row search-product">
                    {relatedProducts.map((product, index) => (
                        <div key={index} className="col-xl-2 col-md-4 col-sm-6">
                            <ProductItem product={product} key={index} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

function mapStateToProps(state) {
    return {
        items: state.data.products,
        symbol: state.data.symbol,
    };
}

export default connect(mapStateToProps, { addToWishlist, addToCompare })(RelatedProduct);
