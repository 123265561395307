import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';
import Modal from 'react-responsive-modal';

import api from '../../config/axios';

function AddressBook(props) {
    const { user } = props;
    useEffect(() => {
        fetchAddresses();
    }, []);

    const { handleSubmit, register, errors } = useForm();
    const [visibleModal, setVisibleModal] = useState(false);

    useEffect(() => {
        if (!visibleModal) {
            setAddress(null);
        }
    }, [visibleModal]);

    const [loadedGet, setLoadedGet] = useState(false);
    const [loadedPut, setLoadedPut] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [address, setAddress] = useState(null);

    const fetchAddresses = async () => {
        const { data } = await api.get(`/user/${user.id}/address`);
        setAddresses(data.data);
        setLoadedGet(true);
    };

    const addAddress = address => {
        api.post(`/user/address`, address)
            .then(response => {
                setLoadedPut(false);
                setVisibleModal(false);
                toast('Address added successfully.');
                fetchAddresses();
            })
            .catch(error => {
                setLoadedPut(false);
                const errors = error.response.data.errors;

                errors.map(error => toast.error(error.message));
            });
    };

    const updateAddress = addressVerified => {
        const addressPut = { ...addressVerified, id: address.id };
        api.put(`/user/${user.id}/address/${address.id}`, addressPut)
            .then(response => {
                setLoadedPut(false);
                setVisibleModal(false);
                toast('Address updated successfully.');
                fetchAddresses();
            })
            .catch(error => {
                setLoadedPut(false);
                const errors = error.response.data.errors;

                errors.map(error => toast.error(error.message));
            });
    };

    const openModalUpdate = address => {
        setAddress(address);
        setVisibleModal(true);
    };

    const deleteAddress = id => {
        api.delete(`/user/${user.id}/address/${id}`)
            .then(response => {
                toast('Address deleted successfully.');
                fetchAddresses();
            })
            .catch(error => {
                const errors = error.response.data.errors;

                errors.map(error => toast.error(error.message));
            });
    };

    /**
     * Address verification with easypost api (backend)
     * @param {Object} data Address information
     * @param {Object} e event
     */
    const verifyAddress = async (data, e) => {
        e.preventDefault();
        setLoadedPut(true);

        const dataRequest = { ...data, country: 'US' };
        api.post(`/user/verificationAddress`, dataRequest)
            .then(response => {
                const addressInfo = {
                    ...response.data.data,
                    fullName: data.fullName,
                };

                // address hook
                if (!address) {
                    addAddress(addressInfo);
                } else {
                    updateAddress(addressInfo);
                }
            })
            .catch(error => {
                setLoadedPut(false);
                const errors = error.response.data.errors;

                errors.map(error => toast.error(error.message));
            });
    };

    return (
        <div className="wrapper-addresses">
            <div
                className="page-title mb-4"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <h2>address book</h2>
                <button className="btn btn-theme" onClick={() => setVisibleModal(true)}>
                    add address
                </button>
            </div>
            <div className="box-account box-info">
                <div className="row">
                    {!loadedGet ? (
                        <div className="loading-cls"></div>
                    ) : addresses.length > 0 ? (
                        addresses.map((address, index) => {
                            return (
                                <div className="col-sm-6 mb-5" key={`address-${index}`}>
                                    <div className="box">
                                        <div className="box-content">
                                            <div className="box-title">
                                                <h3>{address.fullName}</h3>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                    }}
                                                >
                                                    <span onClick={() => openModalUpdate(address)}>
                                                        Edit
                                                    </span>
                                                    &nbsp; &nbsp;
                                                    <span onClick={() => deleteAddress(address.id)}>
                                                        Delete
                                                    </span>
                                                </div>
                                            </div>
                                            <p>{address.street1}</p>
                                            <p>{address.street2}</p>
                                            <p>
                                                {address.city}, {address.state}
                                            </p>
                                            <p>{address.zip}</p>
                                            <p>US</p>
                                            <p>Phone number: {address.phone}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="col-sm-6 mb-5">
                            <div className="box">There are no addresses added yet.</div>
                        </div>
                    )}
                </div>
            </div>
            <Modal open={visibleModal} onClose={() => setVisibleModal(false)} center>
                <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                    <div className="modal-content quick-view-modal">
                        <div className="modal-header">
                            <h4>address information</h4>
                        </div>
                        <div className="modal-body">
                            {loadedPut ? (
                                <div className="wrapper-modal-loader">
                                    <div className="loading-cls"></div>
                                </div>
                            ) : null}
                            <div className="row">
                                <div className="col-lg-12">
                                    <label htmlFor="fullName">Full name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="fullName"
                                        ref={register({
                                            required: true,
                                        })}
                                        defaultValue={address && address.fullName}
                                    />
                                    <p className="message-error-input">
                                        {errors.fullName &&
                                            errors.fullName.type === 'required' &&
                                            'Full Name is required'}
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="street1">Street 1</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="street1"
                                        ref={register({
                                            required: true,
                                        })}
                                        defaultValue={address && address.street1}
                                    />
                                    <p className="message-error-input">
                                        {errors.street1 &&
                                            errors.street1.type === 'required' &&
                                            'Street 1 is required'}
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="street2">Street 2</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="street2"
                                        ref={register({
                                            required: false,
                                        })}
                                        defaultValue={address && address.street2}
                                    />
                                    <p className="message-error-input"></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <label htmlFor="city">City</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="city"
                                        ref={register({
                                            required: true,
                                        })}
                                        defaultValue={address && address.city}
                                    />
                                    <p className="message-error-input">
                                        {errors.city &&
                                            errors.city.type === 'required' &&
                                            'City is required'}
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="state">State</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="state"
                                        ref={register({
                                            required: true,
                                        })}
                                        defaultValue={address && address.state}
                                    />
                                    <p className="message-error-input">
                                        {errors.state &&
                                            errors.state.type === 'required' &&
                                            'State is required'}
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="zip">ZIP</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="zip"
                                        ref={register({
                                            required: true,
                                        })}
                                        defaultValue={address && address.zip}
                                    />
                                    <p className="message-error-input">
                                        {errors.zip &&
                                            errors.zip.type === 'required' &&
                                            'ZIP Code is required'}
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="phone">Phone number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="phone"
                                        name="phone"
                                        ref={register({
                                            required: true,
                                            pattern: /[0-9]{10}/,
                                        })}
                                        defaultValue={address && address.phone}
                                    />
                                    <p className="message-error-input">
                                        {errors.phone &&
                                            errors.phone.type === 'required' &&
                                            'Phone number is required'}
                                    </p>
                                    <p className="message-error-input">
                                        {errors.phone &&
                                            errors.phone.type === 'pattern' &&
                                            'Phone number must have 10 characters'}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="product-right">
                                <button
                                    className="btn btn-solid"
                                    onClick={handleSubmit(verifyAddress)}
                                >
                                    {address ? 'update' : 'add'} address
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default AddressBook;
