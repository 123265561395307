import React from 'react';

function SignInSuccessful() {
    return (
        <div className="signup-page">
            <section className="p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="error-section">
                                <h3>Welcome to louis + rocco</h3>
                                <br />
                                <p>
                                    We are so glad you are here! We have a team dedicated to serve
                                    you! If you have comments, questions or feedback, please contact
                                    us at customerservice@louisandrocco.com
                                </p>
                                <br />
                                <a href="/" className="btn btn-solid">
                                    style away
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SignInSuccessful;
