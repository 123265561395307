import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalProject from './modalProject';
import api from '../../config/axios';
import SimpleProduct from '../item-product/single';
import { currency, formatDate } from '../../utils/miscUtils';

function ProjectDetail(props) {
    const { user } = props;
    const projectId = props.match.params.projectId;

    useEffect(() => {
        Promise.all([fetchProject(), fetchSettings()]).then(() => setLoadedGet(true));
    }, []);

    const [loadedGet, setLoadedGet] = useState(false);
    const [loadedPut, setLoadedPut] = useState(false);
    const [project, setProject] = useState(null);
    const [visibleModal, setVisibleModal] = useState(false);
    const [settingsBusiness, setSettingsBusiness] = useState(null);

    const fetchProject = async () => {
        const { data } = await api.get(`/user/${user.id}/projects/${projectId}`);
        setProject(data.data);
    };

    const fetchSettings = async () => {
        const { data } = await api.get('/settings/general-info');
        setSettingsBusiness(data.data[0]);
    };

    const deleteVariantOfProject = variantId => {
        api.get(`/user/${user.id}/projects/${project.project.id}/delete/${variantId}`)
            .then(response => {
                toast('Product removed successfully');
                fetchProject();
            })
            .catch(error => console.log(error));
    };

    const variantAvailability = variantId => {
        if (project.rents && !project.orderPlaced) {
            const availability = project.rents.find(rent => rent.variantId === variantId);

            if (availability !== undefined) {
                return (
                    <div className="mt-2">
                        <p style={{ color: 'red' }}>
                            {`There are no items available during your rental period`}
                        </p>
                    </div>
                );
            }
        }
    };

    const updateProjectInfo = data => {
        return api
            .put(`/user/${user.id}/projects/${project.project.id}`, data)
            .then(response => {
                setVisibleModal(false);
                toast('Project updated successfully.');
                fetchProject();
            })
            .catch(error => {
                const errors = error.response.data.errors;

                errors.map(error => toast.error(error.message));
            });
    };

    // Project dates is valid if: (start date - range_dates_rent) <= today
    // e.g: March 10 - (3 days range) = March 7. Today must be March 6 or older (March 5, March 4, etc)

    // If the project (start date - range_dates_rent) is equal or greater than today return false
    // else return true and you can continue to checkout
    const projectWithDaysOfBuffer = () => {
        const today = moment().format('MM/DD/YYYY');

        const periodOfBuffer = moment(project.project.dateFrom).diff(today, 'days');

        if (periodOfBuffer >= settingsBusiness.range_dates_rent) return true;
        return false;
    };

    // Colchón de tiempo entre fecha de inicio de renta y fecha de fin de renta
    // Este colchón de tiempo es el tiempo que tiene el producto para enviarse hasta el cliente
    // y de regreso al warehouse. Por lo tanto ese tiempo se toma como que la variante
    // NO está disponible

    // Get the total quantity of units of the project
    // If quantity of units < minime_articles_purchase return false
    // else return true and you can continue to checkout
    const haveMinUnitsToPurchase = () => {
        const quantityOfUnits = project.project.variants
            .map(variant => variant.projectVariant.quantity)
            .reduce((a, b) => a + b, 0);

        if (quantityOfUnits >= Number(settingsBusiness.minime_articles_purchase)) {
            return true;
        }
        return false;
    };

    // If project does not have dateFrom (start date) and dateTo (end date) return false
    // else return true and you can continue to checkout
    const projectHaveDates = () => {
        if (project.rents.length === 0 && project.project.dateFrom && project.project.dateTo) {
            return true;
        }
        return false;
    };

    const handleClickCheckout = () => {
        if (haveMinUnitsToPurchase()) {
            if (projectHaveDates()) {
                props.history.push(`${process.env.PUBLIC_URL}/checkout?p=${projectId}`);
            } else {
                toast('Add dates to the project to make the rent');
            }
        } else {
            toast(
                `Must rent at least ${settingsBusiness.minime_articles_purchase} units to purchase.`,
            );
        }
    };

    if (!loadedGet) return null;

    let total = 0;
    // cal rent days
    const rentDays = moment(project.project.dateTo).diff(moment(project.project.dateFrom), 'days');

    // calc total rent
    project.project.variants.forEach(
        variant => (total += variant.projectVariant.quantity * variant.price_rent * rentDays),
    );

    return (
        <div>
            <div className="container pt-3">
                <div>
                    <Link to={`${process.env.PUBLIC_URL}/projects`}>
                        <i className="fa fa-chevron-left"></i>&nbsp;Back to My Projects
                    </Link>
                </div>
                <div className="title5 mt-3">
                    <div className="row subtitle-project-detail">
                        <div className="col-lg-6">
                            <h3 className="title-inner2">{project.project.name}</h3>

                            {project.project.dateFrom && project.project.dateTo && (
                                <p>
                                    From:{' '}
                                    {moment(project.project.dateFrom.split('T')[0]).format(
                                        formatDate,
                                    )}{' '}
                                    To:{' '}
                                    {moment(project.project.dateTo.split('T')[0]).format(
                                        formatDate,
                                    )}
                                </p>
                            )}
                            {project.project.address ? (
                                <p className="mt-3">
                                    {project.project.address.street1}
                                    {project.project.address.street2
                                        ? `, ${project.project.address.street2}.`
                                        : '. '}
                                    {project.project.address.city}, {project.project.address.state}.{' '}
                                    {project.project.address.zip}
                                </p>
                            ) : null}
                            {!project.orderPlaced ? (
                                <button
                                    className="btn btn-sm btn-theme"
                                    onClick={() => setVisibleModal(true)}
                                >
                                    edit project
                                </button>
                            ) : null}
                        </div>
                        <div className="subtitle-section-right col-lg-6">
                            {project.project.variants.length > 0 ? (
                                <div>
                                    {projectHaveDates() ? (
                                        <h6>
                                            Total project:{' '}
                                            <strong>
                                                {currency(
                                                    project.orderPlaced
                                                        ? project.orderPlaced.priceTotal
                                                        : total,
                                                )}
                                            </strong>
                                        </h6>
                                    ) : null}
                                </div>
                            ) : null}
                            <div className="mb-2">
                                {!project.orderPlaced && project.project.variants.length > 0 ? (
                                    <button
                                        className={`btn btn-lg btn-solid ${
                                            project.rents.length > 0 ||
                                            !projectHaveDates() ||
                                            !haveMinUnitsToPurchase() ||
                                            !projectWithDaysOfBuffer()
                                                ? 'btn-disabled'
                                                : ''
                                        }`}
                                        onClick={handleClickCheckout}
                                    >
                                        checkout
                                    </button>
                                ) : null}
                                {project.orderPlaced ? (
                                    <div>
                                        <Link
                                            className={`btn btn-theme 
                                            `}
                                            to={`${process.env.PUBLIC_URL}/rents/${project.orderPlaced.id}`}
                                        >
                                            view order
                                        </Link>
                                    </div>
                                ) : null}
                            </div>
                            {project.rents.length > 0 && !project.orderPlaced ? (
                                <p className="message-error-input">
                                    Some items are not available during your rental period
                                </p>
                            ) : null}
                            {!haveMinUnitsToPurchase() && !project.orderPlaced ? (
                                <p className="message-error-input">
                                    Must rent at least {settingsBusiness.minime_articles_purchase}{' '}
                                    units to purchase
                                </p>
                            ) : null}
                            {!projectHaveDates() && !project.orderPlaced ? (
                                <p className="message-error-input">
                                    Add dates to the project to make the rent
                                </p>
                            ) : null}
                            {!projectWithDaysOfBuffer() && !project.orderPlaced ? (
                                <p className="message-error-input">
                                    Update rent days to make the rent. <br />
                                    Must have at least {settingsBusiness.range_dates_rent} days
                                    between Project's start date and today
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="product-wrapper-grid pb-5 mt-5">
                    <div className="container-fluid">
                        {project.project.variants.length > 0 ? (
                            <div>
                                <div className="row">
                                    {project.project.variants.map((variant, index) => {
                                        return (
                                            <div
                                                className="col-xl-3 col-md-6 col-grid-box"
                                                key={`variant-${variant.id}`}
                                            >
                                                <SimpleProduct
                                                    user={user}
                                                    project={project}
                                                    variant={variant}
                                                    deleteVariantOfProject={deleteVariantOfProject}
                                                    orderPlaced={project.orderPlaced}
                                                    fetchProject={fetchProject}
                                                >
                                                    <p className="mt-1">
                                                        Quantity:{' '}
                                                        <strong>
                                                            {variant.projectVariant.quantity}
                                                        </strong>
                                                    </p>
                                                    {variantAvailability(variant.id)}
                                                </SimpleProduct>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                    <h3>There are not products added yet! </h3>
                                    <p>Add products to this project to start renting</p>
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/search`}
                                        className="btn btn-solid"
                                    >
                                        continue shopping
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ModalProject
                visibleModal={visibleModal}
                setVisibleModal={setVisibleModal}
                project={project.project}
                submitAction={updateProjectInfo}
                loadingPut={loadedPut}
                user={user}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    symbol: state.data.symbol,
    user: state.auth.user.user,
});

export default connect(mapStateToProps, {})(ProjectDetail);
