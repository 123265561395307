import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { addToCart, addToWishlist, addToCompare } from '../../../actions';
import ProductItem from '../../item-product';

function ProductListing(props) {
    const {
        addToCart,
        addToWishlist,
        categoriesSelected,
        products,
        symbol,
        addToCompare,
        fetchNextPage,
        pagination,
    } = props;
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        if (products.length >= pagination.total) {
            setHasMoreItems(false);
        } else {
            setHasMoreItems(true);
        }
    }, [products]);

    useEffect(() => {
        // Handle infinite scroll
        window.onscroll = async function(ev) {
            if (
                window.innerHeight + window.scrollY >= document.body.offsetHeight - 300 &&
                !fetching
            ) {
                if (products.length < pagination.total) {
                    setFetching(true);
                    await fetchNextPage();
                    setFetching(false);
                }
            }
        };
        return () => {
            window.onscroll = null;
        };
    });

    return (
        <div>
            <div className="product-wrapper-grid mt-2">
                <div className="container-fluid">
                    {products.length > 0 ? (
                        <div className="row">
                            {products.map((product, index) => (
                                <div
                                    className={`${
                                        props.colSize === 3
                                            ? 'col-xl-3 col-md-6 col-grid-box'
                                            : 'col-lg-' + props.colSize
                                    }`}
                                    key={`product-${product.id}`}
                                >
                                    <ProductItem
                                        product={product}
                                        symbol={symbol}
                                        onAddToCompareClicked={() => addToCompare(product)}
                                        onAddToWishlistClicked={() => addToWishlist(product)}
                                        onAddToCartClicked={addToCart}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        categoriesSelected.length < 1 && 
                        <div className="row">
                            <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                <h3>Sorry, we couldn't find a match for your search. </h3>
                                <p>
                                    Change your search filters, check your spelling, or try a less
                                    specific search
                                </p>
                            </div>
                        </div>
                        
                    )}
                    {products.length <= 6 && categoriesSelected.length >= 1 && (
                        <div className="row">
                            <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                <h3>We are loading up our inventory.</h3>
                                <p>Please check-in later as we are currently uploading our inventory with amazing accessories to choose from.</p>
                                { !props.auth.user && <Link
                                        to={`${process.env.PUBLIC_URL}/pages/register`}
                                        className="btn btn-solid"
                                    >
                                        Sign up for updates
                                    </Link>
                                }
                            </div>
                        </div>
                    )}
                    {hasMoreItems ? <div className="loading-cls"></div> : null}
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = state => ({
    symbol: state.data.symbol,
    auth: state.auth
});

export default connect(mapStateToProps, { addToCart, addToWishlist, addToCompare })(ProductListing);
