import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import Slider from 'react-slick';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import api from '../../../../config/axios';
import Select from 'react-select';
import ModalProject from './../../../wishlist/modalProject';
import { currency, formatDate } from '../../../../utils/miscUtils';

function DetailsWithPrice(props) {
    const { item, variantSelected, user } = props;
    const [modalProject, setModalProject] = useState(false);
    const [loadedProjects, setLoadedProjects] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState();
    const [quantity, setQuantity] = useState(1);
    const [availableQuantity, setAvailableQuantity] = useState();
    const [visibleModal, setVisibleModal] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (user) {
            fetchProjects();
        }
    }, []);

    useEffect(
        () => {
            setVariantAvailability();
        },
        [selectedProject],
    );

    const fetchProjects = async () => {
        setLoadedProjects(false);
        const { data } = await api.get(`/user/${user.id}/projects`);
        // filter by open projects
        setProjects(
            data.data.rows
                .filter(project => !project.orderPlaced)
                .map(item => ({ ...item, inputQuantity: false })),
        );
        setLoadedProjects(true);
    };

    const addProject = data => {
        api.post(`/user/${user.id}/projects`, data)
            .then(() => {
                setVisibleModal(false);
                toast('Project added successfully.');
                fetchProjects();
            })
            .catch(error => {
                const errors = error.response.data.errors;

                errors.map(error => toast.error(error.message));
            });
    };

    const changeQty = e => {
        if (e.target.value && e.target.value !== '') {
            setQuantity(parseInt(e.target.value));
        } else {
            setQuantity(0);
        }
    };

    const minusQty = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const plusQty = () => {
        if (variantSelected.quantity > quantity) {
            setQuantity(quantity + 1);
        }
    };

    const setVariantAvailability = async () => {
        if (selectedProject !== undefined) {
            const variantsAvailableOnDate = await fetchVarianAvailability(
                selectedProject.dateFrom,
                selectedProject.dateTo,
            );
            const projectVariant = selectedProject.variants.find(
                variant => variant.id === variantSelected.id,
            );

            let projectVariantQuantity = 0;
            if (projectVariant !== undefined) {
                projectVariantQuantity = projectVariant.projectVariant.quantity;
            }

            if (projectVariantQuantity <= variantsAvailableOnDate) {
                setAvailableQuantity(variantsAvailableOnDate - projectVariantQuantity);
            } else if (projectVariantQuantity > variantsAvailableOnDate) {
                setAvailableQuantity(0);
            } else {
                setAvailableQuantity(variantsAvailableOnDate);
            }
        }
    };

    const fetchVarianAvailability = async (fromDate, toDate) => {
        return new Promise(async (resolve, reject) => {
            api.get(`/variant/${variantSelected.id}/availability`, {
                params: {
                    fromDate,
                    toDate,
                },
            })
                .then(response => {
                    resolve(response.data.data.availableItems);
                })
                .catch(error => console.log(error));
        });
    };

    const addVariantToProject = (projectId, quantity) => {
        api.post(`/user/${user.id}/projects/${projectId}/add/${variantSelected.id}`, {
            quantity,
        })
            .then(() => {
                toast('Product added to the project', { autoClose: true });
                fetchProjects();
            })
            .catch(error => console.log(error));
    };

    const handleAddToProject = () => {
        if (quantity <= availableQuantity) {
            addVariantToProject(selectedProject.id, quantity);
        } else {
            toast('Sorry, quantity exceeds stock availability. Try again.');
        }
    };

    const handleOnSelectProject = option => {
        setSelectedProject(option.value);
    };

    var colorsnav = {
        slidesToShow: 6,
        swipeToSlide: true,
        arrows: false,
        dots: false,
        focusOnSelect: true,
    };

    const quantityComponent = () => {
        if (availableQuantity === undefined) {
            return null;
        }

        if (availableQuantity > 0) {
            return (
                <div className="product-description border-product">
                    <h6 className="product-title">Quantity</h6>
                    <div className="qty-box">
                        <div className="input-group">
                            <span className="input-group-prepend" style={{ zIndex: 0 }}>
                                <button
                                    type="button"
                                    className="btn quantity-left-minus"
                                    onClick={minusQty}
                                    data-type="minus"
                                    data-field=""
                                >
                                    <i className="fa fa-angle-left" />
                                </button>
                            </span>
                            <input
                                type="text"
                                name="quantity"
                                value={quantity}
                                max={availableQuantity}
                                onChange={changeQty}
                                className="form-control input-number"
                            />
                            <span className="input-group-prepend" style={{ zIndex: 0 }}>
                                <button
                                    type="button"
                                    className="btn quantity-right-plus"
                                    onClick={plusQty}
                                    data-type="plus"
                                    data-field=""
                                >
                                    <i className="fa fa-angle-right" />
                                </button>
                            </span>
                        </div>
                        <span className="ml-2">{availableQuantity} items available</span>
                    </div>
                    <button onClick={handleAddToProject} className="btn btn-solid mt-3">
                        Add to project
                    </button>
                </div>
            );
        } else {
            return (
                <div className="product-description border-product">
                    <h6 className="product-title">Quantity</h6>
                    <span>There are no items available during your project rental period</span>
                </div>
            );
        }
    };

    return (
        <div className="col-lg-6 rtl-text">
            <div className="product-right">
                <h3> {item.title} </h3>
                {/* <h5 className="mb-0">
                    {currency(variantSelected.price_rent)}{' '}
                    <span style={{ fontSize: 15 }}>/day rental</span>
                </h5>
                <h5 className="mt-1 mb-2 product-detail-retail-price">
                    {' '}
                    {currency(variantSelected.price_retail)} retail
                </h5> */}
                {item.variants && item.variants.length > 1 ? (
                    <ul>
                        <Slider {...colorsnav} className="color-variant">
                            {item.variants.map((vari, i) => {
                                return (
                                    <li
                                        className={vari.images}
                                        key={`color-variant${i}`}
                                        title={vari.color}
                                    >
                                        <img
                                            onClick={() => props.changeVariant(vari)}
                                            src={vari.images[0].url}
                                            alt={item.name}
                                        />
                                    </li>
                                );
                            })}
                        </Slider>
                    </ul>
                ) : (
                    ''
                )}
                {user ? (
                    <div className="product-description border-product">
                        <h6 className="product-title size-text">Project</h6>
                        <div className="form-group mt-2">
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                onChange={option => handleOnSelectProject(option)}
                                options={projects.map(project => {
                                    return {
                                        value: project,
                                        label: project.name,
                                    };
                                })}
                            />
                        </div>
                        <div className="create-project">
                            <span onClick={() => setVisibleModal(true)}>Create new project</span>
                        </div>
                    </div>
                ) : null}
                {quantityComponent()}
                {item.description !== 'No description' ? (
                    <div className="border-product">
                        <h6 className="product-title">Description</h6>
                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                ) : null}
            </div>
            {user ? (
                <ModalProject
                    visibleModal={visibleModal}
                    setVisibleModal={setVisibleModal}
                    submitAction={addProject}
                    user={user}
                />
            ) : null}
        </div>
    );
}

function ItemModalProject(props) {
    const { project, isActive, handleVariantProject, variantSelected } = props;
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        if (isActive) {
            const variant = project.variants.find(variant => variant.id === variantSelected.id);
            setQuantity(variant.projectVariant.quantity);
        }
    }, []);

    const minusQty = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const plusQty = () => {
        if (variantSelected.quantity > quantity) {
            setQuantity(quantity + 1);
        }
    };

    const changeQty = e => {
        if (e.target.value && e.target.value !== '') {
            setQuantity(parseInt(e.target.value));
        } else {
            setQuantity(0);
        }
    };

    return (
        <div className="col-lg-12 item-project-modal">
            <div className="wrapper-project-modal">
                <div className="description-project-modal">
                    <Link to={`/projects/${project.id}`}>
                        <strong>{project.name}</strong>
                        {project.dateFrom && project.dateTo && (
                            <p>
                                {moment(project.dateFrom.split('T')[0]).format(formatDate)}{' '}
                                <i className="fa fa-arrow-right" />
                                &nbsp;
                                {moment(project.dateTo.split('T')[0]).format(formatDate)}
                            </p>
                        )}
                    </Link>
                </div>
                <div className="option-project-modal">
                    {!isActive ? (
                        <div className="qty-box">
                            <div className="input-group">
                                <span className="input-group-prepend">
                                    <button
                                        type="button"
                                        className="btn quantity-left-minus"
                                        onClick={minusQty}
                                        data-type="minus"
                                        data-field=""
                                    >
                                        <i className="fa fa-angle-left" />
                                    </button>
                                </span>
                                <input
                                    type="text"
                                    name="quantity"
                                    value={quantity}
                                    max={variantSelected.quantity}
                                    onChange={changeQty}
                                    className="form-control input-number"
                                />
                                <span className="input-group-prepend">
                                    <button
                                        type="button"
                                        className="btn quantity-right-plus"
                                        onClick={plusQty}
                                        data-type="plus"
                                        data-field=""
                                    >
                                        <i className="fa fa-angle-right" />
                                    </button>
                                </span>
                            </div>
                        </div>
                    ) : null}
                    {isActive ? (
                        <span>
                            {quantity} unit(s) added <i className="fa fa-check" />
                        </span>
                    ) : (
                        <button
                            onClick={() => handleVariantProject(isActive, project.id, quantity)}
                            className="btn-sm btn-outline ml-2"
                        >
                            add
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DetailsWithPrice;
