import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from '../../config/axios';
import { getValueFromQuery } from '../../utils/miscUtils';
import Pace from 'react-pace-progress';

function VerifiedEmail() {
    const history = useHistory();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const token = getValueFromQuery(history.location.search, 'token');

        if (token === '') {
            history.push('/404');
        }

        axios
            .put(`/auth/verify/${token}`)
            .then(({ data }) => {
                setLoaded(true);
            })
            .catch(({ response }) => {
                history.push('/404');
                setLoaded(true);
            });
    }, []);
    if (!loaded) {
        return (
            <div>
                <Pace color="#ff4c3b" />
                <section className="p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="error-section">
                                    <h2>Loading</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
    return (
        <div>
            <section className="p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="error-section">
                                <h1>OK</h1>
                                <h2>Email verified successfully</h2>
                                <br />
                                <a href="/" className="btn btn-solid">
                                    go to home
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default VerifiedEmail;
