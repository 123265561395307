import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import axios from '../../config/axios';
import Pace from 'react-pace-progress';

import { rehydrateUser } from '../../actions/auth';
import store from '../../store';

const Register = withRouter(({ history }) => {
    const { handleSubmit, register, errors, watch } = useForm();

    const [loading, setLoading] = useState(false);
    const [errorRequest, setErrorsRequest] = useState(null);

    const handleSignUp = (data, e) => {
        e.preventDefault();
        setLoading(true);

        // sign up
        axios
            .post('/auth/signup', data)
            .then(responseSignup => {
                // sign in
                axios
                    .post('/auth/client/login', { username: data.email, password: data.password })
                    .then(responseLogin => {
                        setLoading(false);
                        store.dispatch(rehydrateUser(responseLogin.data.data));
                        window.location.href = '/signup-successful'
                    })
                    .catch(({ response }) => {
                        setLoading(false);
                        setErrorsRequest(response.data.errors);
                    });
            })
            .catch(({ response }) => {
                setLoading(false);
                setErrorsRequest(response.data.errors);
            });
    };

    return (
        <div>
            {loading ? <Pace color="#ff4c3b" /> : null}

            {/*Regsiter section*/}
            <section className="register-page section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>create account</h3>
                            <div className="theme-card">
                                <form onSubmit={handleSubmit(handleSignUp)} className="theme-form">
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <label htmlFor="email">First Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fname"
                                                name="firstName"
                                                placeholder="First Name"
                                                ref={register({
                                                    required: true,
                                                })}
                                            />
                                            <p className="message-error-input">
                                                {errors.firstName &&
                                                    errors.firstName.type === 'required' &&
                                                    'First Name is required'}
                                            </p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="review">Last Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="lname"
                                                name="lastName"
                                                placeholder="Last Name"
                                                ref={register({
                                                    required: true,
                                                })}
                                            />
                                            <p className="message-error-input">
                                                {errors.lastName &&
                                                    errors.lastName.type === 'required' &&
                                                    'Last Name is required'}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <label htmlFor="email">Email</label>

                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                placeholder="Email"
                                                ref={register({
                                                    required: true,
                                                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i,
                                                })}
                                            />
                                            <p className="message-error-input">
                                                {errors.email &&
                                                    errors.email.type === 'required' &&
                                                    'Email is required'}
                                                {errors.email &&
                                                    errors.email.type === 'pattern' &&
                                                    'Must be a valid email'}
                                            </p>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="review">Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="pass"
                                                name="password"
                                                placeholder="Must contain at least 8 characters and at least 1 number"
                                                ref={register({
                                                    required: true,
                                                    pattern: /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z!"#$%&()=?¿*-_.:,;+^\\-`.+,/\"]{8,}$/i,
                                                })}
                                            />
                                            <p className="message-error-input">
                                                {errors.password &&
                                                    errors.password.type === 'required' &&
                                                    'Password is required'}
                                                {errors.password &&
                                                    errors.password.type === 'pattern' &&
                                                    'Must contain at least 8 characters and at least 1 number'}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6">
                                            <label htmlFor="review">Confirm password</label>

                                            <input
                                                type="password"
                                                className="form-control"
                                                id="repass"
                                                placeholder="Type again your password"
                                                name="repassword"
                                                ref={register({
                                                    required: true,
                                                    validate: value => value === watch('password'),
                                                })}
                                            />
                                            <p className="message-error-input">
                                                {errors.repassword &&
                                                    errors.repassword.type === 'required' &&
                                                    'Please confirm your password'}
                                                {errors.repassword &&
                                                    errors.repassword.type === 'validate' &&
                                                    'Passwords do not match'}
                                            </p>
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-solid"
                                            onClick={handleSubmit(handleSignUp)}
                                        >
                                            create Account
                                        </button>
                                    </div>
                                    <div className="wrapper-errors">
                                        {errorRequest
                                            ? errorRequest.map(error => (
                                                  <p className="message-error-input">
                                                      <i className="fa fa-exclamation-triangle"></i>
                                                      &nbsp;{error.msg}
                                                  </p>
                                              ))
                                            : null}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
});

export default Register;
