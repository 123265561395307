import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual';

import api from '../../../../config/axios';
import store from '../../../../store';
import { deleteUser } from '../../../../actions/auth';

class NavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navClose: { right: '0px' },
            categories: [],
            spaces: [],
            collections: [],
        };

        this.logout = this.logout.bind(this);
    }

    async componentDidMount() {
        Promise.all([
            api.get(`/category/list`),
            api.get(`/space/list`),
            api.get(`/collection/list`),
        ]).then(values => {
            this.setState({
                categories: values[0].data.data,
                spaces: values[1].data.data,
                collections: values[2].data.data,
            });
        });
    }

    componentWillMount() {
        if (window.innerWidth < 750) {
            this.setState({ navClose: { right: '-410px' } });
        }
        if (window.innerWidth < 1199) {
            this.setState({ navClose: { right: '-300px' } });
        }
    }

    openNav() {
        this.setState({ navClose: { right: '0px' } });
    }
    closeNav() {
        this.setState({ navClose: { right: '-410px' } });
    }

    onMouseEnterHandler() {
        if (window.innerWidth > 1199) {
            document.querySelector('#main-menu').classList.add('hover-unset');
        }
    }

    handleSubmenu = event => {
        if (event.target.classList.contains('sub-arrow')) return;
        if (event.target.nextElementSibling.classList.contains('opensubmenu'))
            event.target.nextElementSibling.classList.remove('opensubmenu');
        else {
            document.querySelectorAll('.nav-submenu').forEach(function(value) {
                value.classList.remove('opensubmenu');
            });
            event.target.nextElementSibling.classList.add('opensubmenu');
        }
    };

    logout() {
        store.dispatch(deleteUser());
        window.location.reload();
    }

    render() {
        const user = store.getState().auth.user ? store.getState().auth.user.user : null;
        const { translate } = this.props;
        return (
            <div>
                <div className="main-navbar">
                    <div id="mainnav">
                        <div className="toggle-nav" onClick={this.openNav.bind(this)}>
                            <i className="fa fa-bars sidebar-bar"></i>
                        </div>
                        <ul className="nav-menu" style={this.state.navClose}>
                            <li className="back-btn" onClick={this.closeNav.bind(this)}>
                                <div className="mobile-back text-right">
                                    <span>Back</span>
                                    <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                                </div>
                            </li>
                            <li>
                                <Link to={`${process.env.PUBLIC_URL}/`} className="nav-link">
                                    {translate('home')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={`${process.env.PUBLIC_URL}/howitworks`}
                                    className="nav-link"
                                >
                                    How it works
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="nav-link"
                                    onClick={e => this.handleSubmenu(e)}
                                >
                                    Categories
                                    {/* <span className="sub-arrow"></span> */}
                                </Link>
                                <ul className="nav-submenu">
                                    {this.state.categories.map((category, index) => (
                                        <li key={`item-nav${index}`}>
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/search?q=&cat=${category.id}`}
                                            >
                                                {category.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="nav-link"
                                    onClick={e => this.handleSubmenu(e)}
                                >
                                    Spaces
                                    {/* <span className="sub-arrow"></span> */}
                                </Link>
                                <ul className="nav-submenu">
                                    {this.state.spaces.map((space, index) => (
                                        <li key={`item-nav${index}`}>
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/search?q=&space=${space.id}`}
                                            >
                                                {space.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            {this.state.collections.length > 0 ? (
                                <li>
                                    <Link
                                        to="#"
                                        className="nav-link"
                                        onClick={e => this.handleSubmenu(e)}
                                    >
                                        Collections
                                        {/* <span className="sub-arrow"></span> */}
                                    </Link>
                                    <ul className="nav-submenu">
                                        {this.state.collections.map((collection, index) => (
                                            <li key={`item-nav${index}`}>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/search?q=&coll=${collection.id}`}
                                                >
                                                    {collection.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ) : null}
                            <hr className="hidden-lg"></hr>
                            {user ? (
                                <li className="hidden-lg">
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/projects`}
                                        className="nav-link"
                                    >
                                        Projects
                                    </Link>
                                </li>
                            ) : null}
                            {user ? (
                                <li className="hidden-lg">
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/user/profile`}
                                        className="nav-link"
                                    >
                                        Profile
                                    </Link>
                                </li>
                            ) : (
                                <li className="hidden-lg">
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/pages/login`}
                                        className="nav-link"
                                    >
                                        Login
                                    </Link>
                                </li>
                            )}
                            {user ? (
                                <li
                                    className="hidden-lg"
                                    style={{ position: 'absolute', bottom: 10 }}
                                    onClick={this.logout}
                                >
                                    <Link to="/" className="nav-link">
                                        logout
                                    </Link>
                                </li>
                            ) : null}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslate(NavBar);
