import React from 'react';
import { Helmet } from 'react-helmet';

function PrivacyPolicy() {
    return (
        <div>
            <Helmet>
                <title>Privacy - Louis & Rocco</title>
                <meta property="og:title" content="Privacy - Louis & Rocco" />
                <meta
                    property="og:image"
                    content={'https://louisandrocco.com/assets/images/icon/logo/logo_short.png'}
                />
                <meta property="og:url" content={`${process.env.PUBLIC_URL}/privacy`} />
            </Helmet>
            <section className="p-0">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-12 product-related">
                            <h2>privacy policy</h2>
                            <h4>Last updated: 06/05/2020</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="mt-3 mb-5">
                                <p>
                                    LOUIS & ROCCO takes the privacy of our customers very seriously.
                                    We value our customers and respect your privacy. We collect
                                    information about you in an effort to communicate with you about
                                    our products, services and promotions. Neither your name nor
                                    anything about you is sold or shared with any other
                                    non-affiliated company or agency.
                                </p>
                                <p>
                                    This Privacy Policy describes the information we collect about
                                    you, why we collect it, how we use it and when we share it with
                                    third parties. Our Privacy Policy also describes the choices you
                                    can make about how we collect and use certain information.
                                </p>
                                <p>
                                    All items are final sale, and not eligible for refunds or
                                    exchanges.
                                </p>
                                <div className="mt-5 mb-3">
                                    <h3>WHEN DO WE COLLECT YOUR INFORMATION?</h3>
                                </div>
                                <p>
                                    LOUIS & ROCCO and its Service Providers collect information
                                    relating to you and your use of our websites in order to provide
                                    services and features that are easy to use and responsive to
                                    your needs. LOUIS & ROCCO collects personal information in the
                                    following ways:
                                </p>
                                <div className="ml-4">
                                    <h4>
                                        FROM YOU WHEN YOU REGISTER AN ACCOUNT AND COMMUNICATE WITH
                                        US.
                                    </h4>
                                    <p>
                                        We collect information from you when you register an account
                                        with us, request information, send us email, send us postal
                                        mail, or speak with us on the telephone.
                                    </p>
                                    <h4>FROM THIRD PARTY SOURCES.</h4>
                                    <p>
                                        We may collect information from our Service Providers who
                                        help us to provide services, or from other third-party
                                        Service Providers.
                                    </p>
                                    <h4>FROM SOCIAL MEDIA.</h4>
                                    <p>
                                        When you interact with us over social media, we may collect
                                        information about you. If you post information or otherwise
                                        interact with us through social media websites, social media
                                        platform plug-ins, or other applications we use, we may
                                        collect or receive information that you provide or that is
                                        automatically collected by the social media platform.
                                        Information that you post on social media platforms may
                                        become public, depending on your account settings.
                                        Information collected by social media platforms when you
                                        interact with their services is governed by the social media
                                        platforms’ respective privacy policies. For more information
                                        about how you can customize your privacy settings and how
                                        third-party social media websites handle your personal
                                        information, please refer to their privacy policies. We have
                                        a presence on{' '}
                                        <a
                                            href="https://www.facebook.com/MARGARITA.BRAVO.DESIGNS/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Facebook
                                        </a>
                                        ,{' '}
                                        <a
                                            href="https://twitter.com/margaritabravo_"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Twitter
                                        </a>
                                        ,{' '}
                                        <a
                                            href="https://www.instagram.com/margaritabravo__/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Instagram
                                        </a>
                                        ,{' '}
                                        <a
                                            href="https://www.pinterest.com/margaritabravo_/_created/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Pinterest
                                        </a>
                                        , and{' '}
                                        <a
                                            href="https://www.youtube.com/channel/UCx3kPrr7iL9dU4pRaeuJQNw"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            YouTube
                                        </a>
                                        .
                                    </p>
                                </div>
                                <div className="mt-5 mb-3">
                                    <h3>WHAT INFORMATION DO WE COLLECT?</h3>
                                </div>
                                <h4>INFORMATION YOU PROVIDE TO US</h4>
                                <p>
                                    When you provide personal information to us, we use it for the
                                    purposes for which it was provided to us as stated at the point
                                    of collection or as obvious from the context of collection. For
                                    example, if you send us an email requesting information, we will
                                    use your personal information to respond to your request. We may
                                    collect the following information:
                                </p>
                                <ul className="list-privacy ml-4">
                                    <li>
                                        <i className="fa fa-chevron-right" />Name
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Address
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Email address
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Payment information
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Colorado Resale Number
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Telephone number
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Company Name
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Communication
                                        Preference
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Website URL
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />
                                        Comments, questions, or other information provided in online
                                        forms or via chat function
                                    </li>
                                </ul>
                                <div className="mt-5 mb-3">
                                    <h3>
                                        INFORMATION WE AND OUR THIRD PARTY SERVICE PROVIDERS COLLECT
                                    </h3>
                                </div>
                                <p>
                                    When you use our website, we and our third party Service
                                    Providers automatically collect information about how you access
                                    and use the websites, and information about the device you use
                                    to access the websites. We typically collect this information
                                    through a variety of tools, including cookies, web beacons,
                                    pixels, social media widgets, and similar technology
                                    (collectively, “tracking technologies”). We and our third party
                                    partners may automatically collect such information as:
                                </p>
                                <ul className="list-privacy ml-4 mb-3">
                                    <li>
                                        <i className="fa fa-chevron-right" />IP Address
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Device Type
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Browser Type
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Operating System or
                                        Platform
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Name of Internet
                                        Service Provider
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Referring Website
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Exiting Website
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Pages visited on the
                                        website
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Frequency, time, data,
                                        and duration of your visit on our website
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Your location
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Whether you access our
                                        website from multiple devices
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Other interactions you
                                        may have on our website
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Device Unique Device
                                        Identifier (e.g., Unique Device Identifier (UDID) or
                                        International Mobile Equipment Identity (IMEI))
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Mobile device MAC or IP
                                        address
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Information about your
                                        mobile carrier
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Communications relating
                                        to customer support, and information drawn from such
                                        communications, for statistical and reference purposes
                                    </li>
                                </ul>
                                <h4>INFORMATION YOU PROVIDE TO US</h4>
                                <p>
                                    We may receive information about you from our third-party
                                    service providers who help us to provide products and services
                                    to you, including our website when you interact with those
                                    Service Providers.
                                </p>
                                <div className="mt-5 mb-3">
                                    <h3>HOW WE USE COLLECTED INFORMATION</h3>
                                </div>
                                <p>
                                    The following is a description of how we use the personal
                                    information we have collected about you. Automatically-collected
                                    information and other personal information may be combined with
                                    other information that we collect directly from you or that we
                                    receive from other sources and is used for the purposes
                                    described below.
                                </p>
                                <div className="ml-4">
                                    <h4>TO PROVIDE SERVICES TO YOU.</h4>
                                    <p />
                                    <h4>TO PROVIDE SERVICES REQUESTED BY YOU.</h4>
                                    <p>
                                        When purchasing LOUIS & ROCCO and MB Home Collection
                                        products, we may ask you to provide certain information
                                        about yourself, such as your name, shipping/billing address,
                                        telephone number, email address, credit card information,
                                        etc. We also maintain a record of your online product
                                        purchases.
                                    </p>
                                    <h4>TO PROVIDE OUR WEBSITE AND ITS FUNCTIONALITIES TO YOU.</h4>
                                    <p />
                                    <h4>TO RESPOND TO YOUR REQUESTS.</h4>
                                    <p>
                                        If you contact us with a question or a request, we will use
                                        your information to respond to your request. For example, if
                                        you contact us via email with a question, we will use the
                                        information in that email and the email address you used to
                                        contact us to respond to you.
                                    </p>
                                    <h4>TO PROVIDE YOU WITH LOCATION-SPECIFIC SERVICES.</h4>
                                    <p>
                                        If you elect to share your location information with us, we
                                        may use that information to tailor options, functionality or
                                        offers related to your location. We also may use your IP
                                        address to determine your general location, which also may
                                        be used to tailor offers to you.
                                    </p>
                                    <h4>TO OFFER AND CONDUCT PROMOTIONS AND CONTESTS.</h4>
                                    <p>
                                        Our email newsletter is used to communicate new product
                                        availability, store events, promotions and discounts. If a
                                        user requests to receive our email newsletters, they need to
                                        provide a valid email address. Real names need not be used
                                        to request newsletters.
                                    </p>
                                    <h4>
                                        TO CONDUCT DATA ANALYTICS TO SUPPORT OUR WEBSITE AND
                                        BUSINESS.
                                    </h4>
                                    <p>
                                        Typically, these analyses are done using aggregated or
                                        otherwise de-identified data. We use information, including
                                        automatically-collected information, to manage and improve
                                        our website and our business. For example, we use such
                                        information to:
                                    </p>
                                    <h4>
                                        MANAGE OUR BUSINESS NEEDS, SUCH AS MONITORING, ANALYZING,
                                        AND IMPROVING THE SERVICES AND THE WEBSITE’S PERFORMANCE AND
                                        FUNCTIONALITY.
                                    </h4>
                                    <p>
                                        For example, we analyze website user behavior and conduct
                                        research and analysis about the way you and other users use
                                        our website.
                                    </p>
                                    <h4>MANAGE RISK AND PROTECT THE WEBSITE.</h4>
                                    <p>
                                        We use data to better protect our services, our website, and
                                        you by helping to detect and prevent fraud and abuse of the
                                        website and our services.
                                    </p>
                                    <h4>TAILOR ADS TO YOUR APPARENT INTERESTS.</h4>
                                    <p />
                                    <h4>DEVELOP NEW PRODUCTS, SERVICES AND OFFERS.</h4>
                                    <p />
                                    <h4>TO MARKET TO YOU.</h4>
                                    <p>
                                        Information we collect about you and how you use and
                                        interact with the website and our services, ads, and other
                                        online content helps us to improve our website and offer
                                        additional products and services that we think might
                                        interest you. For example, we analyze information about user
                                        interactions with our marketing emails and online ads (such
                                        as whether emails have been opened or whether ads are
                                        clicked on) to determine whether those communications and
                                        ads are effective, and to understand what other website
                                        functionalities, products or services may interest you.
                                    </p>
                                    <h4>
                                        TO CONDUCT DUE DILIGENCE PRIOR TO ENGAGING IN A BUSINESS
                                        RELATIONSHIP.
                                    </h4>
                                    <p>
                                        Prior to engaging in a business relationship with you, we
                                        may conduct due diligence exercises, such as background
                                        checks and credit checks.
                                    </p>
                                    <h4>
                                        TO MEET OUR CONTRACTUAL OBLIGATIONS WITH YOU WHEN WE ENGAGE
                                        IN A BUSINESS RELATIONSHIP WITH YOU.
                                    </h4>
                                    <p />
                                    <h4>TO OPERATE AND IMPROVE OUR BUSINESS.</h4>
                                    <p />
                                    <h4>TO MAINTAIN OUR RECORDS.</h4>
                                    <p />
                                    <h4>FOR LEGAL, CONTRACTUAL AND SAFETY PURPOSES.</h4>
                                    <p>
                                        We will use your information as necessary to enforce the
                                        terms of our website; to comply with all applicable laws and
                                        regulations; to defend or protect us, our users, you, or
                                        third parties from harm or in legal proceedings; to protect
                                        our rights; to protect our security and the security of our
                                        users, employees, and property; to respond to court orders,
                                        lawsuits, subpoenas, and government requests; to address
                                        legal and regulatory compliance; to verify your identity or
                                        communications from you; to comply with contracts and other
                                        agreements to which we are a party; and to notify you of
                                        changes to our terms, policies, or practices.
                                    </p>
                                    <h4>TO CONDUCT INTERNAL MONITORING AND TRAINING.</h4>
                                    <p />
                                    <h4>FOR OTHER PURPOSES FOR WHICH YOU PROVIDE YOUR CONSENT.</h4>
                                    <p>
                                        We will use your information for other purposes which are
                                        disclosed to you at the time your information is collected,
                                        or for purposes which can be inferred from or are obvious
                                        given the circumstances of collection. For example, if you
                                        give us your personal information when entering a contest,
                                        we will use that information to facilitate your
                                        participation in that contest.
                                    </p>
                                </div>
                                <div className="mt-5 mb-3">
                                    <h3>TO WHOM DO WE DISCLOSE YOUR INFORMATION?</h3>
                                </div>
                                <p>
                                    We do not sell, trade, or otherwise transfer to third parties
                                    your personal information. This does not include trusted third
                                    parties who assist us in operating our websites, conducting our
                                    business, or providing services to you, so long as those parties
                                    agree to keep this information confidential. We do not share
                                    your personal information with third parties except in the
                                    circumstances discussed below.
                                </p>
                                <h4>WITHIN LOUIS & ROCCO</h4>
                                <p>
                                    We may share your personal information with other LOUIS & ROCCO
                                    entities, brands, divisions, and subsidiaries to serve you,
                                    including for the activities listed above.
                                </p>
                                <h4>OUR BUSINESS PARTNERS</h4>
                                <p>
                                    We disclose personal information to our clients, advertisers,
                                    merchants, consumer and market research companies, Service
                                    Providers who help us provide services, or to other third-party
                                    Service Providers who provide certain specialized services to
                                    us, to provide services on our behalf, or who co-operate with us
                                    on projects. These business partners are responsible for their
                                    own compliance with data protection laws.
                                </p>
                                <p>
                                    These third-parties are contractually restricted from using or
                                    disclosing the information except as necessary to perform
                                    services or to comply with legal requirements.
                                </p>
                                <p>
                                    We may disclose aggregated, de-identified information, and
                                    analyses and reports derived from such information to third
                                    parties including utilities, Service Providers, advertisers,
                                    merchants, consumer and market research companies and other
                                    organizations. If we have obtained your personal information
                                    from one of our clients, they may have the same information we
                                    do.
                                </p>
                                <h4>LEGAL REQUIREMENTS AND BUSINESS TRANSFERS</h4>
                                <p>
                                    We may disclose personal information (i) if we are required to
                                    do so by law, legal process, statute, rule, regulation, or
                                    professional standard, or to respond to a subpoena, search
                                    warrant, or other government official requests, (ii) when we
                                    believe disclosure is necessary or appropriate to prevent
                                    physical harm or financial loss, (iii) in connection with an
                                    investigation of a complaint, security threat, or suspected or
                                    actual illegal activity; (iv) in connection with an internal
                                    audit; or (v) in the event that LOUIS & ROCCO is subject to
                                    mergers, acquisitions, joint ventures, sales of assets,
                                    reorganizations, divestitures, dissolutions, bankruptcies,
                                    liquidations, or other types of business transactions. In these
                                    types of transactions, personal information may be shared, sold,
                                    or transferred, and it may be used subsequently by a third
                                    party.
                                </p>
                                <div className="mt-5 mb-3">
                                    <h3>
                                        WHAT CHOICES DO YOU HAVE ABOUT YOUR PERSONAL INFORMATION?
                                    </h3>
                                </div>
                                <p>
                                    We offer certain choices about how we communicate with you and
                                    what personal information we obtain about you and share with
                                    others, described below.
                                </p>
                                <h4>INFORMATION PROVIDED BY YOU</h4>
                                <p>
                                    Depending on the activity, some of the information we ask you to
                                    provide is mandatory and some is voluntary. Whether or not to
                                    provide such information is completely your own choice. But if
                                    you choose not to provide the information we request, you may be
                                    unable to purchase products, or access certain services, offers
                                    and content on our website as a guest.
                                </p>
                                <h4>CATALOGUES AND MAILINGS</h4>
                                <p>
                                    We want to communicate with you only if you want to hear from
                                    us. If you prefer not to receive information from us please let
                                    us know by sending your written request via email to
                                    info@louisandrocco.com, or via postal mail to:
                                </p>
                                <div className="mt-4 mb-4">
                                    <p>LOUIS & ROCCO</p>
                                    <p>2265 S Harrison St</p>
                                    <p>Denver, CO 80210</p>
                                </div>
                                <p>
                                    You may also contact us at 1-844-719-5696. Please provide your
                                    full name, mailing address, email address, and phone number when
                                    making this request. Include your email address, full name, and
                                    specifically what information you do not want to receive.
                                </p>
                                <p>
                                    You may also may also visit{' '}
                                    <a
                                        href="https://dmachoice.thedma.org/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        DMAChoice.org
                                    </a>
                                    , a tool offered by the Data & Marketing Association, which lets
                                    you remove your name and address from a number of marketing
                                    lists as well as unsubscribe from entire categories of mail,
                                    like catalogs and advertisements, or unsubscribe from specific
                                    catalogs.
                                    <a
                                        href="https://www.catalogchoice.org/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Catalogchoice.org
                                    </a>{' '}
                                    allows you to unsubscribe individual catalogues.
                                </p>
                                <h4>EMAIL</h4>
                                <p>
                                    You may unsubscribe from emails from us by clicking the
                                    Unsubscribe at the bottom of any promotional email from us. You
                                    may also contact us by email or postal address as noted below.
                                    Please include your current contact information and your
                                    requested changes.
                                </p>
                                <h4>COOKIES</h4>
                                <p>
                                    We provide a variety of services on our website using cookies.
                                    We place cookies on your device in order to provide you with
                                    personalized access to essential services, enhance website
                                    functionality, and to show you offers and services tailored to
                                    your interests.
                                </p>
                                <div className="mt-5 mb-3">
                                    <h3>WHAT IS A COOKIE?</h3>
                                </div>
                                <p>
                                    A cookie is a small piece of data that a website can store on
                                    your hard drive that identifies your computer. Cookies help to
                                    navigate from page to page on a website, provide secure
                                    connections, gather statistics about the usage and effectiveness
                                    of our site, and remember preferences from a previous visit.
                                </p>
                                <p>
                                    We use third-party services for analytics and interest-based
                                    advertising purposes. These service providers and ad partners
                                    may use cookies alone or in conjunction with unique identifiers,
                                    beacons, and other tracking technologies to collect information
                                    about you when you use our website, open our emails, or interact
                                    with our ads.
                                </p>
                                <p>
                                    For example, we use Google Analytics to help us analyze how
                                    visitors use our websites and mobile applications. The
                                    information generated by the cookie, including your IP address,
                                    is transmitted to Google. This information is then used to
                                    evaluate visitors’ use of the website and mobile applications,
                                    and to compile statistical reports on website activity for us.
                                    If you do not want any information to be collected and used by
                                    Google Analytics, you can install an opt-out in your web
                                    browser.
                                </p>
                                <div className="mt-5 mb-3">
                                    <h3>COOKIE CHOICES</h3>
                                </div>
                                <p>
                                    Most web browsers automatically accept cookies, but you can
                                    change your browser’s settings to disable all or certain cookies
                                    if you wish. For more information on how to manage browser
                                    cookies, see{' '}
                                    <a
                                        href="http://www.allaboutcookies.org"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        allaboutcookies.org
                                    </a>
                                    . Click on the links below to learn how to manage cookies on
                                    these browsers:
                                </p>
                                <ul className="list-privacy ml-4 mb-3">
                                    <li>
                                        <i className="fa fa-chevron-right" />
                                        <a
                                            href="https://support.google.com/chrome/answer/95647"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Google Chrome
                                        </a>
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />
                                        <a
                                            href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Microsoft Edge
                                        </a>
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />
                                        <a
                                            href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Mozilla Firefox
                                        </a>
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />
                                        <a
                                            href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Microsoft Internet Explorer
                                        </a>
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />
                                        <a
                                            href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Opera
                                        </a>
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />
                                        <a
                                            href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Apple Safari
                                        </a>
                                    </li>
                                </ul>
                                <p>
                                    Please note that by disabling or deleting cookies, you may not
                                    be able to access the full functionality of our website.
                                    Disabling or clearing cookies also may affect cookie-based
                                    opt-outs by either preventing you from using such cookie-based
                                    opt-outs or clearing opt-outs that you previously set.
                                </p>
                                <p>
                                    You may control your advertising preferences or opt-out of
                                    certain Google advertising products by visiting the Google Ads
                                    Preferences Manager, currently available at
                                    https://google.com/ads/preferences or by visiting NAI’s online
                                    resources at http://www.networkadvertising.org/choices.
                                </p>
                                <p>
                                    Some of the third parties that we may work with may participate
                                    in the Digital Advertising Alliance’s self-regulatory program,
                                    which provides consumers tools to opt-out of receiving
                                    interest-based advertising. Click{' '}
                                    <a
                                        href="http://optout.aboutads.info/?c=2&lang=EN"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>{' '}
                                    to learn more about the DAA’s WebChoices tool. To help control
                                    or block certain ads in mobile applications, you may choose to
                                    download and utilize the DAA mobile app by clicking{' '}
                                    <a
                                        href="https://youradchoices.com/appchoices"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    .
                                </p>
                                <p>
                                    You also can learn more about opting out of third parties’ use
                                    of cookies used to tailor ads and other content by visiting
                                    http://www.networkadvertising.org/managing/opt_out.asp and
                                    http://www.aboutads.info/choices.
                                </p>
                                <h4>DO NOT TRACK</h4>
                                <p>
                                    Because there currently is not an industry or legal standard for
                                    recognizing or honoring Do Not Track (“DNT”) signals, we do not
                                    respond to them at this time.
                                </p>
                                <h4>HOW DO WE PROTECT YOUR INFORMATION?</h4>
                                <p>
                                    We take security seriously and take numerous and reasonable
                                    precautions to protect the security of your information.
                                    Unfortunately, no data transmission over the internet or any
                                    wireless network can be guaranteed to be 100% secure. As a
                                    result, while we employ commercially reasonable security
                                    measures to protect data, and seek to partner with companies
                                    that do the same, we cannot guarantee the security of any
                                    information transmitted to or from our website, and are not
                                    responsible for the actions of any third parties that may
                                    receive any such information.
                                </p>
                                <p>
                                    Your LOUIS & ROCCO account information is password-protected for
                                    your privacy and security. LOUIS & ROCCO will never ask you for
                                    your password in an unsolicited phone call or email. You are
                                    responsible for maintaining the secrecy of your password and
                                    account information. Remember to sign out of your account and
                                    close your browser window when you have completed your shopping
                                    to help ensure that others cannot access your personal
                                    information.
                                </p>
                                <div className="mt-5 mb-3">
                                    <h3>LINKS TO THIRD PARTY SITES</h3>
                                </div>
                                <p>
                                    Please be aware that our site may contain links to other sites
                                    hosted by third parties. LOUIS & ROCCO does not control, and is
                                    thus not responsible for, the content or privacy practices and
                                    policies of such other sites. We encourage our users to be aware
                                    when they leave our site and to read the privacy statements of
                                    each and every website that collects personally identifiable
                                    information.
                                </p>
                                <div className="mt-5 mb-3">
                                    <h3>CHILDREN</h3>
                                </div>
                                <p>
                                    Minors under the age of 18 may not use the website. We do not
                                    knowingly collect personal information from anyone under the age
                                    of 18, and no part of the Website is designed to attract anyone
                                    under the age of 18. We do not sell products for purchase by
                                    children.
                                </p>
                                <div className="mt-5 mb-3">
                                    <h3>YOUR CALIFORNIA PRIVACY RIGHTS</h3>
                                </div>
                                <p>
                                    If you are a California resident, you have the right to request
                                    information regarding the disclosure of personal information to
                                    third parties for their direct marketing purposes during the
                                    immediately preceding calendar year. You may make one request
                                    each year by emailing us at info@louisandrocco.com or writing to
                                    us at:
                                </p>
                                <div className="mt-4 mb-4">
                                    <p>LOUIS & ROCCO</p>
                                    <p>2265 S Harrison St</p>
                                    <p>Denver, CO 80210</p>
                                </div>
                                <div className="mt-5 mb-3">
                                    <h3>INDIVIDUALS WITHIN THE EUROPEAN UNION</h3>
                                </div>
                                <h4>CONTROLLERS OF PERSONAL DATA</h4>
                                <div className="mt-4 mb-4">
                                    <p>LOUIS & ROCCO</p>
                                    <p>2265 S Harrison St</p>
                                    <p>Denver, CO 80210</p>
                                </div>
                                <p>
                                    is the data controller of the personal data processed through
                                    the website.
                                </p>
                                <h4>LEGAL BASES FOR PROCESSING PERSONAL DATA</h4>
                                <p>
                                    If you are an individual located in the European Union (EU), we
                                    collect and process personal data about you where we have a
                                    legal basis for doing so under applicable EU laws. This means we
                                    collect and process your data only when:
                                </p>
                                <ul className="list-privacy ml-4 mb-3">
                                    <li>
                                        <i className="fa fa-chevron-right" />It is necessary for a
                                        legitimate interest (which is not overridden by your
                                        individual privacy interests), such as preventing fraud,
                                        improving our website, and increasing the security of the
                                        website and network infrastructure;
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />You have consented to
                                        this collection and processing for a specific purpose;
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />It is necessary to
                                        fulfil our contractual obligations; or
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />It is necessary to
                                        comply with a legal obligation.
                                    </li>
                                </ul>
                                <p>
                                    Where we rely on your consent to process your personal data, you
                                    have the right to withdraw or decline consent at any time. An
                                    example of where we rely on your consent to process your
                                    personal data includes sending you our newsletter and marketing
                                    emails. If you wish to withdraw your consent, please contact us
                                    at info@louisandrocco.com.
                                </p>
                                <p>
                                    <strong>
                                        Some examples of our legitimate interests for processing
                                        personal data include: 
                                    </strong>
                                </p>
                                <ul className="list-privacy ml-4 mb-3">
                                    <li>
                                        <i className="fa fa-chevron-right" />Website and network
                                        security;
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Customer support;
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Marketing to current
                                        customers;
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Fraud prevention; or
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />Improving our website.
                                    </li>
                                </ul>
                                <p>
                                    Where we rely on our legitimate interests to process your
                                    personal data, you may have the right to object. More
                                    information on exercising this right can be found in the
                                    Individual Rights section below.
                                </p>
                                <p>
                                    If you have any questions about or need further information
                                    concerning the legal basis on which we collect and use your
                                    personal data, please contact us at info@louisandrocco.com.
                                </p>
                                <h4>INDIVIDUAL RIGHTS</h4>
                                <p>
                                    If you are located in the EU, you have certain rights under EU
                                    data protection law with respect to your personal data,
                                    including the right to request access to, correct, and delete
                                    your personal data. You may also have the right to receive a
                                    copy of your personal data in a commonly used and
                                    machine-readable format, and to transmit such data to another
                                    controller. You also may object to processing of your personal
                                    data, or ask that we restrict the processing of your personal
                                    data in certain instances.
                                </p>
                                <p>
                                    To request deletion of, access to, or to make changes to your
                                    personal data, or to any of the rights in this section, please
                                    email us at info@louisandrocco.com. Please note that not all
                                    requests can be honored.
                                </p>
                                <h4>TRANSFERS, STORAGE, AND PROCESSING</h4>
                                <p>
                                    Our website is operated from and hosted on servers located in
                                    the United States. If you access and use our website from a
                                    location outside of the United States, any personal data you
                                    provide to us or that is otherwise collected may be transferred
                                    to and processed in the United States or any other jurisdiction
                                    in our sole discretion. Users of our website should be aware
                                    that the laws that apply to the use and protection of personal
                                    data in the United States or other countries or jurisdictions to
                                    which we transfer, or in which we process, personal data may
                                    differ from those of your country of residence. Users who access
                                    or use our website from jurisdictions outside of the United
                                    States do so at their own choice and risk, and are solely
                                    responsible for compliance with local law. While we take steps
                                    to safeguard your personal data, the United States has NOT been
                                    deemed by the European Commission to ensure an adequate level of
                                    protection for personal data. Accordingly, the level of
                                    protection provided in the United States or other non-EU
                                    countries and jurisdictions from which you may access our
                                    website may not be as stringent as that under EU data protection
                                    standards or the data protection laws of some other countries,
                                    possibly including your home jurisdiction.
                                </p>
                                <p>
                                    By checking the box next to the statement,{' '}
                                    <strong>
                                        “By checking this box, I agree that any personal data that I
                                        provide to LOUIS & ROCCO or its affiliates, or that is
                                        otherwise collected, may be transferred to and processed in
                                        the United States in accordance with our Privacy Statement,”
                                    </strong>
                                    , you consent to allow your personal data to be transferred
                                    across borders to the United States or to other countries or
                                    jurisdictions in which we or our third-party associates may
                                    process personal data.
                                </p>
                                <div className="mt-5 mb-3">
                                    <h3>CHANGES TO PRIVACY POLICY</h3>
                                </div>
                                <p>
                                    If we decide to change our Privacy Policy, we will post those
                                    changes to our website so that you are aware of what information
                                    we collect, how we use it, and under what circumstances, if any,
                                    we disclose it.
                                </p>
                                <p>
                                    We reserve the right to modify this Privacy Policy at any time,
                                    so please review it frequently. If we make material changes to
                                    this policy, we will notify you here.
                                </p>
                                <div className="mt-5 mb-3">
                                    <h3>CONTACTING US</h3>
                                </div>
                                <p>
                                    If there are any questions regarding this privacy policy, you
                                    may contact us using the information below.
                                </p>
                                <div className="mt-4 mb-4">
                                    <p style={{ margin: '2px 0' }}>LOUIS & ROCCO</p>
                                    <p style={{ margin: '2px 0' }}>2265 S Harrison St</p>
                                    <p style={{ margin: '2px 0' }}>Denver, CO 80210</p>
                                    <p style={{ margin: '2px 0' }}>info@louisandrocco.com</p>
                                    <p style={{ margin: '2px 0' }}>1-844-719-5696</p>
                                </div>
                                <div className="mt-5 mb-3">
                                    <h3>COOKIES AND OTHER TRACKING TECHNOLOGIES</h3>
                                </div>
                                <h4>SOCIAL MEDIA PROVIDERS.</h4>
                                <p>
                                    We have presence on several social media platforms so that we
                                    can share information about our products with you and provide a
                                    forum for our community of LOUIS & ROCCO enthusiasts to share
                                    their love for LOUIS & ROCCO. Information collected by social
                                    media platforms when you interact with their services is
                                    governed by the social media platforms’ respective privacy
                                    policies. For more information about how you can customize your
                                    privacy settings and how third-party social media websites
                                    handle your personal information, please refer to their privacy
                                    policies. You can learn more about privacy practices of each
                                    platform by clicking on “Privacy Policy” next to the platform’s
                                    name below. You can also learn about their use of cookies by
                                    clicking on “Cookie Policy.”
                                </p>
                                <p>
                                    Facebook –{' '}
                                    <a
                                        href="https://www.facebook.com/about/privacy/update"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>{' '}
                                    and{' '}
                                    <a href="https://www.facebook.com/policies/cookies/">
                                        Cookies Policy
                                    </a>
                                </p>
                                <p>
                                    Instagram –{' '}
                                    <a
                                        href="https://help.instagram.com/196883487377501"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>{' '}
                                    and{' '}
                                    <a href="https://help.instagram.com/1896641480634370?ref=ig">
                                        Cookies Policy
                                    </a>
                                </p>
                                <p>
                                    Instagram –{' '}
                                    <a
                                        href="https://policy.pinterest.com/en/privacy-policy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>{' '}
                                    and{' '}
                                    <a href="https://policy.pinterest.com/en-gb/cookies">
                                        Cookies Policy
                                    </a>
                                </p>
                                <p>
                                    Instagram –{' '}
                                    <a
                                        href="https://twitter.com/en/privacy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>{' '}
                                    and{' '}
                                    <a href="https://help.twitter.com/en/rules-and-policies/twitter-cookies">
                                        Cookies Policy
                                    </a>
                                </p>
                                <h4>ANALYTICS PROVIDERS AND ADVERTISING NETWORKS.</h4>
                                <p>
                                    We allow certain analytics providers and online advertising
                                    networks and partners, such as those listed below, to set
                                    cookies during your visit to our website to collect information
                                    in order to provide tailored services and to personalize the ads
                                    shown to you when you visit other websites. They may also use
                                    cookies to promote our products and offers to you on other
                                    websites based on your activity on our website. For example, you
                                    may see products that you viewed at
                                    https://www.margaritabravo.com presented on other websites as
                                    you move around the internet. Some of our partners will use your
                                    location to provide more relevant messaging. You can learn more
                                    about privacy practices of each provider by clicking on “Privacy
                                    Policy” next to each provider’s name below. If there is an
                                    opt-out option offered, you may click on “Opt-Out” to be taken
                                    to the provider’s opt-out page. Some providers also may provide
                                    opt-out options within their Privacy Policies.
                                </p>
                                <div className="table-cookies">
                                    <table className="tg">
                                        <colgroup>
                                            <col style={{ width: '229px' }} />
                                            <col style={{ width: '161px' }} />
                                            <col style={{ width: '294px' }} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th className="tg-fhi3">Cookie Provider</th>
                                                <th className="tg-fhi3">Cookie Type</th>
                                                <th className="tg-fhi3">
                                                    Privacy Policy &amp; Opt Out Options
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        AddThis
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">Functionality</td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://www.addthis.com/privacy/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Privacy Policy{' '}
                                                    </a>
                                                    and{' '}
                                                    <a
                                                        href="https://www.addthis.com/privacy/opt-out/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Opt-Out
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Adroll
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">Targeting</td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://www.nextroll.com/privacy"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Privacy Policy
                                                    </a>
                                                    ,{' '}
                                                    <a
                                                        href="https://www.nextroll.com/trust-center"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Trust Center
                                                    </a>
                                                    , and{' '}
                                                    <a
                                                        href="https://app.adroll.com/optout/safari"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Opt-Out
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Advertising.com
                                                    </span>
                                                    <br />
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        aka Oath (AOL, Verizon)
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Targeting
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://policies.oath.com/xw/en/oath/privacy/intl/index.html"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Privacy Policy
                                                    </a>
                                                    ,{' '}
                                                    <a
                                                        href="https://policies.oath.com/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Opt-Out
                                                    </a>
                                                    , and{' '}
                                                    <a
                                                        href="https://policies.oath.com/us/en/oath/privacy/controls/index.html"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Other Privacy Controls
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">AppNexus, Inc.</td>
                                                <td className="tg-oe15">Targeting</td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://www.appnexus.com/platform-privacy-policy#choices"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Privacy Policy, Online Tracking Opt-Out
                                                    </a>
                                                    , and{' '}
                                                    <a
                                                        href="http://go.appnexus.com/UnsubscribePage.html"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Marketing Opt-Out
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">Blue Kai (Oracle)</td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Targeting
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://www.oracle.com/legal/privacy/marketing-cloud-data-cloud-privacy-policy.html"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Privacy Policy{' '}
                                                    </a>
                                                    and{' '}
                                                    <a
                                                        href="https://www.oracle.com/marketingcloud/opt.html"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Opt-Out
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">Casale Media Inc.</td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Targeting
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Privacy Policy
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">Criteo</td>
                                                <td className="tg-oe15">Targeting</td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://www.criteo.com/privacy/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <span>Privacy Policy</span>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">Dotomi (Conversant LLC)</td>
                                                <td className="tg-oe15">Targeting</td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://www.conversantmedia.com/legal/privacy"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Privacy Policy{' '}
                                                    </a>
                                                    and{' '}
                                                    <a
                                                        href="http://optout.conversantmedia.com/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Opt-Out
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">DoubleClick (Google)</td>
                                                <td className="tg-oe15">Targeting</td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://policies.google.com/privacy"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Privacy Policy{' '}
                                                    </a>
                                                    and{' '}
                                                    <a
                                                        href="https://support.google.com/ads/answer/2662922?hl=en"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Opt-Out
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">Facebook</td>
                                                <td className="tg-oe15">Targeting</td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://m.facebook.com/about/privacy/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <span>Privacy Policy</span>
                                                    </a>{' '}
                                                    and{' '}
                                                    <a
                                                        href="https://www.facebook.com/help/562973647153813/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Ad Preferences
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">
                                                    Google Universal Analytics
                                                </td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Performance
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        “
                                                    </span>
                                                    <a
                                                        href="https://policies.google.com/technologies/partner-sites"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        How Google uses data when you use our
                                                        partners’ websites or apps
                                                    </a>
                                                    ”,{' '}
                                                    <a
                                                        href="https://tools.google.com/dlpage/gaoptout/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Google Analytics Browser Ad-On
                                                    </a>
                                                    ,{' '}
                                                    <a
                                                        href="https://policies.google.com/privacy"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Privacy Policy
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        IPONWEB
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Targeting
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://www.iponweb.com/privacy-policy/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <span
                                                            style={{
                                                                fontWeight: 400,
                                                                fontStyle: 'normal',
                                                                textDecoration: 'none',
                                                            }}
                                                        >
                                                            Privacy Policy
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">Live Ramp (Acxiom)</td>
                                                <td className="tg-oe15">Targeting</td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://liveramp.com/privacy/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Privacy Policy{' '}
                                                    </a>
                                                    and{' '}
                                                    <a
                                                        href="https://liveramp.com/opt_out/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Opt-Out
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">LiveChat Inc.</td>
                                                <td className="tg-oe15">Functionality</td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Privacy Policy
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        MediaMath
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Targeting
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="http://www.mediamath.com/privacy-policy/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Privacy Policy{' '}
                                                    </a>
                                                    and{' '}
                                                    <a
                                                        href="http://www.mediamath.com/ad-choices-opt-out/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Opt-Out
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        OpenX
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">Targeting</td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://www.openx.com/legal/privacy-policy/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <span
                                                            style={{
                                                                fontWeight: 400,
                                                                fontStyle: 'normal',
                                                                textDecoration: 'none',
                                                            }}
                                                        >
                                                            Privacy Policy
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Outbrain
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Targeting
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://www.outbrain.com/legal/privacy#privacy-policy"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Privacy Policy{' '}
                                                    </a>
                                                    and{' '}
                                                    <a
                                                        href="https://my.outbrain.com/recommendations-settings/home"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Opt-Out
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">Pubmatic</td>
                                                <td className="tg-oe15">Targeting</td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://pubmatic.com/legal/privacy-policy/#adservices"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Privacy Policy{' '}
                                                    </a>
                                                    and{' '}
                                                    <a
                                                        href="https://pubmatic.com/legal/opt-out/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Opt-Out
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Pulsepoint
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">Targeting</td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://www.pulsepoint.com/privacy-policy-platform"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Privacy Policy{' '}
                                                    </a>
                                                    and{' '}
                                                    <a
                                                        href="http://optout.networkadvertising.org/?c=1#!/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Opt-Out
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Quantcast
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">Targeting</td>
                                                <td className="tg-oe15">
                                                    <a
                                                        href="https://www.quantcast.com/privacy/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Privacy Policy{' '}
                                                    </a>
                                                    and{' '}
                                                    <a
                                                        href="https://www.quantcast.com/opt-out/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Opt-Out
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">
                                                    Salesforce (formerly Demandware)
                                                </td>
                                                <td className="tg-oe15">Performance / Targeting</td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Privacy Policy
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Semasio
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Targeting
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Privacy Policy and{' '}
                                                    </span>
                                                    <a
                                                        href="http://www.semasio.com/de/nc/en/opt-out/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Opt-Out
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">Taboola Inc.</td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Targeting
                                                    </span>
                                                </td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Privacy Policy and{' '}
                                                    </span>
                                                    <a
                                                        href="https://www.taboola.com/privacy-policy#user-choices-and-optout"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Opt-Out
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">Tapad Inc.</td>
                                                <td className="tg-oe15">Targeting</td>
                                                <td className="tg-oe15">Privacy Policy</td>
                                            </tr>
                                            <tr>
                                                <td className="tg-oe15">TripleLift, Inc.</td>
                                                <td className="tg-oe15">Targeting</td>
                                                <td className="tg-oe15">
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontStyle: 'normal',
                                                        }}
                                                    >
                                                        Privacy Policy and{' '}
                                                    </span>
                                                    <a
                                                        href="https://triplelift.com/consumer-opt-out/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Opt-Out
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p>
                                    In addition to any management tools above, you can manage
                                    third-party advertising preferences for some of the third-party
                                    partners we work with to serve advertising across the Internet
                                    by utilizing the choices available here and here.
                                </p>
                                <p>
                                    For mobile users, you also have numerous controls in your
                                    Operating System that enable you to choose whether to allow
                                    cookies or to share your advertising ID with third parties. For
                                    some information on controlling your mobile choices, click here.
                                    To help control or block certain ads in mobile applications, you
                                    may choose to download and utilize the Digital Advertising
                                    Alliance’s mobile app by clicking here.
                                </p>
                                <p>
                                    We do not guarantee that all of the third parties we work with
                                    will honor the elections you make using any of these options.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default PrivacyPolicy;
