import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import '../common/index.scss';

import api from '../../config/axios';

function SearchResults() {
    const history = useHistory();
    const [products, setProducts] = useState({
        list: [],
        pagination: {
            page: 1,
        },
    });
    const [loaded, setLoaded] = useState(false);

    const query = history.location.search;

    useEffect(() => {
        getProducts();
    }, []);

    const fetchProducts = async () => {
        const { data } = await api.post(`/product/all`, null);
        return data;
    };

    const getProducts = async (page, query) => {
        const data = await fetchProducts(page, query);

        setProducts({ list: data.data.rows });
        setLoaded(true);
    };

    if (!loaded) return null;

    return (
        <div>
            <section className="section-b-space">
                <div className="collection-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="collection-content col">
                                <div className="page-main-content ">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="collection-product-wrapper">
                                                    {/*Products Listing Component*/}
                                                    <ul>
                                                        {products.list.map((product, index) => (
                                                            <li style={{ display: 'block' }}>
                                                                <Link
                                                                    to={`${
                                                                        process.env.PUBLIC_URL
                                                                    }/product/${product.id}`}
                                                                >
                                                                    {product.title}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SearchResults;
