import React from 'react';
import { Helmet } from 'react-helmet';

function Returns() {
    return (
        <div>
            <Helmet>
                <title>Returns Policy - Louis & Rocco</title>
                <meta property="og:title" content="Returns Policy - Louis & Rocco" />
                <meta
                    property="og:image"
                    content={'https://louisandrocco.com/assets/images/icon/logo/logo_short.png'}
                />
                <meta property="og:url" content={`${process.env.PUBLIC_URL}/returns`} />
            </Helmet>
            <section className="p-0">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-12 product-related">
                            <h2>returns & exchanges</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="mt-3 mb-5">
                                <p>
                                    Please inspect your purchase immediately upon delivery. If there
                                    is a problem with your order, contact us at 1-844-719-5696
                                    within three (3) days of receipt. You may also email us at
                                    info@louisandrocco.com. If you receive a damaged, defective or
                                    incorrect item, we will work with you to make things right.
                                </p>
                                <p>
                                    Once an item has shipped, cancellation is not possible.
                                    Furniture, Custom and Special Order items cannot be cancelled.{' '}
                                </p>
                                <p>
                                    All items are final sale, and not eligible for refunds or
                                    exchanges.
                                </p>
                                <p>
                                    Requests for price adjustments will be honored if the
                                    merchandise was purchased at full price and if it is marked down
                                    within 14 days of receipt of your package. In the event you have
                                    any problem or concern with an order we will connect you with
                                    our knowledgeable staff to ensure the issue is resolved to the
                                    best of our ability.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Contact louis + rocco</h3>
                            <div className="mt-3">
                                <p>
                                    <i className="fa fa-phone">&nbsp;&nbsp;</i>Phone: 1-844-719-5696
                                </p>
                                <p>
                                    <i className="fa fa-envelope-o">&nbsp;&nbsp;</i>Email:{' '}
                                    <a href="#">info@louisandrocco.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Returns;
