import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';
import Modal from 'react-responsive-modal';
import api from '../../config/axios';
import { renderIconCard } from '../../utils/miscUtils';

function PaymentMethods(props) {
    const Stripe = window.Stripe(process.env.REACT_APP_STRIPE_PK);
    const { user } = props;
    const { handleSubmit, register, errors } = useForm();
    const elements = Stripe.elements();

    const [card, setCard] = useState({});
    const [cards, setCards] = useState([]);
    const [visibleModal, setVisibleModal] = useState(false);
    const [errorStripe, setErrorStripe] = useState(false);
    const [loadedPut, setLoadedPut] = useState(false);
    const [loadedGet, setLoadedGet] = useState(false);

    useEffect(() => {
        fetchCards();
    }, []);

    useEffect(() => {
        if (visibleModal) {
            let cardInfo = {};
            cardInfo.cardNumber = elements.create('cardNumber', {
                style: elementStyles,
                classes: elementClasses,
            });
            cardInfo.cardNumber.mount('#cardNumber');
            cardInfo.cardNumber.on('change', function(event) {
                if (event.complete) {
                    setErrorStripe(prevState => ({
                        ...prevState,
                        cardNumber: {
                            error: false,
                        },
                    }));
                } else if (event.error) {
                    // show validation to customer
                    setErrorStripe(prevState => ({
                        ...prevState,
                        cardNumber: {
                            error: true,
                            message: 'Card number is invalid',
                        },
                    }));
                }
            });

            cardInfo.cardExpiry = elements.create('cardExpiry', {
                style: elementStyles,
                classes: elementClasses,
            });
            cardInfo.cardExpiry.mount('#cardExpiry');
            cardInfo.cardExpiry.on('change', function(event) {
                if (event.complete) {
                    setErrorStripe(prevState => ({
                        ...prevState,
                        cardExpiry: {
                            error: false,
                        },
                    }));
                } else if (event.error) {
                    // show validation to customer
                    setErrorStripe(prevState => ({
                        ...prevState,
                        cardExpiry: {
                            error: true,
                            message: 'Expiry date is invalid',
                        },
                    }));
                }
            });

            cardInfo.cardCvc = elements.create('cardCvc', {
                style: elementStyles,
                classes: elementClasses,
            });
            cardInfo.cardCvc.mount('#cardCvc');
            cardInfo.cardCvc.on('change', function(event) {
                if (event.complete) {
                    setErrorStripe(prevState => ({
                        ...prevState,
                        cardCvc: {
                            error: false,
                        },
                    }));
                } else if (event.error) {
                    // show validation to customer
                    setErrorStripe(prevState => ({
                        ...prevState,
                        cardCVC: {
                            error: true,
                            message: 'Secure code is invalid',
                        },
                    }));
                }
            });

            setCard(cardInfo);
        }
    }, [visibleModal]);

    const fetchCards = async () => {
        const { data } = await api.get(`/user/${user.id}/payment-method`);
        setCards(data.data);
        setLoadedGet(true);
    };

    const addCard = (data, e) => {
        e.preventDefault();
        if (validateStripeElement()) {
            setLoadedPut(true);
            Stripe.createToken(card.cardNumber, { name: data.firstName }).then(response => {
                setLoadedPut(false);
                setVisibleModal(false);
                api.post(`/user/payment-method`, { token: response.token.id })
                    .then(response => {
                        setLoadedPut(false);
                        setVisibleModal(false);
                        toast('Payment method added successfully.');
                        setLoadedGet(false);
                        fetchCards();
                    })
                    .catch(error => {
                        setLoadedPut(false);
                        const errors = error.response.data.errors;

                        errors.map(error => toast.error(error.message));
                    });
            });
        }
    };

    const deleteCard = id => {
        api.delete(`/user/${user.id}/payment-method/${id}`)
            .then(response => {
                toast('Card deleted successfully.');
                setLoadedGet(false);
                fetchCards();
            })
            .catch(error => {
                const errors = error.response.data.errors;

                errors.map(error => toast.error(error.message));
            });
    };

    /**
     * Custom validator to verify the right way to build the token request
     */
    const validateStripeElement = () => {
        let error = false;
        let objectErrors = {};
        if (card.cardNumber._empty) {
            objectErrors = {
                cardNumber: {
                    error: true,
                    message: 'Card number is required',
                },
            };
            error = true;
        }
        if (card.cardExpiry._empty) {
            objectErrors = {
                ...objectErrors,
                cardExpiry: {
                    error: true,
                    message: 'Expiry date is required',
                },
            };
            error = true;
        }
        if (card.cardCvc._empty) {
            objectErrors = {
                ...objectErrors,
                cardCvc: {
                    error: true,
                    message: 'Secure code is required',
                },
            };
            error = true;
        }
        if (error) {
            setErrorStripe(objectErrors);
        }
        return !error;
    };

    return (
        <div className="wrapper-addresses">
            <div
                className="page-title mb-4"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <h2>payment methods</h2>
                <button className="btn btn-theme" onClick={() => setVisibleModal(true)}>
                    add card
                </button>
            </div>
            <div className="box-account box-info">
                <div className="row">
                    {!loadedGet ? (
                        <div className="loading-cls"></div>
                    ) : cards.length > 0 ? (
                        cards.map((card, index) => (
                            <ItemCard
                                key={`card-item-${index}`}
                                card={card}
                                index={index}
                                deleteCard={deleteCard}
                            />
                        ))
                    ) : (
                        <div className="col-sm-6 mb-5">
                            <div className="box">There are no payment methods added yet.</div>
                        </div>
                    )}
                </div>
            </div>
            <Modal open={visibleModal} onClose={() => setVisibleModal(false)} center>
                <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                    <div className="modal-content quick-view-modal">
                        <div className="modal-header">
                            <h4>payment method</h4>
                        </div>
                        <div className="modal-body">
                            {loadedPut ? (
                                <div className="wrapper-modal-loader">
                                    <div className="loading-cls"></div>
                                </div>
                            ) : null}
                            <div className="row">
                                <div className="col-lg-12">
                                    <label htmlFor="firstName">Name on card</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="fname"
                                        name="firstName"
                                        ref={register({
                                            required: true,
                                        })}
                                    />
                                    <p className="message-error-input">
                                        {errors.firstName &&
                                            errors.firstName.type === 'required' &&
                                            'Name on card is required'}
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="cardNumber">Card number</label>
                                    <div
                                        id="cardNumber"
                                        className="empty field"
                                        style={stylesElements}
                                    ></div>
                                    <p className="message-error-input">
                                        {errorStripe.cardNumber &&
                                            errorStripe.cardNumber.error &&
                                            errorStripe.cardNumber.message}
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="expireDate">Expiry date</label>
                                    <div
                                        id="cardExpiry"
                                        className="empty field"
                                        style={stylesElements}
                                    ></div>
                                    <p className="message-error-input">
                                        {errorStripe.cardExpiry &&
                                            errorStripe.cardExpiry.error &&
                                            errorStripe.cardExpiry.message}
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="cvc">Secure code</label>
                                    <div
                                        id="cardCvc"
                                        className="empty field"
                                        style={stylesElements}
                                    ></div>
                                    <p className="message-error-input">
                                        {errorStripe.cardCvc &&
                                            errorStripe.cardCvc.error &&
                                            errorStripe.cardCvc.message}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="product-right">
                                <button className="btn btn-solid" onClick={handleSubmit(addCard)}>
                                    add card
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

const ItemCard = props => {
    const { card, index, deleteCard } = props;
    return (
        <div className="col-sm-6 mb-5">
            <div className="box">
                <div className="box-content">
                    <div className="box-title">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <img
                                alt={card.brand}
                                style={{ height: 20, marginRight: 10 }}
                                src={renderIconCard(card.brand)}
                            />
                            <h3>**** **** **** {card.last4}</h3>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <span onClick={() => deleteCard(card.id)}>
                                Delete
                            </span>
                        </div>
                    </div>
                    <p>{card.name}</p>
                    <p>
                        {card.exp_month}/{card.exp_year}
                    </p>
                </div>
            </div>
        </div>
    );
};

const elementClasses = {
    focus: 'focused',
    empty: 'empty',
    invalid: 'invalid',
};

const elementStyles = {
    base: {
        color: '#32325D',
        fontWeight: 500,
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        '::placeholder': {
            color: '#CFD7DF',
        },
        ':-webkit-autofill': {
            color: '#e39f48',
        },
    },
    invalid: {
        color: '#E25950',

        '::placeholder': {
            color: '#FFCCA5',
        },
    },
};

const stylesElements = {
    width: '100%',
    height: 38,
    padding: '0.5rem 0.75rem',
    fontSize: '1rem',
    lineHeight: 1.5,
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    WebkitTransition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
};

export default PaymentMethods;
