import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../../common/index.scss';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

// Import custom components
import api from '../../../config/axios';
import SpecialProducts from './special-products';

import HeaderOne from '../../common/headers/header-one';
import FooterOne from '../../common/footers/footer-one';

function Furniture() {
    const [misc, setMisc] = useState({
        items_banner: [],
        special_products: [],
        items_categories: [],
    });
    useEffect(() => {
        Promise.all([
            api.get('/collection/list'),
            api.get('/category/list'),
            api.post('/product/search?page=1'),
        ]).then(values => {
            const data = {
                items_banner: values[0].data.data.splice(0, 2),
                items_categories: values[1].data.data.splice(0, 2),
                special_products: values[2].data.data.rows.splice(0, 8),
            };

            setMisc(data);
        });
    }, []);

    const { items_banner, special_products, items_categories } = misc;
    return <div>
            <HeaderOne logoName={'logo/logo.png'} />
            <Helmet>
                <title>Louis & Rocco</title>
                <meta name="description" content="Welcome to louis + rocco – We bring you a unique and stylish rental solution to decorate your house or interior design project." />
                <meta property="og:title" content="Let the fun begin - Louis & Rocco" />
                <meta property="og:image" content={'https://louisandrocco.com/assets/images/icon/logo/logo_short.png'} />
                <meta property="og:url" content={`${process.env.PUBLIC_URL}`} />
            </Helmet>
            <section className="p-0 small-slider">
                <Slider className="slide-1 home-slider">
                    <div>
                        <div className="home text-left">
                            <img alt="louis + rocco" src="/assets/images/home/21.jpeg" />
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="slider-contain">
                                            <div className="onboarding-banner-container">
                                                <h1>style away</h1>
                                                <h3>
                                                    Welcome to louis + rocco. We bring you a
                                                    unique and stylish rental solution to
                                                    decorate your house or interior design
                                                    project.
                                                </h3>
                                                <Link to={`${process.env.PUBLIC_URL}/search?q=`} className="btn btn-solid">
                                                    {''}let the fun begin
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </section>

            <section className="banner-furniture ratio_45">
                <div className="container-fluid">
                    <div className="row partition3">
                        <div className="col-md-12 d-flex justify-content-center align-items-center" style={{ flexDirection: 'column' }}>
                            <div className="mb-5">
                                <h2 className="title-inner1">Introducing louis + rocco</h2>
                            </div>
                            <div className="wrapper-video-landing">
                                <iframe style={{ height: '100%', width: '100%' }} src="https://www.youtube.com/embed/THtz7kgW6D8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Collection Banner section*/}
            <section className="banner-furniture ratio_45">
                <div className="container-fluid">
                    <div className="row partition3">
                        <div className="col-md-6" key={`collection-0`}>
                            <Link to={`${process.env.PUBLIC_URL}/search?q=&cat=f2e0764b-03a2-4ed8-823a-7a317d62b97c`}>
                                <div className="collection-banner p-right text-right">
                                    <div className="img-part" style={{ height: '100%' }}>
                                        <img src="/assets/images/home/vases.jpg" alt="" className="img-fluid blur-up lazyload bg-img" />
                                    </div>
                                    <div className="contain-banner banner-3">
                                        <div>
                                            <h2>Bowls + Vases</h2>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6" key="collection-1">
                            <Link to={`${process.env.PUBLIC_URL}/search?q=&cat=f1885df4-05f8-4b1f-a918-32eacb2e7148`}>
                                <div className="collection-banner p-right text-right">
                                    <div className="img-part" style={{ height: '100%' }}>
                                        <img src="/assets/images/home/books.jpeg" alt="" className="img-fluid blur-up lazyload bg-img" />
                                    </div>
                                    <div className="contain-banner banner-3">
                                        <div>
                                            <h2>Decorative Items</h2>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div style={{ visibility: 'hidden' }}>
                            <Link to={`${process.env.PUBLIC_URL}/main`} />
                        </div>
                    </div>
                </div>
            </section>
            {/*Collection Banner section end*/}

            {/*Special Products Start*/}
            <SpecialProducts type={'furniture'} products={special_products} />
            {/*Special Products End*/}
            <FooterOne logoName={'logo/logo-light.png'} />
        </div>;
}

export default Furniture;
