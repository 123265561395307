import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual';
import store from '../../../../store';
import { deleteUser } from '../../../../actions/auth';

const TopBar = withRouter(({ history, ...props }) => {
    const { translate, session } = props;
    let username = null;

    if (session) {
        username = `${session.user.firstName.split(' ')[0]} ${session.user.lastName.slice(0, 1)}`;
    }

    const logout = async () => {
        store.dispatch(deleteUser());
        history.push('/');
    };

    return (
        <div className="top-header">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        
                    </div>
                    <div className="col-lg-6 text-right">
                        {!session ? (
                            <ul className="header-dropdown">
                                <li className="mobile-wishlist">
                                    {session ? (
                                        <Link to={`${process.env.PUBLIC_URL}/wishlist`}>
                                            <i className="fa fa-heart" aria-hidden="true"></i>
                                            projects
                                        </Link>
                                    ) : null}
                                </li>
                                <li className="onhover-dropdown mobile-account">
                                    <i className="fa fa-user" aria-hidden="true"></i>{' '}
                                    {translate('my_account')}
                                    <ul className="onhover-show-div">
                                        <li>
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/pages/login`}
                                                data-lng="en"
                                            >
                                                Login
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/pages/register`}
                                                data-lng="en"
                                            >
                                                Register
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        ) : (
                            <ul className="header-dropdown">
                                <li className="mobile-wishlist">
                                    {session ? (
                                        <Link to={`${process.env.PUBLIC_URL}/projects`}>
                                            <i className="fa fa-heart" aria-hidden="true"></i>
                                            projects
                                        </Link>
                                    ) : null}
                                </li>
                                <li className="onhover-dropdown mobile-account">
                                    <i className="fa fa-user" aria-hidden="true"></i> {username}
                                    <ul className="onhover-show-div">
                                        <li>
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/user/profile`}
                                                data-lng="en"
                                            >
                                                Profile
                                            </Link>
                                        </li>
                                        <li>
                                            <a to="#" onClick={logout} data-lng="en">
                                                Logout
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default withTranslate(TopBar);
