import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import useForm from 'react-hook-form';
import api from '../../config/axios';

function ShippingCheckout(props) {
    const {
        user,
        variants,
        shipmentAddress,
        setShipmentAddress,
        setDeliveryOption,
        setStep,
        settingsBusiness,
        children,
    } = props;
    const { register, errors, setValue, handleSubmit, getValues } = useForm();
    const addressWarehouse = {
        street1: settingsBusiness.street1,
        street2: settingsBusiness.street2,
        city: settingsBusiness.city,
        state: settingsBusiness.state,
        zip: settingsBusiness.zip,
        phone: settingsBusiness.phone_number,
        name: settingsBusiness.company_name,
        country: 'US',
    };

    useEffect(() => {
        fetchAddresses();
    }, []);

    const [loadedGet, setLoadedGet] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [errorsForm, setErrorsForm] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [errorAddress, setErrorAddress] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saveAddress, setSaveAddress] = useState(false);

    const fetchAddresses = async () => {
        const { data } = await api.get(`/user/${user.id}/address`);
        setAddresses(data.data);
        setLoadedGet(true);
    };

    const handleMyAddress = value => {
        setValue('fullName', value.fullName);
        setValue('street1', value.street1);
        setValue('street2', value.street2);
        setValue('city', value.city);
        setValue('state', value.state);
        setValue('zip', value.zip);
        setValue('phone', value.phone);
    };

    const submitShippingAddress = async data => {
        const dataAddress = {
            fromStreet1: data.street1,
            fromStreet2: data.street2,
            fromCity: data.city,
            fromState: data.state,
            fromCountry: 'US',
            fromZip: data.zip,
            fromPhone: data.phone,
            fromName: data.fullName,
        };
        setLoading(true);

        if (saveAddress) {
            try {
                await verifyAddress(data);
            } catch (e) {
                setErrorAddress(e.response.data.errors);
                setLoading(false);
                return setIsValid(false);
            }
        }
        api.post('/shipping/verificationAddress', dataAddress)
            .then(async ({ data }) => {
                const itemsShipment = getShipmentDimensions(variants);

                // Envio de ida hacia el cliente
                const outboundShipment = await fetchRates(
                    addressWarehouse,
                    data.data,
                    itemsShipment,
                );
                // Envio de regreso hacia el warehouse
                const returnShipment = await fetchRates(data.data, addressWarehouse, itemsShipment);

                // Rates para el envío de ida
                // Se obtiene el rate más barato, el array retornado es de una sola posición con el array más barato
                const outboundCheapestRate = sortRates(outboundShipment.data.shipping);

                // Rates para el envío de regreso
                // Se obtiene el rate más barato, el array retornado es de una sola posición con el array más barato
                const returnCheapestRate = sortRates(returnShipment.data.shipping);

                setDeliveryOption({
                    outbound: outboundCheapestRate,
                    return: returnCheapestRate,
                    binPacking: outboundShipment.data.binPacking,
                });
                setShipmentAddress({
                    fullName: getValues().fullName,
                    ...data.data,
                });
                setLoading(false);
                setStep(2);
            })
            .catch(error => {
                setErrorAddress(error.response.data.errors);
                setLoading(false);
                setIsValid(false);
            });
    };

    /**
     * Llamado al API para ordenar obtener los rates asociados al trayecto y tamaño de la caja(s) de envío
     * @param {String} addressFrom Dirección origen
     * @param {String} addressTo Dirección destino
     * @param {Array} items Listao de variantes (productos)
     */
    const fetchRates = async (addressFrom, addressTo, items) => {
        const dataShipment = {
            fromStreet1: addressFrom.street1,
            fromStreet2: addressFrom.street2,
            fromCity: addressFrom.city,
            fromState: addressFrom.state,
            fromZip: addressFrom.zip,
            fromCountry: addressFrom.country,
            fromPhone: addressFrom.phone,
            fromName: addressFrom.name,
            toStreet1: addressTo.street1,
            toStreet2: addressTo.street2,
            toCity: addressTo.city,
            toState: addressTo.state,
            toZip: addressTo.zip,
            toCountry: addressTo.country,
            toPhone: addressTo.phone,
            toName: addressTo.name,
            items,
        };
        const {
            data: { data },
        } = await api.post(`/shipping/generate-rates`, dataShipment);

        return data;
    };

    const addAddress = address => {
        return api.post(`/user/address`, address);
    };

    /**
     * Address verification with easypost api (backend)
     * @param {Object} data Address information
     * @param {Object} e event
     */
    const verifyAddress = async data => {
        const dataRequest = { ...data, country: 'US' };
        return api.post(`/user/verificationAddress`, dataRequest).then(async response => {
            const addressInfo = {
                ...response.data.data,
                fullName: data.fullName,
            };

            return await addAddress(addressInfo);
        });
    };

    /**
     * Ordenar los rates del shipment para obtener el más barato
     * @param {Array} shipment Shipment (easypost)
     * @return {void}
     */
    const sortRates = shipment => {
        return {
            ...shipment,
            rates: shipment.rates
                .sort((a, b) => {
                    if (Number(a.rate) > Number(b.rate)) {
                        return 1;
                    }
                    if (Number(a.rate) < Number(b.rate)) {
                        return -1;
                    }
                    return 0;
                })
                .splice(0, 1),
        };
    };

    /**
     * Mapea las variantes para obtener las dimensiones de la caja de empaque (packing box)
     * En caso que on tenga caja de empaque, se toman las dimensiones propias del producto
     * @param {Array} variants Listado de variantes (productos)
     * @return {Array}
     */
    const getShipmentDimensions = variants => {
        const items = variants.map(variant => {
            if (variant.product && variant.product.box) {
                return {
                    width: variant.product.box.width,
                    length: variant.product.box.length,
                    height: variant.product.box.height,
                    weight: variant.product.weight,
                    quantity: variant.projectVariant.quantity,
                    name: variant.product.title,
                };
            } else {
                return {
                    width: variant.product.width,
                    length: variant.product.length,
                    height: variant.product.height,
                    weight: variant.product.weight,
                    quantity: variant.projectVariant.quantity,
                    name: variant.product.title,
                };
            }
        });
        return items;
    };

    return (
        <div>
            {loading ? <PlacingOrder /> : null}
            <div className="checkout row">
                <div className="col-lg-6 col-sm-12 col-xs-12">
                    <div className="checkout-box-section">
                        <div className="checkout-title">
                            <h3>Delivery address</h3>
                        </div>
                        <div>
                            {!loadedGet ? (
                                <div className="loading-cls"></div>
                            ) : addresses.length > 0 ? (
                                <div>
                                    <div className="mb-3">
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            name="categoryId"
                                            placeholder="My addresses"
                                            onChange={value => handleMyAddress(value)}
                                            defaultValue={
                                                shipmentAddress
                                                    ? {
                                                          value: shipmentAddress.id,
                                                          label: `${shipmentAddress.fullName} - ${shipmentAddress.street1}, ${shipmentAddress.city}, ${shipmentAddress.state}`,
                                                      }
                                                    : null
                                            }
                                            options={addresses.map(address => {
                                                return {
                                                    ...address,
                                                    value: address.id,
                                                    label: `${address.fullName} - ${address.street1}, ${address.city}, ${address.state}`,
                                                };
                                            })}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="row check-out">
                            <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                <div className="field-label">Full name</div>
                                <input
                                    type="text"
                                    name="fullName"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={shipmentAddress && shipmentAddress.fullName}
                                />
                                <p className="message-error-input">
                                    {errors.fullName &&
                                        errors.fullName.type === 'required' &&
                                        'Full Name is required'}
                                </p>
                            </div>
                            <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                <div className="field-label">Street 1</div>
                                <input
                                    type="text"
                                    name="street1"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={shipmentAddress && shipmentAddress.street1}
                                />
                                <p className="message-error-input">
                                    {errors.street1 &&
                                        errors.street1.type === 'required' &&
                                        'Street is required'}
                                </p>
                            </div>
                            <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                <div className="field-label">Street 2</div>
                                <input
                                    type="text"
                                    name="street2"
                                    ref={register({
                                        required: false,
                                    })}
                                    defaultValue={shipmentAddress && shipmentAddress.street2}
                                />
                            </div>
                            <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                <div className="field-label">Town/City</div>
                                <input
                                    type="text"
                                    name="city"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={shipmentAddress && shipmentAddress.city}
                                />
                                <p className="message-error-input">
                                    {errors.city &&
                                        errors.city.type === 'required' &&
                                        'City is required'}
                                </p>
                            </div>
                            <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                <div className="field-label">State / County</div>
                                <input
                                    type="text"
                                    name="state"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={shipmentAddress && shipmentAddress.state}
                                />
                                <p className="message-error-input">
                                    {errors.state &&
                                        errors.state.type === 'required' &&
                                        'State is required'}
                                </p>
                            </div>
                            <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                <div className="field-label">Postal code</div>
                                <input
                                    type="text"
                                    name="zip"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={shipmentAddress && shipmentAddress.zip}
                                />
                                <p className="message-error-input">
                                    {errors.zip &&
                                        errors.zip.type === 'required' &&
                                        'Postal code is required'}
                                </p>
                            </div>
                            <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                <div className="field-label">Phone</div>
                                <input
                                    type="text"
                                    name="phone"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={shipmentAddress && shipmentAddress.phone}
                                />
                                <p className="message-error-input">
                                    {errors.phone &&
                                        errors.phone.type === 'required' &&
                                        'Phone number is required'}
                                </p>
                            </div>
                            <div className="form-group col-md-12 col-sm-6 col-xs-12 custom-control custom-checkbox collection-filter-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    value="1"
                                    onChange={() => setSaveAddress(!saveAddress)}
                                    checked={saveAddress}
                                    id="save-address"
                                    style={{ position: 'relative' }}
                                />
                                <label className="custom-control-label" htmlFor="save-address">
                                    Save this address
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                {children}
            </div>
            <div className="payment-box">
                <div className="text-right">
                    <div>
                        <button
                            type="button"
                            className="btn-solid btn"
                            onClick={handleSubmit(submitShippingAddress)}
                        >
                            Continue
                        </button>
                    </div>
                    <div></div>
                    <p className="message-error-input">{errorsForm}</p>
                    <div>
                        {errorAddress.length > 0 &&
                            errorAddress.map((error, index) => (
                                <p key={index} className="message-error-input">
                                    {error.message}
                                </p>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

function PlacingOrder() {
    return (
        <div
            style={{
                height: '100%',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: 1000,
                backgroundColor: 'rgba(255, 255,255, 0.8)',
            }}
        >
            <div className="loading-cls"></div>
            <h2 style={{ marginTop: 30 }}>please wait...</h2>
        </div>
    );
}

export default ShippingCheckout;
