import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import useForm from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';

// Import custom components
import api from '../../config/axios';

function Contact() {
    const { handleSubmit, register, errors, reset } = useForm();
    const [sent, setSent] = useState(false);

    const handleContact = (data, e) => {
        api.post('/misc/contact-mail', data)
            .then(response => {
                setSent(true);
                reset();
            })
            .catch(error => {
                const errors = error.response.data.errors;
                if (errors) {
                    errors.map(error => toast.error(error.message));
                } else {
                    toast.error('Something was wrong. Please try again');
                }
            });
    };

    return (
        <div>
            <Helmet>
                <title>Contact Us - Louis & Rocco</title>
                <meta property="og:title" content="Contact Us - Louis & Rocco" />
                <meta
                    property="og:image"
                    content={'https://louisandrocco.com/assets/images/icon/logo/logo_short.png'}
                />
                <meta property="og:url" content={`${process.env.PUBLIC_URL}/contact`} />
            </Helmet>
            {/*Parallax banner*/}
            <section className="p-0">
                <div className="full-banner parallax parallax-banner3  text-center p-center">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="banner-contain" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mt-5 contact-page section-b-space">
                <div className="container">
                    {!sent ? (
                        <>
                            <div className="title1 title5">
                                <h2 className="title-inner1">Contact louis + rocco</h2>
                            </div>
                            <div className="row mt-5 mt-5">
                                <div className="col-sm-12">
                                    <form className="theme-form">
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstName">First Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="firstName"
                                                    placeholder="Enter your first name"
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                />
                                                <p className="message-error-input">
                                                    {errors.firstName &&
                                                        errors.firstName.type === 'required' &&
                                                        'First Name is required'}
                                                </p>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="lastName">Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="lastName"
                                                    placeholder="Enter your last name"
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                />
                                                <p className="message-error-input">
                                                    {errors.lastName &&
                                                        errors.lastName.type === 'required' &&
                                                        'Last Name is required'}
                                                </p>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="phoneNumber">Phone number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="phoneNumber"
                                                    placeholder="Enter your number"
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                />
                                                <p className="message-error-input">
                                                    {errors.phoneNumber &&
                                                        errors.phoneNumber.type === 'required' &&
                                                        'Phone Number is required'}
                                                </p>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="email">Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="email"
                                                    placeholder="contact@example.com"
                                                    ref={register({
                                                        required: true,
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                            message: 'Invalid email address',
                                                        },
                                                    })}
                                                />
                                                <p className="message-error-input">
                                                    {errors.email &&
                                                        errors.email.type === 'required' &&
                                                        'Email is required'}
                                                </p>
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="message">Write Your Message</label>
                                                <textarea
                                                    className="form-control"
                                                    placeholder="Write Your Message"
                                                    name="message"
                                                    rows="6"
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                />
                                                <p className="message-error-input">
                                                    {errors.message &&
                                                        errors.message.type === 'required' &&
                                                        'Message is required'}
                                                </p>
                                            </div>
                                            <div className="col-md-12 d-flex justify-content-end mt-3">
                                                <button
                                                    className="btn btn-solid"
                                                    type="submit"
                                                    onClick={handleSubmit(handleContact)}
                                                >
                                                    Send Your Message
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="title1">
                                    <h1 className="pt-2 pb-3" style={{ fontSize: 40 }}>
                                        Message sent
                                    </h1>
                                    <h3 className="pb-3">
                                        Thank you for contacting us. We'll get back to you as soon
                                        as possible.
                                    </h3>
                                    <Link
                                        className="btn btn-solid"
                                        to={`${process.env.PUBLIC_URL}`}
                                    >
                                        go home
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <ToastContainer />
        </div>
    );
}

export default Contact;
