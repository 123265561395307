import React from 'react';

function SubscriptionsProfile(props) {
    return (
        <div className="wrapper-subscriptions">
            <div className="page-title mb-4">
                <h2>Subscriptions</h2>
            </div>
            <div className="box-account box-info">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="box">Under construction</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionsProfile;
