import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import '../common/index.scss';
import { connect } from 'react-redux';
import api from '../../config/axios';

// import custom Components
import RelatedProduct from '../common/related-product';
import DetailsWithPrice from './common/product/details-price';
import DetailsTopTabs from './common/details-top-tabs';
import { addToCart, addToCartUnsafe, addToWishlist } from '../../actions';
import ImageZoom from './common/product/image-zoom';

class LeftImage extends Component {
    constructor() {
        super();
        this.state = {
            nav1: null,
            nav2: null,
            vertical: true,
            product: null,
            loaded: false,
        };
        this.changeVariant = this.changeVariant.bind(this);
        this.fetchProduct = this.fetchProduct.bind(this);
    }

    componentWillMount() {
        if (window.innerWidth > 576) {
            this.setState({ vertical: true });
        } else {
            this.setState({ vertical: false });
        }
    }

    componentDidMount() {
        const productId = this.props.match.params.id;
        this.fetchProduct(productId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.fetchProduct(this.props.match.params.id);
        }
    }

    fetchProduct(productId) {
        api.get(`/product/${productId}`).then(({ data }) => {
            this.setState(
                {
                    loaded: true,
                    product: data.data,
                    variantSelected: data.data.variants[0],
                },
                () => {
                    this.setState({
                        nav1: this.slider1,
                        nav2: this.slider2,
                    });
                },
            );
        });
    }

    changeVariant(variant) {
        this.setState({
            ...this.state,
            variantSelected: variant,
        });
    }

    render() {
        const { symbol, addToCart, addToCartUnsafe, addToWishlist } = this.props;
        const { product, loaded } = this.state;

        if (!loaded) return <div style={{ height: '40vh' }} className="loading-cls mt-5" />;

        var products = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
        };
        var productsnav = {
            vertical: this.state.vertical,
            verticalSwiping: this.state.vertical,
            slidesToShow: this.state.variantSelected.images.length,
            slidesToScroll: 1,
            asNavFor: '.product-right-slick',
            arrows: false,
            infinite: true,
            centerMode: false,
            dots: false,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: this.state.variantSelected.images.length,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        return (
            <div>
                {/*Section Start*/}
                {product ? (
                    <section>
                        <Helmet>
                            <title>{product.title}</title>
                            <meta property="og:title" content={product.title} />
                            <meta property="og:description" content="louis + rocco" />
                            <meta
                                property="og:image"
                                content={this.state.variantSelected.images[0].url}
                            />
                            <meta
                                property="og:url"
                                content={`${process.env.PUBLIC_URL}/product/${product.id}`}
                            />
                        </Helmet>
                        <div className="collection-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-1 col-sm-2 col-xs-12 p-0">
                                        <div className="row">
                                            <div className="col-12 p-0">
                                                <Slider
                                                    {...productsnav}
                                                    asNavFor={this.state.nav1}
                                                    ref={slider => (this.slider2 = slider)}
                                                    className="slider-nav"
                                                >
                                                    {this.state.variantSelected.images.map(
                                                        (image, index) => (
                                                            <div key={`slider-nav1-${index}`}>
                                                                <img
                                                                    src={`${image.url}`}
                                                                    key={index}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                        ),
                                                    )}
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-sm-10 col-xs-12 order-up">
                                        <Slider
                                            {...products}
                                            asNavFor={this.state.nav2}
                                            ref={slider => (this.slider1 = slider)}
                                            className="product-right-slick"
                                        >
                                            {this.state.variantSelected.images.map(
                                                (image, index) => (
                                                    <div key={`product-right-slick${index}`}>
                                                        <ImageZoom
                                                            image={image.url}
                                                            className="img-fluid image_zoom_cls-0"
                                                        />
                                                    </div>
                                                ),
                                            )}
                                        </Slider>
                                    </div>
                                    <DetailsWithPrice
                                        symbol={symbol}
                                        item={product}
                                        variantSelected={this.state.variantSelected}
                                        navOne={this.state.nav1}
                                        addToCartClicked={addToCart}
                                        BuynowClicked={addToCartUnsafe}
                                        addToWishlistClicked={addToWishlist}
                                        changeVariant={this.changeVariant}
                                        user={
                                            this.props.auth.user ? this.props.auth.user.user : null
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                ) : (
                    ''
                )}
                {/*Section End*/}

                <section className="tab-product m-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <DetailsTopTabs item={product} />
                            </div>
                        </div>
                    </div>
                </section>

                <RelatedProduct productId={product.id} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let productId = ownProps.match.params.id;
    return {
        symbol: state.data.symbol,
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
    { addToCart, addToCartUnsafe, addToWishlist },
)(LeftImage);
