import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { SlideUpDown } from '../../../services/script';
import LogoImage from '../headers/common/logo';

class FooterOne extends Component {
    componentDidMount() {
        var contentwidth = window.innerWidth;
        if (contentwidth < 750) {
            SlideUpDown('footer-title');
        } else {
            var elems = document.querySelectorAll('.footer-title');
            [].forEach.call(elems, function(elemt) {
                let el = elemt.nextElementSibling;
                el.style = 'display: block';
            });
        }
    }

    render() {
        return (
            <footer className="footer-light">
                <div className="section-footer dark-layout">
                    <div className="container">
                        <div className="row footer-theme partition-f">
                            <div className="col-sm-6 col-md-7">
                                <div className="footer-contant">
                                    <div className="footer-nav">
                                        <ul>
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL}/howitworks`}>
                                                    How it works
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL}/contact`}>
                                                    Contact Us
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="footer-social">
                                        <ul>
                                            <li>
                                                <a
                                                    href="https://www.facebook.com/MARGARITA.BRAVO.DESIGNS/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i
                                                        className="fa fa-facebook"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.instagram.com/louisandrocco/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i
                                                        className="fa fa-instagram"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.pinterest.com/margaritabravo_/_created/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i
                                                        className="fa fa-pinterest"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://twitter.com/margaritabravo_"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i
                                                        className="fa fa-twitter"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </li>

                                            <li>
                                                <a
                                                    href="https://www.youtube.com/channel/UCx3kPrr7iL9dU4pRaeuJQNw"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i
                                                        className="fa fa-youtube"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="mailto:info@louisandrocco.com"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i
                                                        className="fa fa-envelope"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <p>Interested in Interior Design?</p>
                                    </div>
                                    <div className="footer-nav mb-4 mt-2">
                                        <ul>
                                            <li>
                                                <a
                                                    href="https://www.margaritabravo.com/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Margarita Bravo
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="http://www.sorellafurniture.com/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Sorella Furniture
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-5 text-right">
                                <div className="sub-title">
                                    <div className="footer-contant">
                                        <ul>
                                            <li>
                                                <p>louis & rocco, Denver, CO 80210</p>
                                            </li>
                                            <li>
                                                <p>
                                                    <Link to={`${process.env.PUBLIC_URL}/privacy`}>
                                                        Privacy Policy
                                                    </Link>{' '}
                                                    /{' '}
                                                    <Link to={`${process.env.PUBLIC_URL}/returns`}>
                                                        Return Policy
                                                    </Link>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    © Copyright 2015-20 Margarita Bravo ® All rights
                                                    Reserved
                                                </p>
                                            </li>
                                            <li>
                                                <p>Website by PCG</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default FooterOne;
