import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { getValueFromQuery } from '../../utils/miscUtils';

function OrderError(props) {
    const history = useHistory();
    const projectId = getValueFromQuery(props.location.search, 'p');

    return (
        <div className="pb-5">
            <section className="pt-5 light-layout pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="error-text">
                                <i className="fa fa-times-circle" aria-hidden="true"></i>
                                <h2>sorry</h2>
                                <p>Payment has been declined. Try again</p>
                            </div>

                            <div className="text-center mt-5">
                                <Link
                                    to={`/checkout?p=${projectId}`}
                                    className="btn btn-lg btn-outline"
                                >
                                    <i className="fa fa-credit-card" aria-hidden="true"></i>
                                    &nbsp;Try again
                                </Link>
                                <Link to="/" className="btn btn-lg btn-solid ml-5">
                                    <i className="fa fa-home" aria-hidden="true"></i>&nbsp;Go home
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default OrderError;
