import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { currency } from '../../utils/miscUtils';
import { toast } from 'react-toastify';
import api from '../../config/axios';

const SimpleProduct = props => {
    const { variant, deleteVariantOfProject, orderPlaced, user, project, fetchProject } = props;
    const [visibleModal, setVisibleModal] = useState(false);
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        setQuantity(variant.projectVariant.quantity);
    }, []);

    const minusQty = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const plusQty = () => {
        if (quantity < variant.quantity) {
            setQuantity(quantity + 1);
        }
    };

    const changeQty = e => {
        const qty = e.target.value;
        if (qty <= variant.quantity && qty > 0) {
            setQuantity(parseInt(e.target.value));
        }
    };

    const updateQuantity = () => {
        api.put(`/user/${user.id}/projects/${project.project.id}/update/${variant.id}`, {
            quantity,
        })
            .then(() => {
                toast('Quantity updated successfully');
                setVisibleModal(false);
                fetchProject();
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <div className="product-box product-projects">
            <div className="img-wrapper">
                <div className="front">
                    <Link to={`${process.env.PUBLIC_URL}/product/${variant.product.id}`}>
                        <img src={variant.images[0].url} className="img-fluid" alt="" />
                    </Link>
                </div>
                {!orderPlaced ? (
                    <div className="cart-box" style={{ cursor: 'pointer' }}>
                        <i
                            className="fa fa-pencil"
                            aria-hidden="true"
                            onClick={() => setVisibleModal(true)}
                        ></i>
                        <i
                            className="fa fa-trash"
                            aria-hidden="true"
                            onClick={() => deleteVariantOfProject(variant.id)}
                        ></i>
                    </div>
                ) : null}
            </div>
            <div className="product-detail">
                <div>
                    <Link to={`${process.env.PUBLIC_URL}/product/${variant.product.id}`}>
                        <h6>{variant.product.title}</h6>
                    </Link>
                    <h4>
                        {currency(variant.price_rent)}{' '}
                        <span style={{ fontSize: 15, color: '#555', textTransform: 'none' }}>
                            /day rental
                        </span>
                    </h4>
                    <span className="mt-1 mb-2 product-detail-retail-price">
                        {' '}
                        {currency(variant.price_retail)} retail
                    </span>
                    {props.children}
                </div>
            </div>
            <Modal open={visibleModal} onClose={() => setVisibleModal(false)} center>
                <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                    <div className="modal-content quick-view-modal">
                        <div className="modal-header">
                            <h4>{variant.product.title}</h4>
                        </div>
                        <div className="qty-box mt-3 pl-5 pr-5">
                            <p className="text-center">Update quantity</p>
                            <div className="input-group">
                                <span className="input-group-prepend">
                                    <button
                                        type="button"
                                        className="btn quantity-left-minus"
                                        onClick={minusQty}
                                        data-type="minus"
                                        data-field=""
                                    >
                                        <i className="fa fa-angle-left"></i>
                                    </button>
                                </span>
                                <input
                                    type="text"
                                    name="quantity"
                                    value={quantity}
                                    max={variant.quantity}
                                    onChange={changeQty}
                                    className="form-control input-number"
                                />
                                <span className="input-group-prepend">
                                    <button
                                        type="button"
                                        className="btn quantity-right-plus"
                                        onClick={plusQty}
                                        data-type="plus"
                                        data-field=""
                                    >
                                        <i className="fa fa-angle-right"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div className="mt-5 text-center">
                            <button className="btn btn-solid" onClick={updateQuantity}>
                                update
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default SimpleProduct;
