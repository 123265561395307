import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import useForm from 'react-hook-form';
import Modal from 'react-responsive-modal';
import moment from 'moment';
import api from '../../config/axios';
import { toast } from 'react-toastify';

function ModalProject(props) {
    const { visibleModal, setVisibleModal, project, submitAction, user } = props;
    const { handleSubmit, register, errors, setValue } = useForm();

    useEffect(() => {
        Promise.all([fetchAddresses(), fetchSettings()]);
        register({ name: 'startDate' }, { required: false });
        register({ name: 'endDate' }, { required: false });
    }, []);

    useEffect(() => {
        if (project) {
            if (!visibleModal) {
                setDate({
                    startDate: null,
                    endDate: null,
                });
            } else {
                if (project.dateFrom && project.dateTo) {
                    setDate({
                        startDate: moment(project.dateFrom),
                        endDate: moment(project.dateTo),
                    });
                    setValue('startDate', moment(project.dateFrom));
                    setValue('endDate', moment(project.dateTo));
                }
            }
        }
    }, [visibleModal]);

    const [addresses, setAddresses] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [validate, setValidate] = useState(true);
    const [loadedPut, setLoadedPut] = useState(false);
    const [settingsBusiness, setSettingsBusiness] = useState(null);
    const [dates, setDate] = useState({
        startDate: null,
        endDate: null,
    });
    const [visibleModalAddress, setVisibleModalAddress] = useState(false);

    const fetchAddresses = async () => {
        const { data } = await api.get(`/user/${user.id}/address`);
        setAddresses(data.data);
    };

    const fetchSettings = async () => {
        const { data } = await api.get('/settings/general-info');
        setSettingsBusiness(data.data[0]);
    };

    const validateRange = (startDate, endDate) => {
        const rentDays = endDate.diff(startDate, 'days');
        if (
            rentDays >= settingsBusiness.rents_min_days &&
            rentDays <= settingsBusiness.rents_max_days
        ) {
            setValidate(true);
            return true;
        }
        setValidate(false);
        return false;
    };

    const handleCustomSubmit = async data => {
        // if the project does not have both startDate and endDate, don't make validation and continue
        // if the project does startDate and endDate, do validation with validateRange()
        if (
            (!data.startDate && !data.endDate) ||
            (data.startDate && data.endDate && validateRange(data.startDate, data.endDate))
        ) {
            const dataSubmit = {
                ...data,
                dateFrom: data.startDate ? moment(data.startDate).format('YYYY-MM-DD') : undefined,
                dateTo: data.endDate ? moment(data.endDate).format('YYYY-MM-DD') : undefined,
            };
            setLoadedPut(true);
            await submitAction(dataSubmit);
            setLoadedPut(false);
        }
    };

    const isOutsideRange = day => {
        let dayIsBlocked = false;
        //Block future dates
        const formattedDay = day.format('MM/DD/YYYY');
        const formattedToday = moment().format('MM/DD/YYYY');
        const periodOfBuffer = moment(formattedDay).diff(formattedToday, 'days');

        if (periodOfBuffer < settingsBusiness.range_dates_rent) {
            dayIsBlocked = true;
        }

        return dayIsBlocked;
    };

    return (
        <div>
            <Modal open={visibleModal} onClose={() => setVisibleModal(false)} center>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content quick-view-modal">
                        <div className="modal-header">
                            <h6>project information</h6>
                        </div>
                        <div className="modal-body">
                            {loadedPut ? (
                                <div className="wrapper-modal-loader">
                                    <div className="loading-cls"></div>
                                </div>
                            ) : null}
                            <div className="row">
                                <div className="col-lg-12">
                                    <label htmlFor="dateFrom">Rental dates</label>
                                    <div>
                                        <DateRangePicker
                                            //minDate={minDate}
                                            startDate={dates.startDate}
                                            startDateId="your_unique_start_date_id"
                                            endDate={dates.endDate}
                                            endDateId="your_unique_end_date_id"
                                            onDatesChange={dates => {
                                                setDate(dates);
                                                setValue('startDate', dates.startDate);
                                                setValue('endDate', dates.endDate);
                                                if (dates.startDate && dates.endDate) {
                                                    validateRange(dates.startDate, dates.endDate);
                                                }
                                            }}
                                            focusedInput={focusedInput}
                                            onFocusChange={focusedInput =>
                                                setFocusedInput(focusedInput)
                                            }
                                            isOutsideRange={isOutsideRange}
                                        />
                                    </div>
                                    <p className="message-error-input">
                                        {!validate &&
                                            `Period rent must be between ${settingsBusiness.rents_min_days} and ${settingsBusiness.rents_max_days} days.`}
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="name">Project name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        ref={register({
                                            required: true,
                                        })}
                                        defaultValue={project && project.name}
                                    />
                                    <p className="message-error-input">
                                        {errors.name &&
                                            errors.name.type === 'required' &&
                                            'Project name is required'}
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="description">Description</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="description"
                                        ref={register({
                                            required: true,
                                        })}
                                        defaultValue={project && project.description}
                                    />
                                    <p className="message-error-input">
                                        {errors.description &&
                                            errors.description.type === 'required' &&
                                            'Description is required'}
                                    </p>
                                </div>

                                <div className="col-lg-12">
                                    <label htmlFor="addressId">Address</label>
                                    <select
                                        className="form-control"
                                        name="addressId"
                                        ref={register({
                                            required: false,
                                        })}
                                        defaultValue={
                                            project && project.address ? project.address.id : ''
                                        }
                                    >
                                        <option value="" disabled>
                                            Select one (optional)
                                        </option>
                                        {addresses &&
                                            addresses.map((address, item) => {
                                                return (
                                                    <option
                                                        key={`address-modal-${item}`}
                                                        value={address.id}
                                                    >
                                                        {address.fullName} - {address.street1}{' '}
                                                        {address.city}, {address.state}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                    <div className="mt-2">
                                        <button
                                            onClick={() => setVisibleModalAddress(true)}
                                            className="btn btn-xs"
                                        >
                                            Add new address
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="product-right">
                                <button
                                    className="btn btn-solid"
                                    onClick={handleSubmit(handleCustomSubmit)}
                                >
                                    {project ? 'update' : 'add'} project
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <ModalAddress
                visibleModalAddress={visibleModalAddress}
                setVisibleModalAddress={setVisibleModalAddress}
                fetchAddresses={fetchAddresses}
            />
        </div>
    );
}

function ModalAddress(props) {
    const { visibleModalAddress, setVisibleModalAddress, fetchAddresses } = props;
    const { handleSubmit, register, errors } = useForm();
    const [loadedPut, setLoadedPut] = useState(false);

    const addAddress = address => {
        api.post(`/user/address`, address)
            .then(response => {
                setLoadedPut(false);
                setVisibleModalAddress(false);
                toast('Address added successfully.');
                fetchAddresses();
            })
            .catch(error => {
                setLoadedPut(false);
                const errors = error.response.data.errors;

                errors.map(error => toast.error(error.message));
            });
    };

    /**
     * Address verification with easypost api (backend)
     * @param {Object} data Address information
     * @param {Object} e event
     */
    const verifyAddress = async (data, e) => {
        e.preventDefault();
        setLoadedPut(true);

        const dataRequest = { ...data, country: 'US' };
        api.post(`/user/verificationAddress`, dataRequest)
            .then(response => {
                const addressInfo = {
                    ...response.data.data,
                    fullName: data.fullName,
                };

                addAddress(addressInfo);
            })
            .catch(error => {
                setLoadedPut(false);
                const errors = error.response.data.errors;

                errors.map(error => toast.error(error.message));
            });
    };

    return (
        <Modal open={visibleModalAddress} onClose={() => setVisibleModalAddress(false)} center>
            <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                <div className="modal-content quick-view-modal">
                    <div className="modal-header">
                        <h4>address information</h4>
                    </div>
                    <div className="modal-body">
                        {loadedPut ? (
                            <div className="wrapper-modal-loader">
                                <div className="loading-cls"></div>
                            </div>
                        ) : null}
                        <div className="row">
                            <div className="col-lg-12">
                                <label htmlFor="fullName">Full name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="fullName"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                                <p className="message-error-input">
                                    {errors.fullName &&
                                        errors.fullName.type === 'required' &&
                                        'Full Name is required'}
                                </p>
                            </div>
                            <div className="col-lg-12">
                                <label htmlFor="street1">Street 1</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="street1"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                                <p className="message-error-input">
                                    {errors.street1 &&
                                        errors.street1.type === 'required' &&
                                        'Street 1 is required'}
                                </p>
                            </div>
                            <div className="col-lg-12">
                                <label htmlFor="street2">Street 2</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="street2"
                                    ref={register({
                                        required: false,
                                    })}
                                />
                                <p className="message-error-input"></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <label htmlFor="city">City</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="city"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                                <p className="message-error-input">
                                    {errors.city &&
                                        errors.city.type === 'required' &&
                                        'City is required'}
                                </p>
                            </div>
                            <div className="col-lg-12">
                                <label htmlFor="state">State</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="state"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                                <p className="message-error-input">
                                    {errors.state &&
                                        errors.state.type === 'required' &&
                                        'State is required'}
                                </p>
                            </div>
                            <div className="col-lg-12">
                                <label htmlFor="zip">ZIP</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="zip"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                                <p className="message-error-input">
                                    {errors.zip &&
                                        errors.zip.type === 'required' &&
                                        'ZIP Code is required'}
                                </p>
                            </div>
                            <div className="col-lg-12">
                                <label htmlFor="phone">Phone number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="phone"
                                    name="phone"
                                    ref={register({
                                        required: true,
                                        pattern: /[0-9]{10}/,
                                    })}
                                />
                                <p className="message-error-input">
                                    {errors.phone &&
                                        errors.phone.type === 'required' &&
                                        'Phone number is required'}
                                </p>
                                <p className="message-error-input">
                                    {errors.phone &&
                                        errors.phone.type === 'pattern' &&
                                        'Phone number must have 10 characters'}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="product-right">
                            <button className="btn btn-solid" onClick={handleSubmit(verifyAddress)}>
                                Add address
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalProject;
