import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../config/axios';
import { currency, formatDate } from '../../utils/miscUtils';
import moment from 'moment';

function OrderHistory(props) {
    const { user } = props;
    useEffect(() => {
        fetchRents();
    }, []);

    const [loadedGet, setLoadedGet] = useState(false);
    const [rents, setRents] = useState(null);

    const fetchRents = async () => {
        const { data } = await api.get(`/user/${user.id}/rents`);

        data.data.rows.sort(function(a, b) {
            if (a.status_rent === 'pre_send' || a.status_rent === 'active') {
                return -1;
            }
            if (a.status_rent === 'in_warehouse') {
                return 1;
            }
            // a must be equal to b
            return 0;
        });

        setRents(data);
        setLoadedGet(true);
    };

    return (
        <div className="wrapper-subscriptions">
            <div className="page-title mb-4">
                <h2>my orders</h2>
            </div>
            <div className="box-account box-info">
                <div className="row">
                    {!loadedGet ? (
                        <div className="loading-cls"></div>
                    ) : rents && rents.data && rents.data.rows.length > 0 ? (
                        rents.data.rows.map((rent, index) => {
                            return <OrderHistoryItem rent={rent} key={`stock-item-${index}`} />;
                        })
                    ) : (
                        <div className="col-sm-6 mb-5">
                            <div className="box">Order history is empty.</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function OrderHistoryItem(props) {
    const { rent } = props;
    const getCurrentStep = status => {
        switch (status) {
            case 'pre_send':
                return 'Order placed';
            case 'sent_courier':
                return 'Sent';
            case 'transit_client':
                return 'In transit (client)';
            case 'delivered':
                return 'Delivered';
            case 'transit_warehouse':
                return 'In transit (warehouse)';
            case 'in_warehouse':
                return 'Returned';
            default:
                return '';
        }
    };

    const groupStock = stock => {
        const arrayGrouped = stock.reduce((total, value) => {
            total[value.variant.id] = total[value.variant.images];
            return total;
        }, {});
        const stockGrouped = Object.keys(arrayGrouped).map(key => {
            const variant = stock.find(itemStock => itemStock.variant.id === key);
            return { variant: variant.variant };
        });
        return stockGrouped;
    };

    const stockGrouped = groupStock(rent.stocks);
    return (
        <div className="wrapper-order-history-item">
            <div className="row">
                <div className="col-lg-8 col-sm-12 col-xs-12">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="container-thumbnail-project">
                                {stockGrouped.slice(0, 4).map((stock, index) => {
                                    return (
                                        <div
                                            className={`div${index + 1}`}
                                            key={`thumb-stock-${index}`}
                                        >
                                            <img
                                                src={stock.variant.images[0].url}
                                                alt={stock.variant.sku}
                                            />
                                        </div>
                                    );
                                })}
                                {stockGrouped.slice(4, rent.stocks.length).length > 0 ? (
                                    <div className="div5">
                                        +{rent.stocks.slice(4, rent.stocks.length).length}
                                        <p>items</p>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="wrapper-info-order">
                                <div className="info-order">
                                    <p>
                                        <strong>Order status:</strong>{' '}
                                        {getCurrentStep(rent.status_rent)}
                                    </p>
                                    <p>
                                        <strong>Order #:</strong> {rent.id}
                                    </p>
                                    <p>
                                        <strong>Order placed:</strong>{' '}
                                        {moment(rent.rentalDate).format(formatDate)}
                                    </p>
                                    <p>
                                        <strong>Start rent:</strong>{' '}
                                        {moment(rent.dateFrom).format(formatDate)}
                                    </p>
                                    <p>
                                        <strong>End rent:</strong>{' '}
                                        {moment(rent.dateTo).format(formatDate)}
                                    </p>
                                </div>
                                <h4>Total: {currency(rent.priceTotal)}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12 col-xs-12">
                    <div className="wrapper-btn-orders">
                        {/* <button className="btn btn-full btn-solid">track package</button> */}
                        <Link to={`/rents/${rent.id}`} className="btn btn-full btn-outline">
                            order details
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderHistory;
