import { roundTwoDecimals } from '../../utils/miscUtils';
/**
 * Obtener la suma total del envío en base al array de rates de la order de shipments aún no comprados
 * de los envios de ida y regreso
 * @param {Array} outboundShipment     Outbound shipment
 * @param {Array} returnShipment       Return shipment
 * @param {Number} shippingCoverage     Percent coverage of shipment
 * @return {void}
 */
const totalShipping = (outboundShipment, returnShipment, shippingCoverage = 0) => {
    if (outboundShipment && returnShipment) {
        // Se toma la primera posición del arreglo shipment.rates
        // porque ya el arreglo viene filtrado con el rate más barato
        const outboundPrice = Number(outboundShipment.rates[0].rate);
        const returnPrice = Number(returnShipment.rates[0].rate);

        const amountRates = outboundPrice + returnPrice;
        const amountShippingCoverage = amountRates * (shippingCoverage / 100);
        const totalShipping = amountRates - amountShippingCoverage;
        return Number(roundTwoDecimals(totalShipping));
    }
    return 0;
};

/**
 * Obtener la suma total del envío en base al array de rates de la order de shipments ya comprados
 * de los envios de ida y regreso
 * @param {Array} outboundShipment     Outbound shipment
 * @param {Array} returnShipment       Return shipment
 * @param {Number} shippingCoverage     Percent coverage of shipment
 * @return {void}
 */
const totalSelectedRates = (outboundShipment, returnShipment, shippingCoverage = 0) => {
    if (outboundShipment && returnShipment) {
        // Se toma la primera posición del arreglo shipment.rates
        // porque ya el arreglo viene filtrado con el rate más barato
        const outboundPrice = outboundShipment.ratesº
            .map(shipment => Number(shipment.selected_rate.rate))
            .reduce((a, b) => a + b);
        const returnPrice = returnShipment
            .map(shipment => Number(shipment.selected_rate.rate))
            .reduce((a, b) => a + b);

        const amountRates = outboundPrice + returnPrice;
        const amountShippingCoverage = amountRates * (shippingCoverage / 100);
        const totalShipping = amountRates - amountShippingCoverage;
        return Number(roundTwoDecimals(totalShipping));
    }
    return 0;
};

export { totalShipping, totalSelectedRates };
