import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import api from '../../config/axios';
import { getValueFromQuery } from '../../utils/miscUtils';

import ShippingCheckout from './shipping-select';
import PaymentCheckout from './payment';
import OrderSummary from './order-summary';
import ReviewOrder from './review-order';
import { totalShipping } from './utils';

function Checkout(props) {
    const { user } = props;
    const history = useHistory();
    const projectId = getValueFromQuery(props.location.search, 'p');

    useEffect(() => {
        Promise.all([fetchProject(), fetchSettings()]).then(response => setLoadedGet(true));
    }, []);

    const [step, setStep] = useState(1);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    const [loadedGet, setLoadedGet] = useState(false);
    const [loadingRent, setLoadingRent] = useState(false);
    const [project, setProject] = useState(null);
    const [coupon, setCoupon] = useState(null);
    const [shipmentAddress, setShipmentAddress] = useState(null);
    const [billingAddress, setBillingAddress] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [settingsBusiness, setSettingsBusiness] = useState(null);
    const [modalDeclined, setModalDeclined] = useState(false);
    const [deliveryOption, setDeliveryOption] = useState({
        outbound: null,
        return: null,
        binPacking: null,
    });

    const fetchProject = async () => {
        const { data } = await api.get(`/user/${user.id}/projects/${projectId}`);
        setProject(data.data.project);
    };

    const fetchSettings = async () => {
        const { data } = await api.get('/settings/general-info');
        setSettingsBusiness(data.data[0]);
    };

    const placeOrder = () => {
        const dataRent = {
            projectId,
            rentalDate: moment().format('YYYY-MM-DD'),
            dateFrom: project.dateFrom,
            dateTo: project.dateTo,
            fee: getAmountFee(subtotal),
            subtotal: subtotal,
            tokenIdStripe: paymentMethod.id,
            variants: project.variants.map(variant => {
                return {
                    variantId: variant.id,
                    quantity: variant.projectVariant.quantity,
                };
            }),
            outboundShippingInfo: deliveryOption.outbound,
            returnShippingInfo: deliveryOption.return,
            binPacking: deliveryOption.binPacking,
            coupon: coupon && coupon.status === 'active' ? coupon.id : null,
        };
        setLoadingRent(true);
        api.post('/rent', dataRent)
            .then(({ data }) => {
                setLoadingRent(false);
                setModalDeclined(false);
                history.push(`/order-success?r=${data.data.data.id}`);
            })
            .catch(error => {
                setLoadingRent(false);
                setModalDeclined(true);
                //history.push(`/order-error?p=${projectId}`);
            });
    };

    if (!loadedGet) return null;
    const rentDays = moment(project.dateTo).diff(moment(project.dateFrom), 'days');
    const totalItems = project.variants
        .map(item => item.price_rent * item.projectVariant.quantity)
        .reduce((a, b) => a + b);
    const subtotal = project.variants
        .map(item => item.price_rent * item.projectVariant.quantity * rentDays)
        .reduce((a, b) => a + b);
    const getAmountFee = amount => amount * (Number(settingsBusiness.fee_service) / 100);

    const couponDiscount = (amount, coupon) =>
        coupon.type === 'percentage' ? amount * (Number(coupon.value) / 100) : coupon.value;

    return (
        <div>
            {loadingRent ? <PlacingOrder /> : null}
            <section className="section-b-space">
                <div className="container padding-cls">
                    <div className="checkout-page">
                        {!loadedGet ? (
                            <div className="wrapper-modal-loader">
                                <div className="loading-cls"></div>
                            </div>
                        ) : (
                            <div className="checkout-form">
                                {step === 1 ? (
                                    <div>
                                        <ShippingCheckout
                                            user={user}
                                            variants={project.variants}
                                            shipmentAddress={shipmentAddress}
                                            setShipmentAddress={setShipmentAddress}
                                            deliveryOption={deliveryOption}
                                            setDeliveryOption={setDeliveryOption}
                                            setStep={setStep}
                                            settingsBusiness={settingsBusiness}
                                        >
                                            <OrderSummary
                                                rentDays={rentDays}
                                                project={project}
                                                totalItems={totalItems}
                                                subtotal={subtotal}
                                                getAmountFee={getAmountFee}
                                                deliveryOption={deliveryOption}
                                                settingsBusiness={settingsBusiness}
                                                coupon={coupon}
                                                couponDiscount={couponDiscount}
                                            />
                                        </ShippingCheckout>
                                    </div>
                                ) : step === 2 ? (
                                    <div>
                                        <PaymentCheckout
                                            user={user}
                                            billingAddress={billingAddress}
                                            setBillingAddress={setBillingAddress}
                                            paymentMethod={paymentMethod}
                                            setPaymentMethod={setPaymentMethod}
                                            setStep={setStep}
                                            settingsBusiness={settingsBusiness}
                                            coupon={coupon}
                                            setCoupon={setCoupon}
                                        >
                                            <OrderSummary
                                                rentDays={rentDays}
                                                project={project}
                                                totalItems={totalItems}
                                                subtotal={subtotal}
                                                getAmountFee={getAmountFee}
                                                deliveryOption={deliveryOption}
                                                settingsBusiness={settingsBusiness}
                                                coupon={coupon}
                                                couponDiscount={couponDiscount}
                                            />
                                        </PaymentCheckout>
                                    </div>
                                ) : (
                                    <div>
                                        <ReviewOrder
                                            shipmentAddress={shipmentAddress}
                                            deliveryOption={deliveryOption}
                                            billingAddress={billingAddress}
                                            totalShipping={totalShipping}
                                            paymentMethod={paymentMethod}
                                            setStep={setStep}
                                            settingsBusiness={settingsBusiness}
                                            placeOrder={placeOrder}
                                        >
                                            <OrderSummary
                                                rentDays={rentDays}
                                                project={project}
                                                totalItems={totalItems}
                                                subtotal={subtotal}
                                                getAmountFee={getAmountFee}
                                                deliveryOption={deliveryOption}
                                                settingsBusiness={settingsBusiness}
                                                coupon={coupon}
                                                couponDiscount={couponDiscount}
                                            />
                                        </ReviewOrder>
                                    </div>
                                )}
                            </div>
                        )}
                        <Modal open={modalDeclined} onClose={() => setModalDeclined(false)} center>
                            <div
                                className="modal-dialog modal-lg modal-dialog-centered"
                                role="document"
                            >
                                <div className="modal-content quick-view-modal">
                                    <div className="modal-header">
                                        <h6>Payment declined</h6>
                                    </div>
                                    <div className="modal-body">
                                        <p>Please check your information and try again</p>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="product-right">
                                            <button
                                                className="btn btn-solid"
                                                onClick={() => setModalDeclined(false)}
                                            >
                                                try again
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </section>
        </div>
    );
}

function PlacingOrder() {
    return (
        <div
            style={{
                height: '100%',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: 200,
                backgroundColor: 'rgba(255, 255,255, 0.8)',
            }}
        >
            <div className="loading-cls"></div>
            <h2 style={{ marginTop: 30 }}>please wait...</h2>
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.auth.user.user,
});

export default connect(mapStateToProps, {})(Checkout);
