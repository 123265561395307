import React, { Component } from 'react';
import { connect } from 'react-redux';
import { filterSort } from '../../../actions';
import { getVisibleproducts } from '../../../services';

class FilterBar extends Component {
    //List Layout View
    listLayout() {
        document.querySelector('.collection-grid-view').style = 'opacity:0';
        document.querySelector('.product-wrapper-grid').style = 'opacity:0.2';
        document.querySelector('.product-wrapper-grid').classList.add('list-view');
        var elems = document.querySelector('.infinite-scroll-component .row').childNodes;
        [].forEach.call(elems, function(el) {
            el.className = '';
            el.classList.add('col-lg-12');
        });
        setTimeout(function() {
            document.querySelector('.product-wrapper-grid').style = 'opacity: 1';
        }, 500);
    }

    //Grid Layout View
    gridLayout() {
        document.querySelector('.collection-grid-view').style = 'opacity:1';
        document.querySelector('.product-wrapper-grid').classList.remove('list-view');
        var elems = document.querySelector('.infinite-scroll-component .row').childNodes;
        [].forEach.call(elems, function(el) {
            el.className = '';
            el.classList.add('col-lg-3');
        });
    }

    // Layout Column View
    LayoutView = colSize => {
        if (!document.querySelector('.product-wrapper-grid').classList.contains('list-view')) {
            var elems = document.querySelector('.infinite-scroll-component .row').childNodes;
            [].forEach.call(elems, function(el) {
                el.className = '';
                el.classList.add('col-lg-' + colSize);
            });
        }

        this.props.onLayoutViewClicked(colSize);
    };

    render() {
        return (
            <div className="product-filter-content">
                <div className="search-count">
                    <h5>
                        Search:{' '}
                        <strong>
                            {this.props.query !== '' ? this.props.query : 'All results'}
                        </strong>
                    </h5>
                </div>
                <div className="collection-view">
                    <h5>
                        Showing Products {this.props.showed}-{this.props.length} Results
                    </h5>
                </div>
                <div className="product-page-filter">
                    {/* <select onChange={e => this.props.filterSort(e.target.value)}>
                        <option value="">Sorting items</option>
                        <option value="HighToLow">Price: High to Low</option>
                        <option value="LowToHigh">Price: Low to High</option>
                        <option value="Newest">Newest Items</option>
                        <option value="AscOrder">Sort By Name: A To Z</option>
                        <option value="DescOrder">Sort By Name: Z To A</option>
                    </select> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    filters: state.filters,
});

export default connect(mapStateToProps, { filterSort })(FilterBar);
