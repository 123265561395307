import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import { currency, formatDate } from '../../utils/miscUtils';

import api from '../../config/axios';

function ProductItem(props) {
    const { product, auth } = props;
    const user = auth.user ? auth.user.user : null;

    const [variantSelected, setVariantSelected] = useState(product.variants[0]);
    const [modalProject, setModalProject] = useState(false);
    const [loadedProjects, setLoadedProjects] = useState(false);
    const [projects, setProjects] = useState([]);
    const history = useHistory();

    const onClickHandle = variant => {
        setVariantSelected(variant);
    };

    const openModalProject = e => {
        e.stopPropagation();
        if (user) {
            setModalProject(true);
            fetchProjects();
        } else {
            history.push('/pages/login');
        }
    };

    const fetchProjects = async () => {
        setLoadedProjects(false);
        const { data } = await api.get(`/user/${user.id}/projects`);
        setProjects(data.data.rows.map(item => ({ ...item, inputQuantity: false })));
        setLoadedProjects(true);
    };

    const addVariantToProject = (projectId, quantity) => {
        api.post(`/user/${user.id}/projects/${projectId}/add/${variantSelected.id}`, {
            quantity,
        })
            .then(response => {
                toast('Product added to the project', { autoClose: true });
                fetchProjects();
            })
            .catch(error => console.log(error));
    };

    const deleteVariantOfProject = projectId => {
        api.get(`/user/${user.id}/projects/${projectId}/delete/${variantSelected.id}`)
            .then(response => {
                toast('Product removed of the project');
                fetchProjects();
            })
            .catch(error => console.log(error));
    };

    const handleVariantProject = (exist, projectId, quantity) => {
        if (exist) {
            deleteVariantOfProject(projectId);
        } else {
            if (quantity <= variantSelected.quantity) {
                addVariantToProject(projectId, quantity);
            } else {
                toast('Sorry, quantity exceeds stock availability. Try again.');
            }
        }
    };

    const renderProjects = () => {
        if (projects.length > 0) {
            return projects.map((project, index) => {
                const isActive =
                    project.variants.find(variant => variant.id === variantSelected.id) !==
                    undefined;
                return (
                    <ItemModalProject
                        key={`project-${index}`}
                        project={project}
                        isActive={isActive}
                        handleVariantProject={handleVariantProject}
                        variantSelected={variantSelected}
                    />
                );
            });
        } else {
            return null;
        }
    };

    let RatingStars = [];
    for (var i = 0; i < 5; i++) {
        RatingStars.push(<i className="fa fa-star" key={i} />);
    }

    return (
        <div className="product-box product-search-result">
            <div className="img-wrapper">
                <div className="front">
                    <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`}>
                        <img src={variantSelected.images[0].url} className="img-fluid" alt="" />
                    </Link>
                </div>
                {/* <div className="cart-info cart-wrap">
                    <span onClick={openModalProject}>
                        <i className="fa fa-heart" aria-hidden="true"></i>
                    </span>
                </div> */}
                {product.variants && product.variants.length > 1 ? (
                    <ul className="product-thumb-list">
                        {product.variants.map((variant, i) => (
                            <li
                                className={`grid_thumb_img ${
                                    variant.sku === variantSelected.sku ? 'active' : ''
                                }`}
                                key={i}
                            >
                                <img
                                    src={`${variant.images[0].url}`}
                                    alt={variant.sku}
                                    onClick={() => onClickHandle(variant)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </li>
                        ))}
                    </ul>
                ) : (
                    ''
                )}
            </div>
            <div className="product-detail">
                <div>
                    {/* <div className="rating">{RatingStars}</div> */}
                    <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`}>
                        <h6>{product.title}</h6>
                    </Link>
                    {/* <h4>
                        {currency(product.variants[0].price_rent)}{' '}
                        <span style={{ fontSize: 15, textTransform: 'none' }}>/day rental</span>
                    </h4>
                    <span className="mt-1 mb-2 product-detail-retail-price">
                        {' '}
                        {currency(product.variants[0].price_retail)} retail
                    </span> */}
                    {product.variants ? (
                        <ul className="color-variant">
                            {product.variants.map((vari, i) => {
                                return (
                                    <li
                                        className={vari.color}
                                        key={i}
                                        title={vari.color}
                                        onClick={() => onClickHandle(vari.images)}
                                    />
                                );
                            })}
                        </ul>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <Modal open={modalProject} onClose={() => setModalProject(false)} center>
                <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                    <div className="modal-content quick-view-modal">
                        <div className="modal-header">
                            <h4>my project's</h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 text-right">
                                    <p>
                                        Stock available:{' '}
                                        <strong>{variantSelected.quantity} units</strong>
                                    </p>
                                </div>
                            </div>
                            {!loadedProjects ? (
                                <div className="loading-cls" />
                            ) : (
                                <div className="row">{renderProjects()}</div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

function ItemModalProject(props) {
    const { project, isActive, handleVariantProject, variantSelected } = props;
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        if (isActive) {
            const variant = project.variants.find(variant => variant.id === variantSelected.id);
            setQuantity(variant.projectVariant.quantity);
        }
    }, []);

    const minusQty = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const plusQty = () => {
        if (variantSelected.quantity > quantity) {
            setQuantity(quantity + 1);
        }
    };

    const changeQty = e => {
        if (e.target.value && e.target.value !== '') {
            setQuantity(parseInt(e.target.value));
        } else {
            setQuantity(0);
        }
    };

    return (
        <div className="col-lg-12 item-project-modal">
            <div className="wrapper-project-modal">
                <div className="description-project-modal">
                    <Link to={`/projects/${project.id}`}>
                        <strong>{project.name}</strong>
                        {project.dateFrom && project.dateTo && (
                            <p>
                                {moment(project.dateFrom.split('T')[0]).format(formatDate)}{' '}
                                <i className="fa fa-arrow-right" />
                                &nbsp;
                                {moment(project.dateTo.split('T')[0]).format(formatDate)}
                            </p>
                        )}
                    </Link>
                </div>
                <div className="option-project-modal">
                    {!isActive ? (
                        <div className="qty-box">
                            <div className="input-group">
                                <span className="input-group-prepend">
                                    <button
                                        type="button"
                                        className="btn quantity-left-minus"
                                        onClick={minusQty}
                                        data-type="minus"
                                        data-field=""
                                    >
                                        <i className="fa fa-angle-left" />
                                    </button>
                                </span>
                                <input
                                    type="text"
                                    name="quantity"
                                    value={quantity}
                                    max={variantSelected.quantity}
                                    onChange={changeQty}
                                    className="form-control input-number"
                                />
                                <span className="input-group-prepend">
                                    <button
                                        type="button"
                                        className="btn quantity-right-plus"
                                        onClick={plusQty}
                                        data-type="plus"
                                        data-field=""
                                    >
                                        <i className="fa fa-angle-right" />
                                    </button>
                                </span>
                            </div>
                        </div>
                    ) : null}
                    {isActive ? (
                        <span>
                            {quantity} unit(s) added <i className="fa fa-check" />
                        </span>
                    ) : (
                        <button
                            onClick={() => handleVariantProject(isActive, project.id, quantity)}
                            className="btn-sm btn-outline ml-2"
                        >
                            add
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    {},
)(ProductItem);
