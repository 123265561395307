// Get value from query URL
export const getValueFromQuery = (str, value) => {
    if (str.match(value)) {
        return str
            .split(`${value}=`)
            .pop()
            .split('&')[0];
    }
    return null;
};

export const currency = value => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
    });
    return formatter.format(value).replace(/^(\D+)/, '$1');
};

export const roundTwoDecimals = value => {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return formatter.format(value);
};

export const renderIconCard = name => {
    switch (name) {
        case 'Visa':
            return 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Old_Visa_Logo.svg/524px-Old_Visa_Logo.svg.png';

        case 'MasterCard':
            return 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/650px-Mastercard_2019_logo.svg.png';

        case 'American Express':
            return 'https://pngimage.net/wp-content/uploads/2018/05/amex-logo-png-2.png';

        case 'Diners Club':
            return 'https://cdn.iconscout.com/icon/free/png-256/diners-club-international-675719.png';

        default:
            break;
    }
};

export const formatDate = 'MM/DD/YYYY';
