import React from 'react';
import moment from 'moment';
import { currency, formatDate } from '../../utils/miscUtils';
import { totalShipping } from './utils';

function OrderSummary(props) {
    const {
        project,
        getAmountFee,
        deliveryOption,
        totalItems,
        subtotal: subtotalProps,
        rentDays,
        settingsBusiness,
        coupon,
        couponDiscount,
    } = props;

    const subtotal = currency(subtotalProps);
    const fee = currency(getAmountFee(subtotalProps));
    const shipping = deliveryOption
        ? totalShipping(
              deliveryOption.outbound,
              deliveryOption.return,
              settingsBusiness.shipping_coverage,
          )
        : 0;

    const total = subtotalProps + getAmountFee(subtotalProps) + (deliveryOption ? shipping : 0);

    let couponAmount = 0;

    if (coupon && coupon.status === 'active') {
        couponAmount = couponDiscount(total, coupon);
    }

    return (
        <div className="col-lg-6 col-sm-12 col-xs-12">
            <div className="review-order-title text-center">
                <h4>Order summary</h4>
            </div>
            <div className="checkout-details">
                <div className="order-box">
                    <div>
                        <h4>Rental dates</h4>
                        <p>
                            {moment(project.dateFrom).format(formatDate)}{' '}
                            <i className="fa fa-angle-right"></i>{' '}
                            {moment(project.dateTo).format(formatDate)}
                        </p>
                    </div>
                    <div className="title-box">
                        <div>
                            Item <span> Total</span>
                        </div>
                    </div>
                    <ul className="qty">
                        {project.variants.map((item, index) => {
                            return (
                                <li
                                    key={`item-order-${index}`}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div className="description-item-checkout">
                                        <img src={item.images[0].url} alt={item.product.title} />
                                        &nbsp;
                                        <div>
                                            <p>
                                                <strong>{item.product.title}</strong>
                                            </p>
                                            <p>Qty: {item.projectVariant.quantity}</p>
                                        </div>
                                    </div>
                                    <div className="total-item-checkout">
                                        <strong>
                                            {currency(
                                                item.price_rent * item.projectVariant.quantity,
                                            )}
                                        </strong>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                    <ul className="sub-total">
                        <li>
                            Total Items <span className="count">{currency(totalItems)}</span>
                        </li>
                        <li>
                            Rent for {rentDays} days<span className="count">{subtotal}</span>
                        </li>
                        <li>
                            Fee<span className="count">{fee}</span>
                        </li>
                        <li>
                            Shipping & Handling
                            <span className="count">{currency(shipping)}</span>
                            <p>Return included</p>
                        </li>
                        {coupon && coupon.status === 'active' && (
                            <li>
                                Promo code {coupon.value}
                                {coupon.type === 'percentage' ? '%' : '$'}
                                <span className="count">-{currency(couponAmount)}</span>
                            </li>
                        )}
                    </ul>

                    <ul className="total">
                        <li>
                            Total{' '}
                            <span className="count">
                                <strong>{currency(total - couponAmount)}</strong>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default OrderSummary;
