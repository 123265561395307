import React, { useEffect, useState } from 'react';
import moment from 'moment';
import api from '../../config/axios';
import { useHistory, Link } from 'react-router-dom';
import { getValueFromQuery, currency, renderIconCard, formatDate } from '../../utils/miscUtils';

function OrderSuccess(props) {
    const [rent, setRent] = useState(null);
    const [loaded, setLoaded] = useState(null);
    const history = useHistory();
    const rentId = getValueFromQuery(props.location.search, 'r');

    useEffect(() => {
        if (rentId) {
            api.get(`/rent/${rentId}`)
                .then(({ data }) => {
                    if (data.data) {
                        setRent(data.data);
                        setLoaded(true);
                    } else {
                        history.replace('/404');
                    }
                })
                .catch(error => history.replace('/404'));
        } else {
            history.replace('/404');
        }
    }, []);

    const groupStock = stock => {
        const arrayGrouped = stock.reduce((total, value) => {
            total[value.variant.id] = (total[value.variant.id] || 0) + 1;

            return total;
        }, {});
        const stockGrouped = Object.keys(arrayGrouped).map(key => {
            const variant = stock.find(itemStock => itemStock.variant.id === key);
            return { variant: variant.variant, quantity: arrayGrouped[key] };
        });
        return stockGrouped;
    };

    if (!loaded) return <PlacingOrder />;

    const rentDays = moment(rent.dateTo).diff(moment(rent.dateFrom), 'days');
    const totalItems = rent.stocks.map(item => item.variant.price_rent).reduce((a, b) => a + b);
    const shippingAmount = rent.totalShipping - rent.shippingCoverage;
    const stockGrouped = groupStock(rent.stocks);
    const subtotal = stockGrouped
        .map(item => item.variant.price_rent * item.quantity * rentDays)
        .reduce((a, b) => a + b);

    let couponAmount = 0;
    if (rent.coupon) {
        couponAmount =
            rent.coupon.type === 'percentage'
                ? (subtotal + shippingAmount + rent.fee) * (Number(rent.coupon.value) / 100)
                : rent.coupon.value;
    }

    return (
        <section className="section-b-space rent-detail-page">
            <div className="container padding-cls">
                <div className="pt-5 light-layout">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="success-text">
                                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                                    <h2>thank you</h2>
                                    <strong>
                                        Payment has been received. Order placed successfully.
                                    </strong>
                                    <p>Rent ID: {rent.id}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="checkout-page">
                    <div className="row mt-4">
                        <div className="col-lg-6 col-sm-12 col-xs-12">
                            <div className="wrapper-rents-info">
                                <div className="rents-info-left">
                                    <div className="checkout-title">
                                        <h3>rent information</h3>
                                    </div>
                                    <p className="mb-2">
                                        Order # <span>{rent.id}</span>
                                    </p>
                                    <p>
                                        Ordered:{' '}
                                        <span>{moment(rent.rentalDate).format(formatDate)}</span>
                                    </p>
                                    <p>
                                        Rent days: <span>{rentDays} days</span>
                                    </p>
                                    <p>
                                        Start day:{' '}
                                        <span>{moment(rent.dateFrom).format(formatDate)}</span>
                                    </p>
                                    <p>
                                        End day:{' '}
                                        <span>{moment(rent.dateTo).format(formatDate)}</span>
                                    </p>
                                </div>
                                <div className="rents-info-left">
                                    <div className="checkout-title">
                                        <h3>shipping address</h3>
                                    </div>
                                    <p>
                                        {rent.outboundShipmentInfo[0].to_address.street1}
                                        {rent.outboundShipmentInfo[0].to_address.street2
                                            ? `, ${rent.outboundShipmentInfo[0].to_address.street2}.`
                                            : '. '}
                                    </p>
                                    <p>
                                        {rent.outboundShipmentInfo[0].to_address.city},{' '}
                                        {rent.outboundShipmentInfo[0].to_address.state}
                                    </p>
                                    <p>{rent.outboundShipmentInfo[0].to_address.zip}</p>
                                    <p>{rent.outboundShipmentInfo[0].to_address.country}</p>
                                    <p>{rent.outboundShipmentInfo[0].to_address.phone}</p>
                                </div>
                                <div className="rents-info-left">
                                    <div className="checkout-title">
                                        <h3>payment method</h3>
                                    </div>
                                    <div className="item-card-options">
                                        <div className="info-card-options">
                                            <div>
                                                <img
                                                    alt={rent.chargeInfo.source.brand}
                                                    src={renderIconCard(
                                                        rent.chargeInfo.source.brand,
                                                    )}
                                                />
                                            </div>
                                            <div>
                                                <p>{rent.chargeInfo.source.name}</p>
                                                <p>**** **** **** {rent.chargeInfo.source.last4}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <strong>
                                                Exp: {rent.chargeInfo.source.exp_month} /{' '}
                                                {rent.chargeInfo.source.exp_year}
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 col-xs-12">
                            <div className="review-order-title text-center">
                                <h4>Order summary</h4>
                            </div>
                            <div className="rent-checkout-details">
                                <div className="order-box">
                                    <div className="title-box">
                                        <div>
                                            Item <span> Total</span>
                                        </div>
                                    </div>
                                    <ul className="qty">
                                        {stockGrouped.map((item, index) => {
                                            return (
                                                <li
                                                    key={`item-order-${index}`}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <div className="description-item-checkout">
                                                        <img
                                                            src={item.variant.images[0].url}
                                                            alt={item.variant.product.title}
                                                        />
                                                        &nbsp;
                                                        <div>
                                                            <p>{item.variant.product.title}</p>
                                                            <p className="mt-1">
                                                                Qty: {item.quantity}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="total-item-checkout">
                                                        <strong>
                                                            {currency(
                                                                item.variant.price_rent *
                                                                    item.quantity,
                                                            )}
                                                        </strong>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <ul className="sub-total">
                                        <li>
                                            Total Items
                                            <span className="count">{currency(totalItems)}</span>
                                        </li>
                                        <li>
                                            Rent for {rentDays} days
                                            <span className="count">{currency(subtotal)}</span>
                                        </li>
                                        <li>
                                            Fee
                                            <span className="count">{currency(rent.fee)}</span>
                                        </li>
                                        <li>
                                            Shipping & Handling
                                            <span className="count">
                                                {currency(shippingAmount)}
                                            </span>
                                            <p>Return included</p>
                                        </li>
                                        {rent.coupon && (
                                            <li>
                                                Promo code {rent.coupon.value}
                                                {rent.coupon.type === 'percentage' ? '%' : '$'}
                                                <span className="count">
                                                    -{currency(couponAmount)}
                                                </span>
                                            </li>
                                        )}
                                    </ul>

                                    <ul className="total">
                                        <li>
                                            Total{' '}
                                            <span className="count">
                                                <strong>{currency(rent.priceTotal)}</strong>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="payment-box mt-5"
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <div className="text-right">
                        <Link to="/" className="btn btn-lg btn-theme">
                            Go home
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

function PlacingOrder() {
    return (
        <div
            style={{
                height: '100%',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: 200,
                backgroundColor: 'rgba(255, 255,255, 0.8)',
            }}
        >
            <div className="loading-cls"></div>
            <h2 style={{ marginTop: 30 }}>please wait...</h2>
        </div>
    );
}

export default OrderSuccess;
