import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from '../common/breadcrumb';
import useForm from 'react-hook-form';
import Pace from 'react-pace-progress';
import axios from '../../config/axios';
import { getValueFromQuery } from '../../utils/miscUtils';
import { decode } from '../../utils/jwt';

function ChangePassword() {
    const { handleSubmit, register, errors, watch } = useForm();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(false);
    const [changed, setChange] = useState(false);
    const [errorRequest, setErrorsRequest] = useState(null);
    const [errorToken, setErrorToken] = useState(null);
    const [infoToken, setInfoToken] = useState(null);

    useEffect(() => {
        const token = getValueFromQuery(history.location.search, 'token');

        if (token === '') {
            history.push('/404');
        }

        const decodeToken = async () => {
            try {
                const decodedInfo = await decode(token);
                setInfoToken(decodedInfo);
            } catch (e) {
                setErrorToken(e);
            }
        };

        decodeToken();
        setToken(token);
    }, []);

    const handleChangePassword = (data, e) => {
        e.preventDefault();
        setLoading(true);

        const dataRequest = {
            email: infoToken.email,
            newPassword: data.password,
        };

        axios
            .put(`/auth/recovery/${token}`, dataRequest)
            .then(({ data }) => {
                setChange(true);
                setErrorsRequest(null);
                setLoading(false);
            })
            .catch(({ response }) => {
                setLoading(false);
                setErrorsRequest(response.data.errors);
            });
    };

    if (errorToken) {
        return (
            <div>
                {/*Error with token*/}
                <section className="pwd-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <h3>
                                    An error has occurred. Please request a password recovery again.
                                </h3>
                                <div className="form-row justify-content-center">
                                    <Link
                                        to="/pages/forgot-password"
                                        className="btn btn-solid mt-4"
                                    >
                                        recover password
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
    return <div>
            <Breadcrumb title={'change password'} />
            {loading ? <Pace color="#ff4c3b" /> : null}

            {/*Forget Password section*/}
            {!changed ? <section className="pwd-page section-b-space">
                    <Helmet>
                        <title>Change your Password - Louis & Rocco</title>
                        <meta property="og:title" content="Change your Password - Louis & Rocco" />
                        <meta property="og:image" content={'https://louisandrocco.com/assets/images/icon/logo/logo_short.png'} />
                        <meta property="og:url" content={`${process.env.PUBLIC_URL}/pages/change-password`} />
                    </Helmet>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 offset-lg-4">
                                <h2>Change Your Password</h2>
                                <form onSubmit={handleSubmit(handleChangePassword)} className="theme-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <input type="password" className="form-control" id="password" name="password" placeholder="Enter your new password" ref={register(
                                                    {
                                                        required: true,
                                                        pattern: /^(?=.*\d)(?=.*[a-z])[0-9a-zA-Z!"#$%&()=?¿*-_.:,;+^\\-`.+,/\"]{8,}$/i,
                                                    },
                                                )} />
                                            {errors.password && errors.password.type === 'required' && <p className="message-error-input">
                                                    Password is required
                                                </p>}
                                            {errors.password && errors.password.type === 'pattern' && <p className="message-error-input">
                                                    Must contain at least 8 characters and at
                                                    least 1 number
                                                </p>}
                                        </div>
                                        <div className="col-md-12">
                                            <input type="password" className="form-control" placeholder="Type again your new password" id="repassword" name="repassword" ref={register(
                                                    {
                                                        required: true,
                                                        validate: value =>
                                                            value === watch('password'),
                                                    },
                                                )} />
                                            {errors.repassword && errors.repassword.type === 'required' && <p className="message-error-input">
                                                    Please confirm your password
                                                </p>}
                                            {errors.repassword && errors.repassword.type === 'validate' && <p className="message-error-input">
                                                    Passwords do not match
                                                </p>}
                                        </div>
                                        <button type="submit" className="btn btn-solid mt-4" onClick={handleSubmit(handleChangePassword)}>
                                            change password
                                        </button>
                                    </div>
                                    <div className="wrapper-errors">
                                        {errorRequest ? errorRequest.map(error => (
                                                  <p className="message-error-input">
                                                      <i className="fa fa-exclamation-triangle" />
                                                      &nbsp;{error.msg}
                                                  </p>
                                              )) : null}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section> : <section className="pwd-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <h2>Password changed successfully.</h2>
                                <div className="form-row justify-content-center">
                                    <Link to="/pages/login" className="btn btn-solid mt-4">
                                        go to login
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
        </div>;
}

export default ChangePassword;
