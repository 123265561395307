import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import useForm from 'react-hook-form';
import api from '../../config/axios';
import { renderIconCard } from '../../utils/miscUtils';

function PaymentCheckout(props) {
    const Stripe = window.Stripe(process.env.REACT_APP_STRIPE_PK);
    const {
        user,
        paymentMethod,
        setPaymentMethod,
        billingAddress,
        setBillingAddress,
        coupon,
        setCoupon,
        children,
        setStep,
    } = props;
    const { register, errors, setValue, watch, getValues } = useForm();
    const {
        register: registerCard,
        errors: errorsCard,
        handleSubmit: handleSubmitCard,
    } = useForm();
    const { register: registerCoupon, handleSubmit: handleSubmitCoupon } = useForm();
    const elements = Stripe.elements();

    const [card, setCard] = useState({});
    const [couponLoading, setCouponLoading] = useState(false);
    const [errorStripe, setErrorStripe] = useState(false);

    useEffect(() => {
        fetchCards();
        fetchAddresses();

        let cardInfo = {};
        cardInfo.cardNumber = elements.create('cardNumber', {
            style: elementStyles,
            classes: elementClasses,
        });
        cardInfo.cardNumber.mount('#cardNumber');
        cardInfo.cardNumber.on('change', function(event) {
            if (event.complete) {
                setErrorStripe(prevState => ({
                    ...prevState,
                    cardNumber: {
                        error: false,
                    },
                }));
            } else if (event.error) {
                // show validation to customer
                setErrorStripe(prevState => ({
                    ...prevState,
                    cardNumber: {
                        error: true,
                        message: 'Card number is invalid',
                    },
                }));
            }
        });

        cardInfo.cardExpiry = elements.create('cardExpiry', {
            style: elementStyles,
            classes: elementClasses,
        });
        cardInfo.cardExpiry.mount('#cardExpiry');
        cardInfo.cardExpiry.on('change', function(event) {
            if (event.complete) {
                setErrorStripe(prevState => ({
                    ...prevState,
                    cardExpiry: {
                        error: false,
                    },
                }));
            } else if (event.error) {
                // show validation to customer
                setErrorStripe(prevState => ({
                    ...prevState,
                    cardExpiry: {
                        error: true,
                        message: 'Expiry date is invalid',
                    },
                }));
            }
        });

        cardInfo.cardCvc = elements.create('cardCvc', {
            style: elementStyles,
            classes: elementClasses,
        });
        cardInfo.cardCvc.mount('#cardCvc');
        cardInfo.cardCvc.on('change', function(event) {
            if (event.complete) {
                setErrorStripe(prevState => ({
                    ...prevState,
                    cardCvc: {
                        error: false,
                    },
                }));
            } else if (event.error) {
                // show validation to customer
                setErrorStripe(prevState => ({
                    ...prevState,
                    cardCVC: {
                        error: true,
                        message: 'Secure code is invalid',
                    },
                }));
            }
        });

        setCard(cardInfo);
    }, []);

    const [loadedGet, setLoadedGet] = useState(false);
    const [cards, setCards] = useState([]);
    const [formCard, setFormCard] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [errorsForm, setErrorsForm] = useState(null);
    const [newCard, setNewCard] = useState(false);

    const fetchCards = async () => {
        const { data } = await api.get(`/user/${user.id}/payment-method`);
        setCards(data.data);
        if (data.data.length === 0) {
            setFormCard(true);
        }
        setLoadedGet(true);
    };

    const addCard = (data, e) => {
        e.preventDefault();
        if (validateStripeElement()) {
            Stripe.createToken(card.cardNumber, { name: data.firstName }).then(response => {
                setPaymentMethod(response.token);
                setNewCard(true);
            });
        }
    };

    const fetchCoupon = async (data, e) => {
        e.preventDefault();
        setCouponLoading(true);
        try {
            const responseCoupon = await api.get(`/coupon/${data.coupon}`);
            setCoupon(responseCoupon.data.data);
            setCouponLoading(false);
        } catch (error) {
            setCouponLoading(false);
        }
    };

    const cleanCoupon = () => {
        setCoupon(null);
    };

    /**
     * Custom validator to verify the right way to build the token request
     */
    const validateStripeElement = () => {
        let error = false;
        let objectErrors = {};
        if (card.cardNumber._empty) {
            objectErrors = {
                cardNumber: {
                    error: true,
                    message: 'Card number is required',
                },
            };
            error = true;
        }
        if (card.cardExpiry._empty) {
            objectErrors = {
                ...objectErrors,
                cardExpiry: {
                    error: true,
                    message: 'Expiry date is required',
                },
            };
            error = true;
        }
        if (card.cardCvc._empty) {
            objectErrors = {
                ...objectErrors,
                cardCvc: {
                    error: true,
                    message: 'Secure code is required',
                },
            };
            error = true;
        }
        if (error) {
            setErrorStripe(objectErrors);
        }
        return !error;
    };

    const fetchAddresses = async () => {
        const { data } = await api.get(`/user/${user.id}/address`);
        setAddresses(data.data);
    };

    const handleMyAddress = value => {
        setValue('fullName', value.fullName);
        setValue('street1', value.street1);
        setValue('street2', value.street2);
        setValue('city', value.city);
        setValue('state', value.state);
        setValue('zip', value.zip);
        setValue('phone', value.phone);

        setBillingAddress(value);
    };

    const addressValidate = () => {
        let valid = true;
        const watchFields = watch(['fullName', 'street1', 'city', 'state', 'zip', 'phone']);
        Object.keys(watchFields).forEach(item => {
            if (watchFields[item] === '' || watchFields[item] === undefined) {
                valid = false;
            }
        });
        return valid;
    };

    const handleNextStep = () => {
        if (addressValidate() && paymentMethod) {
            setBillingAddress(getValues());
            setStep(3);
        } else if (!paymentMethod) {
            setErrorsForm('Please, select payment method to continue.');
        } else if (!addressValidate()) {
            setErrorsForm('Please, add billing address to continue.');
        }
    };

    const handleClickCard = card => {
        setNewCard(false);
        setPaymentMethod(card);
    };

    return (
        <div>
            <div className="checkout row">
                <div className="col-lg-12 mb-3">
                    <div>
                        <span
                            onClick={() => setStep(1)}
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        >
                            <i className="fa fa-chevron-left"></i>&nbsp;Go back to Shipment options
                        </span>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-xs-12">
                    <div className="checkout-box-section">
                        <div className="checkout-title">
                            <h3>Payment method</h3>
                        </div>
                        <div>
                            {!loadedGet ? (
                                <div className="loading-cls"></div>
                            ) : cards.length > 0 ? (
                                <div>
                                    <h4>My cards</h4>
                                    <div className="mb-3">
                                        {cards.map((card, index) => (
                                            <CardItem
                                                key={`card-${index}`}
                                                index={index}
                                                card={card}
                                                paymentMethod={paymentMethod}
                                                setPaymentMethod={setPaymentMethod}
                                                handleClickCard={handleClickCard}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div>
                            {cards.length > 0 ? (
                                <button
                                    onClick={() => setFormCard(!formCard)}
                                    className="btn-link btn"
                                >
                                    {formCard ? 'Close' : 'Add new card'}
                                </button>
                            ) : null}
                        </div>
                        <div className={`row check-out form-card${formCard ? '' : '-hidden'}`}>
                            <div className="form-group col-lg-12">
                                <label htmlFor="firstName">Name on card</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fname"
                                    name="firstName"
                                    ref={registerCard({
                                        required: true,
                                    })}
                                />
                                <p className="message-error-input">
                                    {errors.firstName &&
                                        errors.firstName.type === 'required' &&
                                        'Name on card is required'}
                                </p>
                            </div>
                            <div className="form-group col-lg-12">
                                <label htmlFor="cardNumber">Card number</label>
                                <div
                                    id="cardNumber"
                                    className="empty field"
                                    style={stylesElements}
                                ></div>
                                <p className="message-error-input">
                                    {errorStripe.cardNumber &&
                                        errorStripe.cardNumber.error &&
                                        errorStripe.cardNumber.message}
                                </p>
                            </div>
                            <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                <label htmlFor="expireDate">Expiry date</label>
                                <div
                                    id="cardExpiry"
                                    className="empty field"
                                    style={stylesElements}
                                ></div>
                                <p className="message-error-input">
                                    {errorStripe.cardExpiry &&
                                        errorStripe.cardExpiry.error &&
                                        errorStripe.cardExpiry.message}
                                </p>
                            </div>
                            <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                <label htmlFor="cvc" style={{ marginBottom: '.5rem' }}>
                                    Secure code
                                </label>
                                <div
                                    id="cardCvc"
                                    className="empty field"
                                    style={stylesElements}
                                ></div>
                                <p className="message-error-input">
                                    {errorStripe.cardCvc &&
                                        errorStripe.cardCvc.error &&
                                        errorStripe.cardCvc.message}
                                </p>
                            </div>
                            <div className="form-group text-right col-md-12 col-sm-12 col-xs-12">
                                <button
                                    type="button"
                                    className="btn-outline btn"
                                    onClick={handleSubmitCard(addCard)}
                                >
                                    use this card to payment
                                </button>
                            </div>
                        </div>
                        {newCard ? (
                            <div>
                                <div className="mb-3">
                                    <div className={`item-card-options item-dinamyc active`}>
                                        <div className="info-card-options">
                                            <div>
                                                <i className="fa fa-check"></i>
                                                &nbsp;
                                                <img
                                                    alt={paymentMethod.card.brand}
                                                    src={renderIconCard(paymentMethod.card.brand)}
                                                />
                                            </div>
                                            <div>
                                                <p>{paymentMethod.card.name}</p>
                                                <p>**** **** **** {paymentMethod.card.last4}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <strong>
                                                Exp: {paymentMethod.card.exp_month} /{' '}
                                                {paymentMethod.card.exp_year}
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    <div className="checkout-box-section mt-4">
                        <div className="checkout-title">
                            <h3>Billing address</h3>
                            <div>
                                {!loadedGet ? (
                                    <div className="loading-cls"></div>
                                ) : addresses.length > 0 ? (
                                    <div className="mt-3">
                                        <div className="mb-3">
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                name="categoryId"
                                                placeholder="My addresses"
                                                onChange={value => handleMyAddress(value)}
                                                defaultValue={
                                                    billingAddress
                                                        ? {
                                                              value: billingAddress.id,
                                                              label: `${billingAddress.fullName} - ${billingAddress.street1}, ${billingAddress.city}, ${billingAddress.state}`,
                                                          }
                                                        : null
                                                }
                                                options={addresses.map(address => {
                                                    return {
                                                        ...address,
                                                        value: address.id,
                                                        label: `${address.fullName} - ${address.street1}, ${address.city}, ${address.state}`,
                                                    };
                                                })}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="row check-out">
                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div className="field-label">Full name</div>
                                    <input
                                        type="text"
                                        name="fullName"
                                        ref={register({
                                            required: true,
                                        })}
                                        defaultValue={billingAddress && billingAddress.fullName}
                                    />
                                    <p className="message-error-input">
                                        {errors.fullName &&
                                            errors.fullName.type === 'required' &&
                                            'Full Name is required'}
                                    </p>
                                </div>
                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div className="field-label">Street 1</div>
                                    <input
                                        type="text"
                                        name="street1"
                                        ref={register({
                                            required: true,
                                        })}
                                        defaultValue={billingAddress && billingAddress.street1}
                                    />
                                    <p className="message-error-input">
                                        {errors.street1 &&
                                            errors.street1.type === 'required' &&
                                            'Street is required'}
                                    </p>
                                </div>
                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div className="field-label">Street 2</div>
                                    <input
                                        type="text"
                                        name="street2"
                                        ref={register({
                                            required: false,
                                        })}
                                        defaultValue={billingAddress && billingAddress.street2}
                                    />
                                </div>
                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div className="field-label">Town/City</div>
                                    <input
                                        type="text"
                                        name="city"
                                        ref={register({
                                            required: true,
                                        })}
                                        defaultValue={billingAddress && billingAddress.city}
                                    />
                                    <p className="message-error-input">
                                        {errors.city &&
                                            errors.city.type === 'required' &&
                                            'City is required'}
                                    </p>
                                </div>
                                <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                    <div className="field-label">State / County</div>
                                    <input
                                        type="text"
                                        name="state"
                                        ref={register({
                                            required: true,
                                        })}
                                        defaultValue={billingAddress && billingAddress.state}
                                    />
                                    <p className="message-error-input">
                                        {errors.state &&
                                            errors.state.type === 'required' &&
                                            'State is required'}
                                    </p>
                                </div>
                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div className="field-label">Postal code</div>
                                    <input
                                        type="text"
                                        name="zip"
                                        ref={register({
                                            required: true,
                                        })}
                                        defaultValue={billingAddress && billingAddress.zip}
                                    />
                                    <p className="message-error-input">
                                        {errors.zip &&
                                            errors.zip.type === 'required' &&
                                            'Postal code is required'}
                                    </p>
                                </div>
                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div className="field-label">Phone</div>
                                    <input
                                        type="text"
                                        name="phone"
                                        ref={register({
                                            required: true,
                                        })}
                                        defaultValue={billingAddress && billingAddress.phone}
                                    />
                                    <p className="message-error-input">
                                        {errors.phone &&
                                            errors.phone.type === 'required' &&
                                            'Phone number is required'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="checkout-box-section mt-4 mb-3">
                        <div className="checkout-title">
                            <h3>promo code</h3>
                        </div>
                        <div className="row check-out form-card align-items-center">
                            <div className="form-group col-lg-6 d-flex align-items-center">
                                <div className="col-lg-10">
                                    <label htmlFor="coupon">Code</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="coupon"
                                        name="coupon"
                                        ref={registerCoupon({
                                            required: true,
                                        })}
                                    />
                                </div>
                                {coupon && coupon.status === 'active' && (
                                    <i
                                        style={{ fontSize: '2em', marginTop: '1em' }}
                                        className="fa fa-check-circle text-success"
                                    ></i>
                                )}
                                {coupon && coupon.status === 'invalid' && (
                                    <i
                                        style={{ fontSize: '2em', marginTop: '1em' }}
                                        className="fa fa-times-circle text-danger"
                                    ></i>
                                )}
                            </div>

                            <div className="form-group text-right col-lg-6">
                                {coupon && (
                                    <span
                                        onClick={cleanCoupon}
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                    >
                                        Discard coupon
                                    </span>
                                )}
                                &nbsp;&nbsp;
                                {couponLoading ? (
                                    <div className="loading-cls"></div>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn-outline btn"
                                        onClick={handleSubmitCoupon(fetchCoupon)}
                                    >
                                        apply
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {children}
            </div>
            <div
                className="payment-box"
                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
            >
                <div className="text-right">
                    <button type="button" className="btn-solid btn" onClick={handleNextStep}>
                        Continue
                    </button>
                    <p className="message-error-input">{errorsForm}</p>
                </div>
            </div>
        </div>
    );
}

function CardItem(props) {
    const { card, paymentMethod, setPaymentMethod, handleClickCard } = props;

    return (
        <div
            className={`item-card-options item-dinamyc ${
                paymentMethod && paymentMethod.id === card.id ? 'active' : ''
            }`}
            onClick={() => handleClickCard(card)}
        >
            <div className="info-card-options">
                <div>
                    {paymentMethod && paymentMethod.id === card.id ? (
                        <i className="fa fa-check"></i>
                    ) : null}{' '}
                    &nbsp;
                    <img alt={card.brand} src={renderIconCard(card.brand)} />
                </div>
                <div>
                    <p>{card.name}</p>
                    <p>**** **** **** {card.last4}</p>
                </div>
            </div>
            <div>
                <strong>
                    Exp: {card.exp_month} / {card.exp_year}
                </strong>
            </div>
        </div>
    );
}

const elementClasses = {
    focus: 'focused',
    empty: 'empty',
    invalid: 'invalid',
};

const elementStyles = {
    base: {
        color: '#32325D',
        fontWeight: 500,
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        '::placeholder': {
            color: '#CFD7DF',
        },
        ':-webkit-autofill': {
            color: '#e39f48',
        },
    },
    invalid: {
        color: '#E25950',

        '::placeholder': {
            color: '#FFCCA5',
        },
    },
};

const stylesElements = {
    width: '100%',
    height: 38,
    padding: '0.5rem 0.75rem',
    fontSize: '1rem',
    lineHeight: 1.5,
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    WebkitTransition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
};

export default PaymentCheckout;
