import React from 'react';

function ReviewOrder(props) {
    const { shipmentAddress, billingAddress, paymentMethod, setStep, placeOrder, children } = props;

    const cardInformation = paymentMethod.card ? paymentMethod.card : paymentMethod;

    return (
        <div className="review-order">
            <div className="checkout row">
                <div className="col-lg-12 mb-3">
                    <div>
                        <span
                            onClick={() => setStep(2)}
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        >
                            <i className="fa fa-chevron-left"></i>&nbsp;Go back to Payment method
                        </span>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-xs-12">
                    <div className="checkout-box-section">
                        <div className="checkout-title">
                            <h3>Delivery information</h3>
                        </div>
                        <div>
                            <h4>Shipping address</h4>
                        </div>
                        <div className="box-account box-info mb-5">
                            <div className="col-sm-12">
                                <div className="box">
                                    <div className="box-content">
                                        <div className="box-title">
                                            <h3>{shipmentAddress.fullName}</h3>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                }}
                                            ></div>
                                        </div>
                                        <p>{shipmentAddress.street1}</p>
                                        <p>{shipmentAddress.street2}</p>
                                        <p>
                                            {shipmentAddress.city}, {shipmentAddress.state}
                                        </p>
                                        <p>{shipmentAddress.zip}</p>
                                        <p>US</p>
                                        <p>Phone number: {shipmentAddress.phone}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="checkout-box-section mt-4">
                        <div className="checkout-title">
                            <h3>Payment method</h3>
                        </div>
                        <div className={`item-card-options`}>
                            <div className="info-card-options">
                                <div>
                                    <img
                                        alt="visa"
                                        src={
                                            cardInformation.brand === 'Visa'
                                                ? 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Old_Visa_Logo.svg/524px-Old_Visa_Logo.svg.png'
                                                : 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/650px-Mastercard_2019_logo.svg.png'
                                        }
                                    />
                                </div>
                                <div>
                                    <p>{cardInformation.name}</p>
                                    <p>**** **** **** {cardInformation.last4}</p>
                                </div>
                            </div>
                            <div>
                                <strong>
                                    Exp: {cardInformation.exp_month} / {cardInformation.exp_year}
                                </strong>
                            </div>
                        </div>
                        <div>
                            <h4>Billing address</h4>
                        </div>
                        <div className="box-account box-info mb-5">
                            <div className="col-sm-12">
                                <div className="box">
                                    <div className="box-content">
                                        <div className="box-title">
                                            <h3>{billingAddress.fullName}</h3>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                }}
                                            ></div>
                                        </div>
                                        <p>{billingAddress.street1}</p>
                                        <p>{billingAddress.street2}</p>
                                        <p>
                                            {billingAddress.city}, {billingAddress.state}
                                        </p>
                                        <p>{billingAddress.zip}</p>
                                        <p>US</p>
                                        <p>Phone number: {billingAddress.phone}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {children}
            </div>
            <div
                className="payment-box mt-5"
                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
            >
                <div className="text-right">
                    <button type="button" className="btn-solid btn" onClick={() => placeOrder()}>
                        Place order
                    </button>
                </div>
            </div>
        </div>
    );
}
export default ReviewOrder;
