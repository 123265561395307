import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import AccountInfo from './account-info';
import Newsletter from './newsletter';
import Subscriptions from './subscriptions';
import PaymentMethods from './cards';
import AddressBook from './address-book';
import OrderHistory from './order-history';

// A custom hook that builds on useLocation to parse
// the hash string for you.
function useHash() {
    if (useLocation().hash) {
        return useLocation().hash.split('#')[1];
    }
    return null;
}

function Profile(props) {
    const { user } = props.auth.user;
    const hash = useHash();
    const [section, setSection] = useState(null);

    useEffect(() => {
        setSection(hash || 'account-info');
    }, []);

    useEffect(() => {
        if (hash) setSection(hash);
    }, [hash]);

    const renderSection = section => {
        switch (section) {
            case 'account-info':
                return <AccountInfo user={user} />;
            case 'address-book':
                return <AddressBook user={user} />;
            case 'payment-methods':
                return <PaymentMethods user={user} />;
            case 'subscription':
                return <Subscriptions />;
            case 'newsletter':
                return <Newsletter user={user} />;
            case 'order-history':
                return <OrderHistory user={user} />;
            default:
                return null;
        }
    };
    return (
        <div>
            <section className="section-b-space dashboard-profile">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="account-sidebar">
                                <a className="popup-btn">my account</a>
                            </div>
                            <div className="dashboard-left">
                                <div className="collection-mobile-back">
                                    <span className="filter-back">
                                        <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                                </div>
                                <div className="block-content">
                                    <ul>
                                        <li
                                            className={`${
                                                section === 'account-info' ? 'active' : ''
                                            }`}
                                        >
                                            <Link to="/user/profile#account-info">
                                                Account info
                                            </Link>
                                        </li>
                                        <li
                                            className={`${
                                                section === 'order-history' ? 'active' : ''
                                            }`}
                                        >
                                            <Link to="/user/profile#order-history">
                                                Order History
                                            </Link>
                                        </li>
                                        <li
                                            className={`${
                                                section === 'address-book' ? 'active' : ''
                                            }`}
                                        >
                                            <Link to="/user/profile#address-book">
                                                Address Book
                                            </Link>
                                        </li>
                                        <li
                                            className={`${
                                                section === 'payment-methods' ? 'active' : ''
                                            }`}
                                        >
                                            <Link to="/user/profile#payment-methods">
                                                Payment methods
                                            </Link>
                                        </li>
                                        <li
                                            className={`${
                                                section === 'newsletter' ? 'active' : ''
                                            }`}
                                        >
                                            <Link to="/user/profile#newsletter">Newsletter</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="dashboard-right">
                                <div className="dashboard">{renderSection(section)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(Profile);
