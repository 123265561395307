import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { IntlActions } from 'react-redux-multilingual';

// Import custom components
import NavBar from './common/navbar';
import CartContainer from './../../../containers/CartContainer';
import TopBar from './common/topbar';
import LogoImage from './common/logo';
import { changeCurrency } from '../../../actions';
import { connect } from 'react-redux';

import store from '../../../store';
import { deleteUser } from '../../../actions/auth';

const HeaderOne = props => {
    const history = useHistory();
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        let number =
            window.pageXOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;
        if (number >= 300) {
            if (window.innerWidth < 576) {
                document.getElementById('sticky').classList.remove('fixed');
            } else document.getElementById('sticky').classList.add('fixed');
        } else {
            document.getElementById('sticky').classList.remove('fixed');
        }
    };

    const openNav = () => {
        var openmyslide = document.getElementById('mySidenav');
        if (openmyslide) {
            openmyslide.classList.add('open-side');
        }
    };
    const openSearch = () => {
        document.getElementById('search-overlay').style.display = 'block';
    };

    const closeSearch = () => {
        document.getElementById('search-overlay').style.display = 'none';
    };

    const handleSearch = e => {
        e.preventDefault();
        history.push(`/search?q=${searchInput}`);
    };

    const handleInput = e => {
        setSearchInput(e.target.value);
    };

    const logout = async () => {
        store.dispatch(deleteUser());
        history.push('/');
        window.location.reload();
    };

    const { auth } = props;
    return (
        <div>
            <header id="sticky" className="sticky">
                {/*Top Header Component*/}
                {/* <TopBar session={auth.user} /> */}

                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="main-menu">
                                <div className="menu-left">
                                    <div className="brand-logo">
                                        <LogoImage logo={props.logoName} />
                                    </div>
                                </div>
                                <div className="menu-right pull-right">
                                    {/*Top Navigation Bar Component*/}
                                    <NavBar logout={logout} />

                                    <div>
                                        <div className="icon-nav main-navbar">
                                            <ul className="nav-menu">
                                                <li className="onhover-div mobile-search">
                                                    <Link
                                                        to="#"
                                                        className="nav-link"
                                                        onClick={openSearch}
                                                    >
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`}
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <i
                                                            className="fa fa-search"
                                                            onClick={openSearch}
                                                        ></i>
                                                    </Link>
                                                </li>
                                                <li className="onhover-div mobile-setting">
                                                    <Link
                                                        to={`${
                                                            auth.user
                                                                ? `${process.env.PUBLIC_URL}/projects`
                                                                : '#'
                                                        }`}
                                                        className="nav-link"
                                                    >
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/images/icon/briefcase.png`}
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                        <i className="fal fa-briefcase"></i>
                                                    </Link>
                                                    {!auth.user ? (
                                                        <ul
                                                            className="nav-submenu"
                                                            style={{
                                                                left: 'unset',
                                                                right: 0,
                                                                width: 230,
                                                            }}
                                                        >
                                                            <li style={{ paddingRight: 20 }}>
                                                                <div>
                                                                    <p>
                                                                        Are you registered? Login to
                                                                        see your Projects.
                                                                    </p>
                                                                    <div className="text-center">
                                                                        <button
                                                                            className="btn btn-solid btn-xs"
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    '/pages/login',
                                                                                )
                                                                            }
                                                                        >
                                                                            Login
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    ) : null}
                                                </li>
                                                <li className="onhover-div mobile-setting">
                                                    <Link to="#" className="nav-link">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/images/icon/user.png`}
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                    </Link>

                                                    {!auth.user ? (
                                                        <ul
                                                            className="nav-submenu"
                                                            style={{ left: 'unset', right: 0 }}
                                                        >
                                                            <li style={{ paddingLeft: 0 }}>
                                                                <Link
                                                                    to={`${process.env.PUBLIC_URL}/pages/login`}
                                                                    data-lng="en"
                                                                >
                                                                    Login
                                                                </Link>
                                                            </li>
                                                            <li style={{ paddingLeft: 0 }}>
                                                                <Link
                                                                    to={`${process.env.PUBLIC_URL}/pages/register`}
                                                                    data-lng="en"
                                                                >
                                                                    Register
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    ) : (
                                                        <ul
                                                            className="nav-submenu"
                                                            style={{ left: 'unset', right: 0 }}
                                                        >
                                                            <li style={{ paddingLeft: 0 }}>
                                                                <Link
                                                                    to={`${process.env.PUBLIC_URL}/user/profile`}
                                                                    data-lng="en"
                                                                >
                                                                    Profile
                                                                </Link>
                                                            </li>
                                                            <li style={{ paddingLeft: 0 }}>
                                                                <Link
                                                                    to="#"
                                                                    onClick={logout}
                                                                    data-lng="en"
                                                                >
                                                                    Logout
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    )}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div id="search-overlay" className="search-overlay custom-height">
                <div>
                    <span className="closebtn" onClick={closeSearch} title="Close Overlay">
                        ×
                    </span>
                    <div className="overlay-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <form onSubmit={handleSearch}>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Search a Product"
                                                value={searchInput}
                                                onChange={handleInput}
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={handleSearch}
                                        >
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { changeCurrency })(HeaderOne);
