import React, { useState } from 'react';

import useForm from 'react-hook-form';
import Pace from 'react-pace-progress';
import axios from '../../config/axios';

function ForgetPassword() {
    const { handleSubmit, register, errors } = useForm();

    const [loading, setLoading] = useState(false);
    const [errorRequest, setErrorsRequest] = useState(null);
    const [requested, setRequested] = useState(false);

    const handleForgot = (data, e) => {
        e.preventDefault();
        const { email } = data;
        setLoading(true);

        axios
            .put(`/auth/recovery?email=${email}`)
            .then(({ data }) => {
                setErrorsRequest(null);
                setRequested(true);
                setLoading(false);
            })
            .catch(({ response }) => {
                setLoading(false);
                setErrorsRequest(response.data.errors);
            });
    };
    return (
        <div>
            {loading ? <Pace color="#ff4c3b" /> : null}

            {/*Forget Password section*/}
            {!requested ? (
                <section className="pwd-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3 pt-5 pb-5">
                                <h2>Forget Your Password</h2>
                                <form onSubmit={handleSubmit(handleForgot)} className="theme-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                placeholder="Enter your email"
                                                ref={register({
                                                    required: true,
                                                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i,
                                                })}
                                            />
                                            {errors.email && errors.email.type === 'required' && (
                                                <p className="message-error-input">
                                                    Email is required
                                                </p>
                                            )}
                                            {errors.email && errors.email.type === 'pattern' && (
                                                <p className="message-error-input">
                                                    Must be a valid email
                                                </p>
                                            )}
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-solid mt-5"
                                            onClick={handleSubmit(handleForgot)}
                                        >
                                            Recover password
                                        </button>
                                    </div>
                                    <div className="wrapper-errors">
                                        {errorRequest
                                            ? errorRequest.map(error => (
                                                  <p className="message-error-input">
                                                      <i className="fa fa-exclamation-triangle"></i>
                                                      &nbsp;{error.msg}
                                                  </p>
                                              ))
                                            : null}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section className="pwd-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <h2>Password recovery has been requested successfully</h2>
                                <h3>Check your email to continue the proccess</h3>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
}

export default ForgetPassword;
