import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useForm from 'react-hook-form';
import Modal from 'react-responsive-modal';
import { ToastContainer, toast } from 'react-toastify';

import api from '../../config/axios';

function Newsletter(props) {
    const { user } = props;

    const [infoUser, setInfoUser] = useState();
    const [loadedGet, setLoadedGet] = useState(true);

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const handleRadio = value => {
        const dataRequest = {
            ...infoUser,
            newsLetter: value,
        };
        api.put(`/user/${user.id}`, dataRequest).then(response => {
            fetchUserInfo();
            toast('Successfully Updated !');
        });
    };

    const fetchUserInfo = () => {
        api.get(`/user/${user.id}`)
            .then(({ data }) => {
                setInfoUser(data.data);
                setLoadedGet(false);
            })
            .catch(error => {
                setLoadedGet(false);
            });
    };

    return (
        <div className="wrapper-newsletter">
            <div className="page-title mb-4">
                <h2>Newsletter</h2>
            </div>
            <div className="box-account box-info">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="box">
                            <div className="box-title">
                                <h3>Receive emails from louis + rocco</h3>
                            </div>
                            <div className="box-content">
                                <div className="box-radios">
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                value="1"
                                                defaultChecked={infoUser && infoUser.newsLetter}
                                                onChange={() => handleRadio(true)}
                                            />
                                            &nbsp;Yes
                                        </label>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                value="0"
                                                defaultChecked={infoUser && !infoUser.newsLetter}
                                                onChange={() => handleRadio(false)}
                                            />
                                            &nbsp;No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Newsletter;
