import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import api from '../../config/axios';
import ModalProject from './modalProject';
import { formatDate } from '../../utils/miscUtils';

const Project = props => {
    const { project } = props;
    const emptyVariants = [1, 2, 3, 4];
    return (
        <div className="product-box">
            <Link to={`${process.env.PUBLIC_URL}/projects/${project.id}`}>
                <div>
                    <div className="front">
                        <div className="container-thumbnail-project">
                            {project.variants.length > 0
                                ? project.variants.slice(0, 4).map((variant, index) => {
                                      return (
                                          <div className={`div${index + 1}`} key={`thumb-${index}`}>
                                              <img src={variant.images[0].url} alt={variant.sku} />
                                          </div>
                                      );
                                  })
                                : emptyVariants.map(item => {
                                      return (
                                          <div
                                              className={`div${item}`}
                                              key={`thumb-${item}`}
                                              style={{ backgroundColor: '#eee' }}
                                          ></div>
                                      );
                                  })}
                        </div>
                    </div>
                </div>
                <div className="product-detail mt-2 text-left">
                    <h4>{project.name}</h4>
                    <div className="mt-1" />
                    {project.dateFrom && project.dateTo && (
                        <p>
                            {moment(project.dateFrom.split('T')[0]).format(formatDate)}{' '}
                            <i className="fa fa-arrow-right"></i>
                            &nbsp;
                            {moment(project.dateTo.split('T')[0]).format(formatDate)}
                        </p>
                    )}
                </div>
            </Link>
        </div>
    );
};

const AddProject = props => {
    const { setVisibleModal } = props;

    return (
        <div
            className="product-box new-project-box btn-theme"
            onClick={() => setVisibleModal(true)}
        >
            <div className="product-detail mt-2">
                <h6>new project</h6>
            </div>
        </div>
    );
};

function wishList(props) {
    const { user } = props.auth.user;
    const [visibleModal, setVisibleModal] = useState(false);
    const [projects, setProjects] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        fetchProjects();
    }, []);

    // If end date of project is older than today return true
    // else return false
    const projectOlder = dateTo => {
        const today = moment();

        if (moment(dateTo).diff(today) < 0) {
            return true;
        }
        return false;
    };

    const fetchProjects = async () => {
        const { data } = await api.get(`/user/${user.id}/projects`);
        setProjects(data.data.rows);
    };

    const addProject = data => {
        api.post(`/user/${user.id}/projects`, data)
            .then(() => {
                setVisibleModal(false);
                toast('Project added successfully.');
                fetchProjects();
            })
            .catch(error => {
                const errors = error.response.data.errors;

                errors.map(error => toast.error(error.message));
            });
    };

    const processingProjects = projects.filter(
        project => project.orderPlaced && !projectOlder(project.dateTo),
    );
    const openProjects = projects.filter(project => !project.orderPlaced);

    return (
        <div>
            <section className="ratio_square gym-product light-layout section-b-space">
                <div className="title1 title5 mb-3">
                    <h2 className="title-inner2">My projects</h2>
                </div>
                <div className="container tab-projects mb-5">
                    <Tabs className="tab-content nav-material">
                        <TabList className="nav nav-tabs nav-material">
                            <Tab className="nav-item">
                                <span className="nav-link">
                                    <i className="icofont icofont-man-in-glasses"></i>Open
                                </span>
                                <div className="material-border"></div>
                            </Tab>
                            <Tab className="nav-item">
                                <span className="nav-link active">
                                    <i className="icofont icofont-ui-home"></i>Processing
                                </span>
                                <div className="material-border"></div>
                            </Tab>        
                        </TabList>
                        <TabPanel>
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="no-slider row">
                                        {openProjects.map((project, index) => (
                                            <Project key={index} project={project} />
                                        ))}
                                        <AddProject setVisibleModal={setVisibleModal} />
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel className="tab-pane fade mt-4 show active">
                            <div className="row mt-3">
                                <div className="col">
                                    {processingProjects.length > 0 ? (
                                        <div className="no-slider row">
                                            {processingProjects.map((project, index) => (
                                                <Project key={index} project={project} />
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="row">
                                            <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                                <h3>There are not projects in process</h3>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </TabPanel>                        
                    </Tabs>
                </div>
            </section>
            <ModalProject
                visibleModal={visibleModal}
                setVisibleModal={setVisibleModal}
                submitAction={addProject}
                user={user}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    Items: state.wishlist.list,
    symbol: state.data.symbol,
    auth: state.auth,
});

export default connect(mapStateToProps, {})(wishList);
